<template>
  <div class="list">
    <slot></slot>
  </div>
</template>
<script>

export default {
  name: 'List',

};
</script>

