<template>
  <div class="module-switch"
       :class="classes">
    <div class="module-switch__headline">
      Wechseln zu
    </div>

    <div class="module-switch__links">
      <router-link :to="{ name: module.initialRoute }"
                   class="module-switch__link"
                   v-for="module in modules"
                   :key="module.id">
        {{ $t(module.name) }}
      </router-link>
    </div>
  </div>
</template>
<script>

import { getAvailableModules } from '@/helpers/modules';
import { mapGetters } from 'vuex';

const availableVariants = ['in-sidebar', 'in-sidebar-narrow'];

export default {
  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
    variant: String,
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUser']),
    modules() {
      return getAvailableModules(this.getCurrentUser.roles, this.$route);
    },
    classes() {
      return {
        ['module-switch--vertical']: this.vertical,
        [`module-switch--${this.variant}`]: this.variant && availableVariants.includes(this.variant),
      };
    },
  },
};
</script>
