export const additionalOptions = [
  {
    name: 'additional_options.no_thank_you_email',
    value: 'keine Danke E-Mail',
  },
];

export const followUpActionsOptions = [
  {
    name: 'follow_up_actions_options.offer',
    value: 'Angebot',
  },
  {
    name: 'follow_up_actions_options.visit',
    value: 'Besuch',
  },
  {
    name: 'follow_up_actions_options.leaflet',
    value: 'Prospekt',
  },
  {
    name: 'follow_up_actions_options.call',
    value: 'Telefonat',
  },
  {
    name: 'follow_up_actions_options.test',
    value: 'Test',
  },
  {
    name: 'follow_up_actions_options.video',
    value: 'Video',
  },
  {
    name: 'follow_up_actions_options.demo',
    value: 'Vorführung',
  },
  {
    name: 'follow_up_actions_options.maintainance_service',
    value: 'Wartung & Service',
  },
  {
    name: 'follow_up_actions_options.newsletter',
    value: 'Messeeinladungen, Eintrittskartengutscheine, Produktinformationen',
  },
];

export const priorityOptions = [
  {
    name: 'A',
    value: 'A',
  },
  {
    name: 'B',
    value: 'B',
  },
  {
    name: 'C',
    value: 'C',
  },
];

export const statusOptions = [
  {
    name: 'status_options.client',
    value: 'Kunde',
  },
  {
    name: 'status_options.interested',
    value: 'Interessent',
  },
  {
    name: 'status_options.consultant',
    value: 'Berater',
  },
  {
    name: 'status_options.dealer',
    value: 'Händler',
  },
  {
    name: 'status_options.supplier',
    value: 'Lieferant',
  },
  {
    name: 'status_options.fair',
    value: 'Messe',
  },
  {
    name: 'status_options.teammate',
    value: 'Mitspieler',
  },
  {
    name: 'status_options.ppi',
    value: 'PPI',
  },
  {
    name: 'status_options.press',
    value: 'Presse',
  },
  {
    name: 'status_options.subsidiary',
    value: 'Tochtergesellschaft',
  },
  {
    name: 'status_options.association',
    value: 'Verband',
  },
  {
    name: 'status_options.representative',
    value: 'Vertreter',
  },
  {
    name: 'status_options.competitor',
    value: 'Wettbewerb',
  },
];

export const titleOptions = [
  {
    name: 'title_options.none',
    value: 'None',
  },
  {
    name: 'title_options.dr',
    value: 'Dr.',
  },
  {
    name: 'title_options.profdr',
    value: 'Prof. Dr.',
  },
  {
    name: 'title_options.department',
    value: 'Abteilung',
  },
  {
    name: 'title_options.subjectarea',
    value: 'Fachbereich',
  },
];

export const salutationOptions = [
  {
    name: 'salutation_options.none',
    value: 'none',
  },
  {
    name: 'salutation_options.mrs',
    value: 'mrs',
  },
  {
    name: 'salutation_options.mr',
    value: 'mr',
  },
  {
    name: 'salutation_options.other',
    value: 'other',
  },
];

export const countryOptions = [
  {
    name: 'country_options.germany',
    value: 'Deutschland',
  },
  {
    name: 'country_options.afghanistan',
    value: 'Afghanistan',
  },
  {
    name: 'country_options.egypt',
    value: 'Ägypten',
  },
  {
    name: 'country_options.albania',
    value: 'Albanien',
  },
  {
    name: 'country_options.algeria',
    value: 'Algerien',
  },
  {
    name: 'country_options.us_virgin_islands',
    value: 'Amerikanische Jungferninseln',
  },
  {
    name: 'country_options.american_samoa',
    value: 'Amerikanisch-Samoa',
  },
  {
    name: 'country_options.andorra',
    value: 'Andorra',
  },
  {
    name: 'country_options.angola',
    value: 'Angola',
  },
  {
    name: 'country_options.anguilla',
    value: 'Anguilla',
  },
  {
    name: 'country_options.antarctica',
    value: 'Antarktis',
  },
  {
    name: 'country_options.antigua_and_barbuda',
    value: 'Antigua und Barbuda',
  },
  {
    name: 'country_options.equatorial_guinea',
    value: 'Äquatorialguinea',
  },
  {
    name: 'country_options.argentina',
    value: 'Argentinien',
  },
  {
    name: 'country_options.armenia',
    value: 'Armenien',
  },
  {
    name: 'country_options.aruba',
    value: 'Aruba',
  },
  {
    name: 'country_options.azerbaijan',
    value: 'Aserbaidschan',
  },
  {
    name: 'country_options.ethiopia',
    value: 'Äthiopien',
  },
  {
    name: 'country_options.australia',
    value: 'Australien',
  },
  {
    name: 'country_options.bahamas',
    value: 'Bahamas',
  },
  {
    name: 'country_options.bahrain',
    value: 'Bahrain',
  },
  {
    name: 'country_options.bangladesh',
    value: 'Bangladesch',
  },
  {
    name: 'country_options.barbados',
    value: 'Barbados',
  },
  {
    name: 'country_options.belgium',
    value: 'Belgien',
  },
  {
    name: 'country_options.belize',
    value: 'Belize',
  },
  {
    name: 'country_options.benin',
    value: 'Benin',
  },
  {
    name: 'country_options.bermuda',
    value: 'Bermuda',
  },
  {
    name: 'country_options.bhutan',
    value: 'Bhutan',
  },
  {
    name: 'country_options.bolivia',
    value: 'Bolivien',
  },
  {
    name: 'country_options.bosnia_and_herzegovina',
    value: 'Bosnien und Herzegowina',
  },
  {
    name: 'country_options.botswana',
    value: 'Botswana',
  },
  {
    name: 'country_options.bouvet_island',
    value: 'Bouvetinsel',
  },
  {
    name: 'country_options.brazil',
    value: 'Brasilien',
  },
  {
    name: 'country_options.british_virgin_islands',
    value: 'Britische Jungferninseln',
  },
  {
    name: 'country_options.brunei_darussalam',
    value: 'Brunei Darussalam',
  },
  {
    name: 'country_options.bulgaria',
    value: 'Bulgarien',
  },
  {
    name: 'country_options.burkina_faso',
    value: 'Burkina Faso',
  },
  {
    name: 'country_options.burundi',
    value: 'Burundi',
  },
  {
    name: 'country_options.chile',
    value: 'Chile',
  },
  {
    name: 'country_options.china',
    value: 'China',
  },
  {
    name: 'country_options.cook_islands',
    value: 'Cookinseln',
  },
  {
    name: 'country_options.costa_rica',
    value: 'Costa Rica',
  },
  {
    name: 'country_options.denmark',
    value: 'Dänemark',
  },
  {
    name: 'country_options.dominica',
    value: 'Dominica',
  },
  {
    name: 'country_options.dominican_republic',
    value: 'Dominikanische Republik',
  },
  {
    name: 'country_options.djibouti',
    value: 'Dschibuti',
  },
  {
    name: 'country_options.equador',
    value: 'Ecuador',
  },
  {
    name: 'country_options.el_salvador',
    value: 'El Salvador',
  },
  {
    name: 'country_options.ivory_coast',
    value: 'Elfenbeinküste',
  },
  {
    name: 'country_options.eritrea',
    value: 'Eritrea',
  },
  {
    name: 'country_options.estland',
    value: 'Estland',
  },
  {
    name: 'country_options.falkland_islands',
    value: 'Falklandinseln',
  },
  {
    name: 'country_options.faroe_islands',
    value: 'Färöer',
  },
  {
    name: 'country_options.fiji',
    value: 'Fidschi',
  },
  {
    name: 'country_options.finland',
    value: 'Finnland',
  },
  {
    name: 'country_options.france',
    value: 'Frankreich',
  },
  {
    name: 'country_options.french_guiana',
    value: 'Französisch-Guayana',
  },
  {
    name: 'country_options.french_polynesia',
    value: 'Französisch-Polynesien',
  },
  {
    name: 'country_options.gabon',
    value: 'Gabun',
  },
  {
    name: 'country_options.gambia',
    value: 'Gambia',
  },
  {
    name: 'country_options.georgia',
    value: 'Georgien',
  },
  {
    name: 'country_options.ghana',
    value: 'Ghana',
  },
  {
    name: 'country_options.gibraltar',
    value: 'Gibraltar',
  },
  {
    name: 'country_options.grenada',
    value: 'Grenada',
  },
  {
    name: 'country_options.greece',
    value: 'Griechenland',
  },
  {
    name: 'country_options.greenland',
    value: 'Grönland',
  },
  {
    name: 'country_options.great_britain',
    value: 'Grossbritannien',
  },
  {
    name: 'country_options.guadeloupe',
    value: 'Guadeloupe',
  },
  {
    name: 'country_options.guam',
    value: 'Guam',
  },
  {
    name: 'country_options.guatemala',
    value: 'Guatemala',
  },
  {
    name: 'country_options.guinea',
    value: 'Guinea',
  },
  {
    name: 'country_options.guinea_bissau',
    value: 'Guinea-Bissau',
  },
  {
    name: 'country_options.guyana',
    value: 'Guyana',
  },
  {
    name: 'country_options.haiti',
    value: 'Haiti',
  },
  {
    name: 'country_options.heard_and_mcdonald_islands',
    value: 'Heard und McDonaldinseln',
  },
  {
    name: 'country_options.honduras',
    value: 'Honduras',
  },
  {
    name: 'country_options.hongkong',
    value: 'Hongkong',
  },
  {
    name: 'country_options.india',
    value: 'Indien',
  },
  {
    name: 'country_options.indonesia',
    value: 'Indonesien',
  },
  {
    name: 'country_options.iraq',
    value: 'Irak',
  },
  {
    name: 'country_options.iran',
    value: 'Iran',
  },
  {
    name: 'country_options.ireland',
    value: 'Irland',
  },
  {
    name: 'country_options.iceland',
    value: 'Island',
  },
  {
    name: 'country_options.israel',
    value: 'Israel',
  },
  {
    name: 'country_options.italy',
    value: 'Italien',
  },
  {
    name: 'country_options.jamaica',
    value: 'Jamaika',
  },
  {
    name: 'country_options.japan',
    value: 'Japan',
  },
  {
    name: 'country_options.yemen',
    value: 'Jemen',
  },
  {
    name: 'country_options.jordan',
    value: 'Jordanien',
  },
  {
    name: 'country_options.cayman_islands',
    value: 'Kaimaninseln',
  },
  {
    name: 'country_options.cambodia',
    value: 'Kambodscha',
  },
  {
    name: 'country_options.cameroon',
    value: 'Kamerun',
  },
  {
    name: 'country_options.canada',
    value: 'Kanada',
  },
  {
    name: 'country_options.cape_verde',
    value: 'Kap Verde',
  },
  {
    name: 'country_options.kazakhstan',
    value: 'Kasachstan',
  },
  {
    name: 'country_options.qatar',
    value: 'Katar',
  },
  {
    name: 'country_options.kenya',
    value: 'Kenia',
  },
  {
    name: 'country_options.kyrgyzstan',
    value: 'Kirgisistan',
  },
  {
    name: 'country_options.kiribati',
    value: 'Kiribati',
  },
  {
    name: 'country_options.cocos_islands',
    value: 'Kokosinseln',
  },
  {
    name: 'country_options.colombia',
    value: 'Kolumbien',
  },
  {
    name: 'country_options.comoros',
    value: 'Komoren',
  },
  {
    name: 'country_options.congo_democratic_republic',
    value: 'Kongo (Demokratische Republik)',
  },
  {
    name: 'country_options.congo_republiy',
    value: 'Kongo (Republik)',
  },
  {
    name: 'country_options.croatia',
    value: 'Kroatien',
  },
  {
    name: 'country_options.cuba',
    value: 'Kuba',
  },
  {
    name: 'country_options.kuwait',
    value: 'Kuwait',
  },
  {
    name: 'country_options.laos',
    value: 'Laos',
  },
  {
    name: 'country_options.lesotho',
    value: 'Lesotho',
  },
  {
    name: 'country_options.latvia',
    value: 'Lettland',
  },
  {
    name: 'country_options.lebanon',
    value: 'Libanon',
  },
  {
    name: 'country_options.liberia',
    value: 'Liberia',
  },
  {
    name: 'country_options.libya',
    value: 'Libyen',
  },
  {
    name: 'country_options.liechtenstein',
    value: 'Liechtenstein',
  },
  {
    name: 'country_options.lithuania',
    value: 'Litauen',
  },
  {
    name: 'country_options.luxemburg',
    value: 'Luxemburg',
  },
  {
    name: 'country_options.macau',
    value: 'Macau',
  },
  {
    name: 'country_options.madagascar',
    value: 'Madagaskar',
  },
  {
    name: 'country_options.malawi',
    value: 'Malawi',
  },
  {
    name: 'country_options.malaysia',
    value: 'Malaysia',
  },
  {
    name: 'country_options.maldives',
    value: 'Malediven',
  },
  {
    name: 'country_options.mali',
    value: 'Mali',
  },
  {
    name: 'country_options.malta',
    value: 'Malta',
  },
  {
    name: 'country_options.morocco',
    value: 'Marokko',
  },
  {
    name: 'country_options.marshall_islands',
    value: 'Marshallinseln',
  },
  {
    name: 'country_options.martinique',
    value: 'Martinique',
  },
  {
    name: 'country_options.mauritania',
    value: 'Mauretanien',
  },
  {
    name: 'country_options.mauritius',
    value: 'Mauritius',
  },
  {
    name: 'country_options.mayotte',
    value: 'Mayotte',
  },
  {
    name: 'country_options.macedonia',
    value: 'Mazedonien',
  },
  {
    name: 'country_options.mexico',
    value: 'Mexiko',
  },
  {
    name: 'country_options.micronesia',
    value: 'Mikronesien',
  },
  {
    name: 'country_options.moldova',
    value: 'Moldawien',
  },
  {
    name: 'country_options.monaco',
    value: 'Monaco',
  },
  {
    name: 'country_options.mongolia',
    value: 'Mongolei',
  },
  {
    name: 'country_options.montenegro',
    value: 'Montenegro',
  },
  {
    name: 'country_options.montserrat',
    value: 'Montserrat',
  },
  {
    name: 'country_options.mosambique',
    value: 'Mosambik',
  },
  {
    name: 'country_options.myanmar',
    value: 'Myanmar',
  },
  {
    name: 'country_options.namibia',
    value: 'Namibia',
  },
  {
    name: 'country_options.nauru',
    value: 'Nauru',
  },
  {
    name: 'country_options.nepal',
    value: 'Nepal',
  },
  {
    name: 'country_options.new_caledonia',
    value: 'Neukaledonien',
  },
  {
    name: 'country_options.new_zealand',
    value: 'Neuseeland',
  },
  {
    name: 'country_options.nicaragua',
    value: 'Nicaragua',
  },
  {
    name: 'country_options.netherlands',
    value: 'Niederlande',
  },
  {
    name: 'country_options.niger',
    value: 'Niger',
  },
  {
    name: 'country_options.nigeria',
    value: 'Nigeria',
  },
  {
    name: 'country_options.niue',
    value: 'Niue',
  },
  {
    name: 'country_options.north_korea',
    value: 'Nordkorea',
  },
  {
    name: 'country_options.northern_mariana_islands',
    value: 'Nördliche Marianen',
  },
  {
    name: 'country_options.norfork_island',
    value: 'Norfolkinsel',
  },
  {
    name: 'country_options.norway',
    value: 'Norwegen',
  },
  {
    name: 'country_options.oman',
    value: 'Oman',
  },
  {
    name: 'country_options.austria',
    value: 'Österreich',
  },
  {
    name: 'country_options.east_timor',
    value: 'Osttimor',
  },
  {
    name: 'country_options.pakistan',
    value: 'Pakistan',
  },
  {
    name: 'country_options.palestine',
    value: 'Palästina',
  },
  {
    name: 'country_options.palau',
    value: 'Palau',
  },
  {
    name: 'country_options.panama',
    value: 'Panama',
  },
  {
    name: 'country_options.papua_new_guinea',
    value: 'Papua-Neuguinea',
  },
  {
    name: 'country_options.paraguay',
    value: 'Paraguay',
  },
  {
    name: 'country_options.peru',
    value: 'Peru',
  },
  {
    name: 'country_options.philippines',
    value: 'Philippinen',
  },
  {
    name: 'country_options.pitcairn_islands',
    value: 'Pitcairninseln',
  },
  {
    name: 'country_options.poland',
    value: 'Polen',
  },
  {
    name: 'country_options.portugal',
    value: 'Portugal',
  },
  {
    name: 'country_options.puerto_rico',
    value: 'Puerto Rico',
  },
  {
    name: 'country_options.reunion',
    value: 'Réunion',
  },
  {
    name: 'country_options.rwanda',
    value: 'Ruanda',
  },
  {
    name: 'country_options.romania',
    value: 'Rumänien',
  },
  {
    name: 'country_options.russian_federation',
    value: 'Russische Föderation',
  },
  {
    name: 'country_options.saint_pierre_and_miquelon',
    value: 'Saint-Pierre und Miquelon',
  },
  {
    name: 'country_options.solomon_islands',
    value: 'Salomonen',
  },
  {
    name: 'country_options.zambia',
    value: 'Sambia',
  },
  {
    name: 'country_options.samoa',
    value: 'Samoa',
  },
  {
    name: 'country_options.san_marino',
    value: 'San Marino',
  },
  {
    name: 'country_options.sao_tome_and_principe',
    value: 'São Tomé und Príncipe',
  },
  {
    name: 'country_options.saudi_arabia',
    value: 'Saudi-Arabien',
  },
  {
    name: 'country_options.sweden',
    value: 'Schweden',
  },
  {
    name: 'country_options.switzerland',
    value: 'Schweiz',
  },
  {
    name: 'country_options.senegal',
    value: 'Senegal',
  },
  {
    name: 'country_options.serbia',
    value: 'Serbien',
  },
  {
    name: 'country_options.seychelles',
    value: 'Seychellen',
  },
  {
    name: 'country_options.sierra_leone',
    value: 'Sierra Leone',
  },
  {
    name: 'country_options.zimbabwe',
    value: 'Simbabwe',
  },
  {
    name: 'country_options.singapore',
    value: 'Singapur',
  },
  {
    name: 'country_options.slovakia',
    value: 'Slowakei',
  },
  {
    name: 'country_options.slovenia',
    value: 'Slowenien',
  },
  {
    name: 'country_options.somalia',
    value: 'Somalia',
  },
  {
    name: 'country_options.spain',
    value: 'Spanien',
  },
  {
    name: 'country_options.sri_lanka',
    value: 'Sri Lanka',
  },
  {
    name: 'country_options.st_helena',
    value: 'St. Helena',
  },
  {
    name: 'country_options.st_kitts_and_nevis',
    value: 'St. Kitts und Nevis',
  },
  {
    name: 'country_options.st_lucia',
    value: 'St. Lucia',
  },
  {
    name: 'country_options.st_vincent_and_the_grenadines',
    value: 'St. Vincent und die Grenadinen',
  },
  {
    name: 'country_options.south_africa',
    value: 'Südafrika',
  },
  {
    name: 'country_options.sudan',
    value: 'Sudan',
  },
  {
    name: 'country_options.south_georgia',
    value: 'Südgeorgien',
  },
  {
    name: 'country_options.south_korea',
    value: 'Südkorea',
  },
  {
    name: 'country_options.suriname',
    value: 'Suriname',
  },
  {
    name: 'country_options.svalbard',
    value: 'Svalbard',
  },
  {
    name: 'country_options.swaziland',
    value: 'Swasiland',
  },
  {
    name: 'country_options.syria',
    value: 'Syrien',
  },
  {
    name: 'country_options.tajikistan',
    value: 'Tadschikistan',
  },
  {
    name: 'country_options.taiwan',
    value: 'Taiwan',
  },
  {
    name: 'country_options.tanzania',
    value: 'Tansania',
  },
  {
    name: 'country_options.thailand',
    value: 'Thailand',
  },
  {
    name: 'country_options.togo',
    value: 'Togo',
  },
  {
    name: 'country_options.tokelau',
    value: 'Tokelau',
  },
  {
    name: 'country_options.tonga',
    value: 'Tonga',
  },
  {
    name: 'country_options.trinidad_and_tobago',
    value: 'Trinidad und Tobago',
  },
  {
    name: 'country_options.chad',
    value: 'Tschad',
  },
  {
    name: 'country_options.czech_republic',
    value: 'Tschechische Republik',
  },
  {
    name: 'country_options.tunisia',
    value: 'Tunesien',
  },
  {
    name: 'country_options.turkey',
    value: 'Türkei',
  },
  {
    name: 'country_options.turkmenistan',
    value: 'Turkmenistan',
  },
  {
    name: 'country_options.turks_and_caicos_islands',
    value: 'Turks- und Caicosinseln',
  },
  {
    name: 'country_options.tuvalu',
    value: 'Tuvalu',
  },
  {
    name: 'country_options.uganda',
    value: 'Uganda',
  },
  {
    name: 'country_options.ukraine',
    value: 'Ukraine',
  },
  {
    name: 'country_options.hungary',
    value: 'Ungarn',
  },
  {
    name: 'country_options.uruguay',
    value: 'Uruguay',
  },
  {
    name: 'country_options.usa',
    value: 'USA',
  },
  {
    name: 'country_options.uzbekistan',
    value: 'Usbekistan',
  },
  {
    name: 'country_options.vanuatu',
    value: 'Vanuatu',
  },
  {
    name: 'country_options.vatican_city',
    value: 'Vatikanstadt',
  },
  {
    name: 'country_options.venezuela',
    value: 'Venezuela',
  },
  {
    name: 'country_options.united_arab_emirates',
    value: 'Vereinigte Arabische Emirate',
  },
  {
    name: 'country_options.vietnam',
    value: 'Vietnam',
  },
  {
    name: 'country_options.wallis_and_futuna',
    value: 'Wallis und Futuna',
  },
  {
    name: 'country_options.christmas_island',
    value: 'Weihnachtsinsel',
  },
  {
    name: 'country_options.belarus',
    value: 'Weissrussland',
  },
  {
    name: 'country_options.western_sahara',
    value: 'Westsahara',
  },
  {
    name: 'country_options.central_african_republic',
    value: 'Zentralafrikanische Republik',
  },
  {
    name: 'country_options.cyprus',
    value: 'Zypern',
  },
];

export const companySizeOptions = [
  {
    name: 'company_size_options.industry',
    value: 'Industrie',
  },
  {
    name: 'company_size_options.craft_midsize',
    value: 'Handwerk-Mittelstand',
  },
];

export const sectorOptions = [
  {
    name: 'sector_options.sausage',
    value: 'Wurst',
  },
  {
    name: 'sector_options.poultry',
    value: 'Geflügel',
  },
  {
    name: 'sector_options.dairy',
    value: 'Molkereiprodukte',
  },
  {
    name: 'sector_options.petfood',
    value: 'Tiernahrung',
  },
  {
    name: 'sector_options.convenience',
    value: 'Fertiggerichte',
  },
  {
    name: 'sector_options.food_other',
    value: 'Lebensmittel, sonstige',
  },
  {
    name: 'sector_options.sealant',
    value: 'Dichtstoffe_Klebstoffe_Chemie',
  },
  {
    name: 'sector_options.general',
    value: 'Allgemein',
  },
  {
    name: 'sector_options.aquaristic',
    value: 'Aquaristik',
  },
  {
    name: 'sector_options.bakery_products',
    value: 'Backwaren_Backmittel',
  },
  {
    name: 'sector_options.bags_other',
    value: 'Beutelverp., sonst.',
  },
  {
    name: 'sector_options.casing_foils_manufacturer',
    value: 'Darm-/Folienhersteller',
  },
  {
    name: 'sector_options.dental',
    value: 'Dentalstoffe',
  },
  {
    name: 'sector_options.seafood',
    value: 'Fischindustrie_Seafood',
  },
  {
    name: 'sector_options.meat_other',
    value: 'Fleisch, sonstige',
  },
  {
    name: 'sector_options.marzipan',
    value: 'Marzipan',
  },
  {
    name: 'sector_options.mechanical_engineering',
    value: 'Maschinenbau',
  },
  {
    name: 'sector_options.medical',
    value: 'Medizintechnik',
  },
  {
    name: 'sector_options.fruit_vegetables',
    value: 'Obst_Gemüse',
  },
  {
    name: 'sector_options.explosive',
    value: 'Sprengstoffe',
  },
  {
    name: 'sector_options.tobacco',
    value: 'Tabak',
  },
  {
    name: 'sector_options.tech_other',
    value: 'Techn. Produkte, sonstige',
  },
  {
    name: 'sector_options.preassembly',
    value: 'Vorkonfektionierung',
  },
];
