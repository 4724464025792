import {
  ROLE_MODULE_LEAD_AND_SALES,
  ROLE_MODULE_MYPOLY,
  ROLE_MODULE_OTHERS,
} from '@/helpers/roles';

export const MODULE_NONE = 'None';
export const MODULE_LEAD_AND_SALES = 'LeadAndSales';
export const MODULE_MY_POLY = 'MyPoly';
export const MODULE_OTHERS = 'Others';

export const MODULES = [
  {
    id: MODULE_LEAD_AND_SALES,
    name: 'sidebar.sales',
    initialRoute: 'Dashboard',
    requiredRoles: [ROLE_MODULE_LEAD_AND_SALES],
  },
  {
    id: MODULE_MY_POLY,
    name: 'sidebar.my',
    initialRoute: 'MyDashboard',
    requiredRoles: [ROLE_MODULE_MYPOLY],
  },
  {
    id: MODULE_OTHERS,
    name: 'sidebar.other',
    initialRoute: 'TranslationManager',
    requiredRoles: [ROLE_MODULE_OTHERS],
  },
];

export const getAvailableModules = (userRoles, route) => {
  const availableModules = MODULES;
  const checker = (arr, target) => target.every(v => arr.includes(v));
  const isAvailable = (m) => m.id !== route.meta.module && checker(userRoles, m.requiredRoles);
  return availableModules.filter(isAvailable);
};
