<template>
  <div v-if="product">
    <div class="container">
      <page-title :subline="product.subtitle"
                  variant="light"
                  :headline="product.title"
                  :img="product.image">

        <btn @click.native.prevent="modal = true"
             class="m-bottom-large"
             variant="highlighted"
             icon="bookmark"
             desktop-only>
          {{ $t('productFinder.addWishlist') }}
        </btn>
      </page-title>

      <btn @click.native.prevent="modal = true"
           class="m-bottom-large"
           variant="highlighted"
           icon="bookmark"
           mobileOnly>
        {{ $t('productFinder.addWishlist') }}
      </btn>

      <ProductTabs :downloads="getDownloads(product.tessaId) || [] "></ProductTabs>

      <modal :product="product"
             v-model="modal"
             :title="`${product.title} ${$t('productFinder.toWishlist')}`">
        <template #body>
          <page-title variant="label"
                      :headline="$t('productFinder.yourWishlists')"
                      btn-right
                      :back-btn="false">
            <btn variant="underlined-inline-text"
                 :to="{ name: 'Wishlists' }">{{ $t('productFinder.manageWishlists') }}
            </btn>
          </page-title>
          <multi-select-list :items="getListsFromCurrentUser"
                             :selected="getListIdsFromCurrentUserByProductId(productId)"
                             v-on:update:selected-list="handleSelectedWishlists" />
          <add-wishlist-form />
        </template>
        <template #actions>
          <btn variant="highlighted"
               :disabled="isDisabled"
               full-width
               @click.native.prevent="handleUpdateWishlist">
            {{ $t('productFinder.save') }}
          </btn>
        </template>
      </modal>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DownloadState from '@/components/atoms/DownloadState';
import Card from '@/components/atoms/Card';
import Btn from '@/components/molecules/Btn';
import MultiSelectList from '@/components/molecules/MultiSelectList';
import PageTitle from '@/components/molecules/PageTitle';
import ProductTabs from '@/components/molecules/ProductTabs';
import Modal from '@/components/organisms/Modal';
import AddWishlistForm from '@/components/forms/wishlist/AddWishlistForm';

export default {
  name: 'Product',
  data() {
    return {
      modal: false,
      selectedWishlists: [],
    };
  },
  components: {
    DownloadState,
    Card,
    ProductTabs,
    AddWishlistForm,
    Btn,
    Modal,
    MultiSelectList,
    PageTitle,
  },
  methods: {
    ...mapActions('wishlists', ['setItemsOnWishlist']),
    handleSelectedWishlists(e) {
      this.selectedWishlists = e;
    },
    handleUpdateWishlist() {
      this.setItemsOnWishlist({ wishlistIds: this.selectedWishlists, productId: this.productId });
      this.modal = false;
    },
  },
  computed: {
    ...mapGetters('wishlists', ['getListsFromCurrentUser', 'getListIdsFromCurrentUserByProductId']),
    ...mapGetters('products', ['getProductById']),
    ...mapGetters('offline', ['getDownloads']),
    productId() {
      return this?.$route?.params?.productId;
    },
    product() {
      return this.getProductById(this.productId);
    },
    isDisabled() {
      const equals = (a, b) => {
        return JSON.stringify(a.sort()) === JSON.stringify(b.sort());
      };
      return equals(this.selectedWishlists, this.getListIdsFromCurrentUserByProductId(this.productId));
    },
  },
};
</script>
