<template>
  <transition name="modal-transition">
    <div class="modal"
         :class="modalClasses"
         v-if="modelValue">
      <div v-if="closeable"
           class="modal__bg"
           @click="close"></div>
      <div v-else
           class="modal__bg modal__bg--not-closeable"></div>
      <div class="modal__box"
           :class="boxClasses">
        <div class="modal__head"
             :class="headClasses">
          <span>{{ title }}</span>
          <button v-if="closeable"
                  class="modal__exit-btn"
                  @click="close">
            <Icon name="times" />
          </button>
        </div>
        <div class="modal__body"
             :class="bodyClasses">
          <slot name="body"></slot>
        </div>
        <div class="modal__actions">
          <slot name="actions"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapActions } from 'vuex';
import Icon from '@/components/atoms/Icon';

export default {
  name: 'Modal',
  components: {
    Icon,
  },
  props: {
    modelValue: {},
    value: {},
    product: {},
    title: {},
    fullScreen: Boolean,
    mobile: Boolean,
    boxBackgroundBg: Boolean,
    headBackgroundWhite: Boolean,
    headShadow: Boolean,
    fullHeight: Boolean,
    closeable: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      name: '',
    };
  },
  methods: {
    ...mapActions('wishlists', ['createWishlist']),
    handleCreateNewWishlist() {
      // add new wishlist with product
      this.createWishlist({
        productId: this.product.id,
        wishlistName: this.name,
      });
      // clear name again
      this.name = '';
      // close window
      this.close();
    },
    close() {
      this.$emit('update:modelValue', false);
    },
  },
  computed: {
    modalClasses() {
      return {
        [`modal--mobile`]: this.mobile,
      };
    },
    boxClasses() {
      return {
        [`modal__box--full-screen`]: this.fullScreen,
        [`modal__box--background-bg`]: this.boxBackgroundBg,
      };
    },
    headClasses() {
      return {
        [`modal__head--background-white`]: this.headBackgroundWhite,
        [`modal__head--shadow`]: this.headShadow,
      };
    },
    bodyClasses() {
      return {
        [`modal__body--full-height`]: this.fullHeight,
      };
    },
  },
};
</script>
