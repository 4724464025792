<template>
  <card class="tab-card">
    <div class="tab-bar">
      <div class="tab-bar__tabs">
        <underline ref="underline"
                   side="top"
                   :underline-present="true">
          <div v-for="(item, index) in getCategoriesByContent"
               :key="`cat${index}`"
               class="tab-bar__tabs-tab"
               :class="{'active': item.category === selectedCategory}"
               :data-active="item.category === selectedCategory"
               @click="selectedCategory = item.category; $refs.underline.selectTab()">
            {{ $t(item.title) }}
          </div>
        </underline>
      </div>
      <div class="tab-bar__title">{{ $t(getSelectedCategory.title) }}</div>
    </div>

    <ul class="tab-card__list">
      <div v-if="filteredDownloads.length <= 0">
        {{ $t(getSelectedCategory.title) }}: {{ $t('productFinder.noData') }}
      </div>
      <div v-else>
        <download v-for="(download, downloadIndex) in filteredDownloads"
                  :key="`download${downloadIndex}`"
                  :download="download"></download>
      </div>
    </ul>

  </card>
</template>
<script>
import Card from '@/components/atoms/Card';
import Underline from '@/components/atoms/Underline';
import Download from '@/components/molecules/Download';
import env from '@/helpers/env';

export default {
  name: 'ProductTabs',
  components: {
    Card,
    Underline,
    Download,
  },
  props: {
    downloads: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      categories: [
        {
          category: env('TESSA_CATEGORY_LEAFLETS'),
          title: 'productFinder.brochures',
        },
        {
          category: env('TESSA_CATEGORY_PRICE_LISTS'),
          title: 'productFinder.priceList',
        },
        {
          category: env('TESSA_CATEGORY_ORDER_FORMS'),
          title: 'productFinder.orderForms',
        },
        {
          category: env('TESSA_CATEGORY_VIDEOS'),
          title: 'productFinder.videos',
        },
        {
          category: env('TESSA_CATEGORY_TRAINING'),
          title: 'productFinder.training',
        },
        {
          category: 'CATEGORY_OTHERS', // no actual category id
          title: 'productFinder.other',
        },
      ],
      selectedCategory: env('TESSA_CATEGORY_LEAFLETS'),
    };
  },
  methods: {
    haveItems(category) {
      const downloads = this.downloads.filter((_) => _.type === category);
      return downloads.length > 0;
    },
  },
  computed: {
    getCategoriesByContent() {
      const availableCategories = [];

      this.categories.forEach((cat) => {
        if (this.downloads.filter((_) => _.type === cat.category).length > 0) {
          availableCategories.push(cat);
        }
      });

      return availableCategories;
    },
    getSelectedCategory() {
      return this.categories.find((_) => _.category === this.selectedCategory);
    },
    filteredDownloads() {
      return this.downloads.filter((_) => _.type === this.selectedCategory);
    },
  },
  watch: {
    selectedCategory() {
      if (this.differsLength > 0) {
        this.handleSave();
      }
    },
    current() {
      this.formsData = this.current;
    },
    $route() {
      const preSelect = this.getCategoriesByContent;
      if (preSelect.length > 0) {
        this.selectedCategory = preSelect[0].category;
      }
    },
  },
  mounted() {
    const preSelect = this.getCategoriesByContent;
    if (preSelect.length > 0) {
      this.selectedCategory = preSelect[0].category;
    }
  },
};
</script>
