<template>
  <button class="sync-tool"
          :class="classes"
          :disabled="!online || readOnly"
          @click.prevent="handleSyncAction">

    <icon name="sync"
          class="sync-tool__icon"
          :class="{ ['icon--sync-paused']: paused }" />

    <div class="sync-tool__info">
      <div v-if="isLoading('sync') && queue.length > 0"
           class="sync-tool__text">
        {{ $t('header.syncing') }}
      </div>
      <div v-else-if="last_sync"
           class="sync-tool__text">
        {{ $t('header.lastSync') }}
      </div>
      <div v-else
           class="sync-tool__text">
        {{ $t('header.sync') }}
      </div>

      <div v-if="isLoading('sync') && queue.length > 0"
           class="sync-tool__date">
        {{ queue.length }}
        <template v-if="queue.length > 1">{{ $t('header.files') }}</template>
        <template v-else>{{ $t('header.file') }}</template>
        {{ $t('header.pending') }}

        <div class="sync-tool__small-info">
          {{ progressPercentPerFile }}%
          {{ progressCurrentFileName }}
        </div>
      </div>
      <div v-else-if="last_sync"
           class="sync-tool__date">
        {{ $filters.date(last_sync) }}
        <template v-if="$i18n.locale === 'de'">Uhr</template>
      </div>
      <div v-else
           class="sync-tool__date">
        {{ $t('header.preparing') }}
      </div>
    </div>
  </button>
</template>
<script>
// dependencies
import { mapActions, mapGetters, mapState } from 'vuex';
// components
import Icon from '@/components/atoms/Icon';

export default {
  name: 'SyncTool',
  components: {
    Icon,
  },
  props: {
    mobile: Boolean,
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions('offline', ['startSync', 'togglePause', 'endPause']),
    handleSyncAction() {
      if (!this.isLoading('sync')) {
        this.startSync(true);
      }
    },
  },
  computed: {
    ...mapState('offline', ['queue', 'last_sync', 'progressPercentPerFile', 'progressCurrentFileName']),
    ...mapState(['online']),
    ...mapGetters(['isLoading']),

    paused() {
      return this.isLoading('sync.paused');
    },
    loading() {
      return this.isLoading('sync') || !this.isLoading('sync') && !this.last_sync;
    },
    classes() {
      return {
        [`sync-tool--mobile`]: this.mobile,
        ['sync-tool--loading']: this.loading,
        ['sync-tool--paused']: this.paused,
        ['sync-tool--read-only']: this.readOnly,
      };
    },
  },
};
</script>
