<template>
  <form class="detail-search"
        @submit.prevent="formHandler">
    <form-input v-model="form.machine"
                class="detail-search__form-item"
                type="text"
                :label="$t('myPolyclip.machine')" />

    <form-input v-model="form.machineNr"
                class="detail-search__form-item"
                type="text"
                :label="$t('myPolyclip.machineNumber')" />

    <form-select v-model="form.language"
                 :options="getLanguagesForSelect"
                 class="detail-search__form-item"
                 :label="$t('myPolyclip.language')"></form-select>

    <btn icon="search"
         variant="highlighted"
         class="detail-search__form-button">
      {{ $t('myPolyclip.search') }}
    </btn>
  </form>
</template>
<script>
import FormInput from '@/components/molecules/FormInput';
import FormSelect from '@/components/molecules/FormSelect';
import Btn from '@/components/molecules/Btn';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'DetailSearch',
  components: { Btn, FormSelect, FormInput },
  data() {
    return {
      form: {
        machine: '',
        machineNr: '',
        language: null,
      },
    };
  },
  computed: {
    ...mapGetters('mypoly', ['getLanguagesForSelect']),
    ...mapState('mypoly', ['filterMachine', 'filterMachineNr', 'filterLanguage']),
  },
  methods: {
    ...mapActions('mypoly', ['setFilters']),
    async formHandler() {
      await this.setFilters(this.form);
      await this.$router.push({ name: 'MyLibrary' });
    },
  },
  beforeMount() {
    this.form.machine = this.filterMachine;
    this.form.machineNr = this.filterMachineNr;
    this.form.language = this.filterLanguage;
  },
};
</script>
