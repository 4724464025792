<template>
  <div>
    <p v-if="bigLabel"
       class="attachment-upload__label--big">{{ bigLabel }}</p>
    <label v-if="label"
           class="attachment-upload__label">{{ label }}</label>
    <div class="attachment-upload"
         :class="classes">
      <div v-if="preview"
           class="attachment-upload__preview">
        <div @click="$refs.input.click()"
             class="attachment-upload__preview-icon"
             v-if="!path">
          <icon name="upload" />
        </div>
        <a target="_blank"
           v-if="path"
           :href="path"
           class="href">
          <img class="attachment-upload__preview-img"
               :src="path"
               :alt="path" />
        </a>
      </div>
      <div class="attachment-upload__input"
           :class="inputClasses">
        <input v-if="!filename"
               @change="handleChange"
               :disabled="disabled"
               ref="input"
               type="file"
               class="attachment-upload__file-upload"
               :class="uploadClasses" />
        <span v-else
              class="attachment-upload__file-upload">
                    {{ filename }}
                </span>
        <icon @click.native.prevent="removeImage"
              name="trash-alt"
              class="attachment-upload__delete-icon"
              :class="iconIsPresent" />
      </div>
    </div>
  </div>
</template>
<script>
import Icon from '@/components/atoms/Icon';
import { mapActions } from 'vuex';
import DB from '@/helpers/indexedDb';

export default {
  name: 'AttachmentUpload',
  emits: ['update:modelValue'],
  components: {
    Icon,
  },
  props: {
    modelValue: String,
    preview: Boolean,
    buttonLeft: Boolean,
    label: String,
    bigLabel: String,
    bordered: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      filename: null,
      path: null,
    };
  },
  methods: {
    ...mapActions('offline', ['addOfflineUpload', 'removeOfflineUpload']),
    async loadFile() {
      const localDatabase = await DB('sales-app-offline-uploads');
      const item = this.modelValue ? await localDatabase?.getEntry(this.modelValue) : null;
      if (item) {
        this.filename = item.blob.name;
        this.path = URL.createObjectURL(item.blob);
      } else {
        this.filename = null;
        this.path = null;
      }
    },
    async handleChange(e) {
      if (this.disabled) return;
      const file = e.target.files[0];
      if (file) {
        this.$emit('update:modelValue', await this.addOfflineUpload(file));
      }
    },
    async removeImage() {
      if (this.disabled) return;
      const remove = await this.removeOfflineUpload(this.modelValue);
      if (remove) {
        this.$emit('update:modelValue', undefined);
      }
    },
  },
  computed: {
    classes() {
      return {
        [`attachment-upload--bordered`]: this.bordered,
        [`attachment-upload--disabled`]: this.disabled,
      };
    },
    uploadClasses() {
      return {
        [`attachment-upload__input-button--hidden`]: this.path,
        [`attachment-upload__input-label--hidden`]: !this.path,
      };
    },
    iconIsPresent() {
      return {
        ['attachment-upload__delete-icon--hidden']: !this.path,
      };
    },
    inputClasses() {
      return {
        [`attachment-upload--space-between`]: this.path,
        [`attachment-upload--flex-start`]: this.buttonLeft && !this.path,
      };
    },
  },
  watch: {
    modelValue() {
      this.loadFile();
    },
  },
  async mounted() {
    this.$nextTick(async () => {
      await this.loadFile();
    });
  },
};
</script>
