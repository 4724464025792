<template>
  <div class="business-card-upload">
    <label class="business-card-upload__label"> {{ $t('leads.businessCard') }}</label>
    <div class="business-card-upload__area m-top-tiny">
      <div class="business-card-upload__area-preview">
        <div class="business-card-upload__area-uploads">
          <p class="business-card-upload__title">{{ $t('leads.bcFront') }}</p>
          <slot name="business-card-front"></slot>
        </div>
        <div class="business-card-upload__area-uploads">
          <p class="business-card-upload__title">{{ $t('leads.bcBack') }}</p>
          <slot name="business-card-back"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BusinessCardUpload',

};
</script>
