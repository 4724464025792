<template>
  <card h-scroll
        is-spaceless
        is-bordered>
    <div class="tabs tabs--extended-filter">
      <div class="tab__navigation-items">
        <div v-for="filter in filters"
             class="tab__navigation-item"
             :class="{'tab__navigation-item--active': filter.id === value}"
             @click.prevent="$emit('input', filter.id)">
          {{ $t(filter.name) }}
        </div>
      </div>
    </div>
  </card>
</template>
<script>
import Card from '@/components/atoms/Card';
import env from '@/helpers/env';

export default {
  name: 'extended-filter',
  props: {
    value: {},
    filters: {},
  },
  components: {
    Card,
  },
};
</script>
