<template>
  <ul class="multi-select-list">
    <li v-for="item in items"
        class="multi-select-list__item"
        :key="item.id">
      <label class="multi-select-list__label">
        <input type="checkbox"
               class="multi-select-list__checkbox"
               :value="item.id"
               :checked="selected.includes(item.id)"
               v-model="selectedList"
               @change="bubbleEvent" />
        <Icon name="bookmark"
              class="multi-select-list__icon"></Icon>
        <div class="multi-select-list__text">
          {{ item.name }}
        </div>
      </label>
    </li>
  </ul>
</template>
<script>
import Icon from '@/components/atoms/Icon';

export default {
  name: 'MultiSelectList',
  components: {
    Icon,
  },
  props: ['items', 'selected'],
  data() {
    return {
      selectedList: [],
    };
  },
  methods: {
    bubbleEvent() {
      this.$emit('update:selected-list', this.selectedList);
    },
  },
  mounted() {
    this.selectedList = this.selected;
    this.bubbleEvent();
  },
};
</script>
