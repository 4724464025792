<template>
  <form class="freetext-search"
        @submit.prevent="formHandler">
    <form-input v-model="form.freetext"
                class="freetext-search__form-item"
                :label="$t('myPolyclip.searchTerm')"
                type="search"
                name="search"
                :placeholder="$t('myPolyclip.giveSearchTerm')" />
    <btn icon="search"
         variant="highlighted"></btn>
  </form>
</template>
<script>
import FormInput from '@/components/molecules/FormInput';
import Btn from '@/components/molecules/Btn';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'FreetextSearch',
  components: { Btn, FormInput },
  data() {
    return {
      form: {
        freetext: '',
      },
    };
  },
  computed: {
    ...mapState('mypoly', ['filterText']),
  },
  methods: {
    ...mapActions('mypoly', ['setFilters']),
    async formHandler() {
      await this.setFilters(this.form);
      await this.$router.push({ name: 'MyLibrary' });
    },
  },
  mounted() {
    this.form.freetext = this.filterText;
  },
};
</script>
