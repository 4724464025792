<template>
  <a class="download"
     :href="link"
     target="_blank"
     style="width: 12px;"
     @click.prevent="handleDownload(link, name)">
    <icon v-if="loading"
          name="spinner"></icon>
    <icon v-else
          name="arrow-to-bottom"
    ></icon>
    <p v-if="hasLabel">
      Download
    </p>
  </a>
</template>
<script>
import Icon from '@/components/atoms/Icon';

export default {
  name: 'Download',
  props: {
    link: String,
    hasLabel: Boolean,
    name: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async handleDownload(link, name) {
      if (!this.loading) {
        this.loading = true;
        let blob = await fetch(link)
        .then(r => r.blob())
        .catch(e => {
          if (e?.response?.status !== 200) {
            this.loading = false;
          }
        });
        const path = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = path;
        if (name) {
          anchor.download = name;
        }
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        this.loading = false;
      }
    },
  },
  components: {
    Icon,
  },
};
</script>
