<template>
  <div class="clip-tip">
    <clip-tip></clip-tip>
  </div>
</template>

<script>
import ClipTip from '@/components/organisms/ClipTip';

export default {
  name: 'ClipTipView',
  components: {
    ClipTip,
  },
};
</script>
