<template>
  <div class="dashboard">
    <head-section img="finder-big">
      <page-title centered
                  class="m-bottom-huge"
                  :subline="$t('dashboard_sales.welcome')"
                  headline="Poly-Clip sales app" />
    </head-section>
    <div class="container">
      <highlighted-categories :items="getHighlightedItems" />
      <teasers-grid :items="getCategoriesByParentId(entry)"
                    to="ProductCategory"
                    :subtitles="false"
                    param="categoryId" />
      <product-finder-widget />
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HeadSection from '@/components/molecules/HeadSection';
import HighlightedCategories from '@/components/molecules/HighlightedCategories';
import PageTitle from '@/components/molecules/PageTitle';
import ProductFinderWidget from '@/components/organisms/ProductFinderWidget';
import TeasersGrid from '@/components/TeasersGrid';
import env from '@/helpers/env';

export default {
  name: 'Dashboard',
  components: {
    HeadSection,
    HighlightedCategories,
    PageTitle,
    ProductFinderWidget,
    TeasersGrid,
  },
  computed: {
    ...mapGetters('productCategories', ['getHighlightedItems', 'getCategoriesByParentId']),
    entry() {
      return Number(env('api_tessa_category'));
    },
  },
};
</script>
