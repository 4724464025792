<template>
  <div class="translation-manager">
    <modal v-model="infoModal"
           :title="`${$t('translation.infoCode')} ${info.code}`">
      <template #body>
        <h4 class="m-bottom-tiny"> {{ $t('translation.context') }}</h4>
        <ul>
          <li v-for="relation in info.relations"
              class="li">
            {{ relation.machine }}
            <span>{{ relation.attribute_name }}</span>
          </li>
        </ul>
      </template>
    </modal>
    <modal v-model="addModal"
           :title="$t('translation.newEntry')">
      <template #body>
        <form-input label="Code"
                    v-model="add.code"
                    hint="<strong>$t('translation.free').</strong> $t('translation.onlyTranslation')"></form-input>
        <div v-if="getTranslationByCode(add.code)">
          <alert class="m-bottom-small"
                 variant="success"
                 size="small">
            <div class="p1 strong">{{ $t('translation.translationExists') }}</div>
            {{ getTranslationByCode(add.code)['textblock-de_DE'] }}
          </alert>
        </div>
        <div v-else-if="add.code !== ''">
          <alert class="m-bottom-small"
                 variant="info"
                 size="small">
            <div class="p1 strong">{{ $t('translation.noTranslation') }}</div>
            {{ $t('translation.createNewEntry') }}
          </alert>
        </div>
        <form-select :label="$t('translation.machine')"
                     mandatory
                     v-model="add.machine"
                     :options="machines"></form-select>
        <form-select label="Attribut Name"
                     mandatory
                     v-model="add.attribute_name"
                     :options="attributes"></form-select>
      </template>
      <template #actions>
        <btn variant="highlighted"
             @click.native.prevent="addNew"
             :disabled="add.machine === '' || add.attribute_name === ''"
             full-width>
          {{ $t('translation.create') }}
        </btn>
      </template>
    </modal>

    <!-- without saved data or selected file -->
    <div v-if="entityReferences.length <= 0 && translations.length <= 0">
      <input
          type="file"
          style="display: none"
          multiple
          ref="inputFile"
          @change="handleFilesAdd">
      <div
          class="translation-manager__drop drop"
      >
        <h2 class="drop__title">{{ $t('translation.dropTitle') }}</h2>
        <p class="drop__text">{{ $t('translation.dropSubTitle') }}</p>
        <div
            class="drop__folder"
            @click="$refs.inputFile.click()"
            @drop.prevent.stop="handleDragEvent"
            @dragenter.prevent.stop="handleDragEvent"
            @dragleave.prevent.stop="handleDragEvent"
            @dragover.prevent.stop="handleDragEvent"
        >
          <span class="drop__folder-icon"></span>
          <span class="drop__text drop__text--no-spacing">{{ $t('translation.dropText') }}</span>
        </div>
        <div
            v-if="files.length"
            class="drop__text drop__text--files-info"
        >
          <span>{{ $t('translation.dropList') }}</span>
          <span
              class="drop__text--remove-all"
              @click="removeFile(null)"
          >{{ $t('translation.dropListRemoveAll') }}</span>
        </div>
        <div
            v-for="(file, index) in files"
            class="drop__files-list"
            :key="file.id"
            :data-index="index"
        >
                    <span
                        class="drop__text-icon drop__text-icon--sheet"
                        :class="`drop__text-icon--${file.error ? 'error' : 'success'}`"
                    ></span>
          <div>
                        <span
                            class="drop__text drop__text--in-line drop__text--no-spacing"
                            :class="{'drop__text--success': !file.error}"
                        >{{ file.name }}</span>
            <div
                v-if="file.error"
                class="drop__text drop__text--in-line drop__text--error drop__text--no-spacing"
            >{{ file.error }}
            </div>
          </div>
          <span
              class="drop__text drop__text-icon drop__text-icon--remove"
              @click="removeFile(file.id)"
          ></span>
        </div>
        <div v-if="this.uploadError"
             class="drop__error">
          {{ this.uploadError }}
        </div>
        <btn v-if="files.length"
             class="drop__button"
             variant="highlighted"
             :disabled="!isFilesHaveNoError || !!this.uploadError"
             @click.native.prevent="filesHandler">
          {{ $t('translation.dropListUpload') }}
        </btn>
      </div>
    </div>
    <div v-else
         class="translation-manager__table">
      <div class="translation-manager__scroller translation-manager__scroller--head">
        <div class="translation-manager__head">
          <btn variant="highlighted"
               class="m-right-tiny"
               @click.native.prevent="addModal=true">
            {{ $t('translation.newEntry') }}
          </btn>
          <btn variant="highlighted"
               class="m-right-tiny"
               @click.native.prevent="triggerDownloads">
            Download
          </btn>
          <btn variant="highlighted"
               class="m-right-tiny"
               @click.native.prevent="discard">
            {{ $t('translation.newImport') }}
          </btn>
          <form-input @keydown.native.enter="handleSearch"
                      @blur.native="handleSearch"
                      :placeholder="$t('translation.search')"></form-input>
        </div>
        <div class="translation-manager__row translation-manager__row--head">
          <div class="translation-manager__col"></div>
          <div class="translation-manager__col translation-manager__col--header"
               :class="{'translation-manager__col--hidden': hiddenCols.includes(editorHeaders[index])}"
               v-for="(head, index) in readableEditorHeaders">
                        <span v-if="!hiddenCols.includes(editorHeaders[index])">
                            {{ head }}
                        </span>
            <template v-if="hideableHeaders.includes(editorHeaders[index])">
              <btn @click.native.prevent="toggleHiddenCol(editorHeaders[index])"
                   variant="transparent"
                   :icon="hiddenCols.includes(editorHeaders[index]) ? 'eye-slash' : 'eye'"
                   tiny></btn>
            </template>
          </div>
        </div>
      </div>
      <RecycleScroller
          page-mode
          ref="scroller"
          :buffer="1000"
          class="translation-manager__scroller"
          :items="cached"
          :item-size="100"
          key-field="entityReferenceId"
          v-slot="{ index, item }">
        <div class="translation-manager__row"
             :class="{ 'translation-manager__row--2nd': Boolean(index % 2) }">
          <div class="translation-manager__col translation-manager__col--actions">
            <btn :disabled="!canMoveUp(item) || searchQuery !== ''"
                 variant="transparent"
                 @click.native.prevent="moveUp(item)"
                 icon="arrow-up"
                 tiny></btn>
            <btn :disabled="!canMoveDown(item) || searchQuery !== ''"
                 variant="transparent"
                 @click.native.prevent="moveDown(item)"
                 icon="arrow-down"
                 tiny></btn>
            <btn @click.native.prevent="handleDuplicate(item)"
                 :disabled="searchQuery !== ''"
                 variant="transparent"
                 icon="clone"
                 tiny></btn>
            <btn @click.native.prevent="handleDelete(item)"
                 variant="transparent"
                 icon="trash-alt"
                 tiny></btn>
            <btn @click.native.prevent="openRelationsModal(item)"
                 icon="print-search"
                 variant="transparent"
                 tiny></btn>
          </div>
          <div class="translation-manager__col"
               v-for="(col, colIndex) in editorHeaders"
               :class="{
                             'translation-manager__col--edited': item.edited && item.edited.includes(col),
                             'translation-manager__col--hidden': hiddenCols.includes(editorHeaders[colIndex])
                         }"
               :key="col">
            <div class="translation-manager__content"
                 :contenteditable="!hiddenCols.includes(editorHeaders[colIndex]) && !disabledInlineEditing.includes(editorHeaders[colIndex])"
                 @keydown.esc="handleAbort"
                 @blur="handleBlur($event, col, item)"
                 @focus="handleEnter"
                 v-html="item[col]">
            </div>
          </div>
        </div>
      </RecycleScroller>
    </div>
  </div>
</template>
<script>
import Modal from '@/components/organisms/Modal';
import Btn from '@/components/molecules/Btn';
import FormInput from '@/components/molecules/FormInput';
import { readFileAsText, getCsvHeaders, csvToArray, arrayToCsv } from '@/helpers/file';
import {
  transformCsvDataToEntityReferences,
  transformCsvDataToTranslations,
  transformEntityReferencesToCsvData, transformObjectByArray,
  transformTranslationsToCsvData,
} from '@/helpers/api';
import { v4 as uuidv4 } from 'uuid';
import Icon from '@/components/atoms/Icon';
import Tooltip from '@/components/molecules/Tooltip';
import FormSelect from '@/components/molecules/FormSelect';
import Alert from '@/components/molecules/Alert';

export default {
  name: 'translation-manager',
  components: {
    Alert,
    FormSelect,
    Tooltip,
    Icon,
    Modal,
    Btn,
    FormInput,
  },
  data() {
    return {
      hiddenCols: [],

      hideableHeaders: [
        'textblock-de_DE',
        'textblock-en_GB',
        'textblock-en_US',
        'textblock-es_ES',
        'textblock-pt_BR',
        'textblock-ru_RU',
      ],

      editorHeaders: [
        'code',
        'machine',
        'attribute_name',
        'textblock-de_DE',
        'textblock-en_GB',
        'textblock-en_US',
        'textblock-es_ES',
        'textblock-pt_BR',
        'textblock-ru_RU',
      ],

      validEntityReferenceHeaders: [
        'code',
        'machine',
        'attribute_name',
      ],
      validTranslationHeaders: [
        'code',
        'entityCode',
        'textblock-de_DE',
        'textblock-en_GB',
        'textblock-en_US',
        'textblock-es_ES',
        'textblock-pt_BR',
        'textblock-ru_RU',
      ],

      disabledInlineEditing: [
        'code',
        'machine',
        'attribute_name',
      ],

      machines: [],
      attributes: [],

      entityReferences: [],
      translations: [],

      cached: [],

      searchQuery: '',

      addModal: false,
      infoModal: false,

      possibleNewCode: 0,

      info: {
        code: '',
        relations: [],
      },

      add: {
        code: '',
        machine: '',
        attribute_name: '',
      },

      edit: {
        initialValue: '',
      },
      files: [],
      uploadError: '',
    };
  },
  methods: {
    // add new entry
    async addNew() {
      // close modal
      this.addModal = false;

      // if no code is chosen or the code is not valid, create a new one
      if (this.add.code === '' || this.getTranslationByCode(this.add.code) === undefined) {
        this.add.code = await this.generateNewCode();

        // create new empty translation
        const transformedTranslation = transformObjectByArray([...['translationId'], ...this.validTranslationHeaders]);
        transformedTranslation.translationId = uuidv4();
        transformedTranslation.code = this.add.code;
        transformedTranslation.entityCode = 'Textblocks';
        this.translations.push(transformedTranslation);
      }
      const filteredReferences = this.entityReferences.filter(er => {
        return er.machine === this.add.machine && er.attribute_name === this.add.attribute_name;
      });
      let lastIndex = -1;
      filteredReferences.forEach((reference) => {
        lastIndex = this.entityReferences.lastIndexOf(reference);
      });

      const newReferenceId = uuidv4();
      const newItem = {
        entityReferenceId: newReferenceId,
        ...this.add,
      };

      if (lastIndex === -1) {
        this.entityReferences.push(newItem);
      } else {
        this.entityReferences.splice(lastIndex + 1, 0, newItem);
      }

      // reset form
      this.add.code = '';
      this.add.machine = '';
      this.add.attribute_name = '';

      // generate cache
      await this.generateCache();
    },

    // get translation preview by adding a code
    getTranslationByCode(code) {
      return this.translations.find((translation => translation.code === code));
    },

    // toggle visibility of column
    toggleHiddenCol(col) {
      if (!this.hiddenCols.includes(col)) {
        this.hiddenCols.push(col);
      } else {
        const index = this.hiddenCols.findIndex((hc) => hc === col);
        this.hiddenCols.splice(index, 1);
      }
    },

    // add info to modal and open it
    openRelationsModal(item) {
      this.info.code = item.code;
      this.info.relations = item.related;
      this.infoModal = true;
    },

    // increment code
    async generateNewCode() {
      const code = String(this.possibleNewCode);
      this.possibleNewCode++;
      return code;
    },

    // checks for possible moves (up & down)
    canMoveUp(item) {
      const index = this.entityReferences.findIndex(er => er.entityReferenceId === item.entityReferenceId);
      return !!(this.entityReferences[index - 1]
          && item.machine === this.entityReferences[index - 1].machine
          && item.attribute_name === this.entityReferences[index - 1].attribute_name);
    },
    canMoveDown(item) {
      const index = this.entityReferences.findIndex(er => er.entityReferenceId === item.entityReferenceId);
      return !!(this.entityReferences[index + 1]
          && item.machine === this.entityReferences[index + 1].machine
          && item.attribute_name === this.entityReferences[index + 1].attribute_name);
    },

    // move item within array (up & down)
    async moveUp(item) {
      const itemToMove = this.entityReferences.find(er => er.entityReferenceId === item.entityReferenceId);
      const index = this.entityReferences.findIndex(er => er.entityReferenceId === item.entityReferenceId);
      this.entityReferences.splice(index, 1);
      this.entityReferences.splice(index - 1, 0, itemToMove);
      await this.generateCache();
    },
    async moveDown(item) {
      const itemToMove = this.entityReferences.find(er => er.entityReferenceId === item.entityReferenceId);
      const index = this.entityReferences.findIndex(er => er.entityReferenceId === item.entityReferenceId);
      this.entityReferences.splice(index, 1);
      this.entityReferences.splice(index + 1, 0, itemToMove);
      await this.generateCache();
    },

    // delete an entry
    async handleDelete(item) {
      const index = this.entityReferences.findIndex(er => er.entityReferenceId === item.entityReferenceId);
      this.entityReferences.splice(index, 1);

      await this.generateCache();
    },

    // duplicate an entry
    async handleDuplicate(item) {
      const transformedEntityReference = transformObjectByArray([...['entityReferenceId'], ...this.validEntityReferenceHeaders], item);
      const transformedTranslation = transformObjectByArray([...['translationId'], ...this.validTranslationHeaders], item);
      const index = this.entityReferences.findIndex(er => er.entityReferenceId === transformedEntityReference.entityReferenceId);
      const newCode = await this.generateNewCode();

      transformedEntityReference.entityReferenceId = uuidv4();
      transformedEntityReference.code = newCode;
      this.entityReferences.splice(index + 1, 0, transformedEntityReference);

      transformedTranslation.translationId = uuidv4();
      transformedTranslation.code = newCode;
      this.translations.push(transformedTranslation);

      await this.generateCache();
    },

    // set query and regenerate cache
    async handleSearch(e) {
      this.searchQuery = e.target.value.trim();
      await this.generateCache();
    },

    // generate inline downloads
    async createCsvDownload(csv, filename) {
      const uri = encodeURIComponent(csv);

      // Construct the <a> element
      const link = document.createElement('a');
      link.download = `${filename}.csv`;
      link.href = `data:text/csv;charset=utf-8,${uri}`;

      document.body.appendChild(link);
      link.click();

      // Cleanup the DOM
      document.body.removeChild(link);
    },

    // trigger the downloads
    async triggerDownloads() {
      const entityReferences = transformEntityReferencesToCsvData(this.entityReferences);
      const translations = transformTranslationsToCsvData(this.translations);
      const entityReferenceCsv = arrayToCsv(this.validEntityReferenceHeaders, entityReferences);
      const translationsCsv = arrayToCsv(this.validTranslationHeaders, translations);
      await this.createCsvDownload(entityReferenceCsv, 'entity-references');
      await this.createCsvDownload(translationsCsv, 'translations');
    },

    // discard imported files
    async discard() {
      this.entityReferences = [];
      this.translations = [];
      this.cached = [];
      this.machines = [];
      this.attributes = [];
    },

    // onKeyDown ESC abort editing the col
    async handleAbort(e) {
      if (e.key === 'Escape') {
        e.target.innerText = this.edit.initialValue;
        // this.edit.initialValue = '';
        e.target.blur();
      }
    },
    // onClick on the input / editable div
    async handleEnter(e) {
      this.edit.initialValue = e.target.innerText.trim();
    },
    // onBlur of the input / editable div
    async handleBlur(e, col, item) {
      if (e.target.innerText.trim() !== this.edit.initialValue) {
        const newValue = e.target.innerText.trim();
        const isEntityReferenceValue = this.validEntityReferenceHeaders.includes(col);
        const isTranslationValue = this.validTranslationHeaders.includes(col);

        if (isEntityReferenceValue) {
          await this.saveEntityReference({ ...item, [col]: newValue });
        } else if (isTranslationValue) {
          await this.saveTranslation({ ...item, [col]: newValue, edited: col });
        }

        await this.generateCache();
        this.initialValue = '';
      } else {
        e.target.innerText = this.edit.initialValue;
      }
    },

    // save entity reference
    async saveEntityReference(newItem) {
      const transformed = transformObjectByArray(this.validEntityReferenceHeaders, newItem);
      transformed.entityReferenceId = newItem.entityReferenceId;
      const objIndex = this.entityReferences.findIndex((entity => entity.entityReferenceId === transformed.entityReferenceId));
      this.entityReferences[objIndex] = transformed;
    },

    // save translation
    async saveTranslation(newItem) {
      let edited = [];
      const transformed = transformObjectByArray(this.validTranslationHeaders, newItem);
      transformed.translationId = newItem.translationId;

      const obj = this.translations.find((translation => translation.translationId === transformed.translationId));
      if (newItem.edited) {
        if (obj && obj.edited) {
          edited = [newItem.edited, ...obj.edited];
        } else {
          edited = [newItem.edited];
        }
      }

      if (transformed.translationId) {
        const objIndex = this.translations.findIndex((translation => translation.translationId === transformed.translationId));
        transformed.edited = edited;
        this.translations[objIndex] = transformed;
      } else {
        this.translations.push({
          ...transformed,
          translationId: uuidv4(),
          entityCode: 'Textblocks',
          edited,
        });
      }
    },

    // generates one big array within entity references and translations.
    async generateCache() {
      this.cached = this.entityReferences.map((entityReference) => {
        const translations = this.translations.find((t) => t.code === entityReference.code);
        const related = this.entityReferences.filter((er) => er.code === entityReference.code).map((er) => {
          return {
            machine: er.machine,
            attribute_name: er.attribute_name,
          };
        });
        return {
          ...entityReference,
          ...translations,
          related,
        };
      });

      // filtering the results by search criteria
      if (this.searchQuery !== '') {
        this.cached = this.cached.filter((item) => {
          return (
              item.code.includes(this.searchQuery)
              || item.machine.includes(this.searchQuery)
              || item['textblock-de_DE'] && item['textblock-de_DE'].includes(this.searchQuery)
              || item['textblock-en_GB'] && item['textblock-en_GB'].includes(this.searchQuery)
              || item['textblock-en_US'] && item['textblock-en_US'].includes(this.searchQuery)
              || item['textblock-es_ES'] && item['textblock-es_ES'].includes(this.searchQuery)
              || item['textblock-pt_BR'] && item['textblock-pt_BR'].includes(this.searchQuery)
              || item['textblock-ru_RU'] && item['textblock-ru_RU'].includes(this.searchQuery)
          );
        });
      }
    },

    // import of the csv files
    async filesHandler() {
      let entityReferences = [];
      let translations = [];

      // check files available otherwise log error.
      if (this.files.length <= 0) {
        console.error('Es wurden keine Dateien ausgewählt.');
        return;
      }

      // iterate trough every single entry;
      for (const file of this.files) {
        const rawFile = file.data;

        // read file as text
        const fileText = await readFileAsText(rawFile);
        const csvHeaders = getCsvHeaders(fileText);
        const csvEntries = csvToArray(fileText);

        // detect files - entity reference file and translation file is necessary!
        if (this.validEntityReferenceHeaders.every(v => csvHeaders.includes(v))) {
          console.info(`"${file.name}" wurde als Referenzdatei erkannt.`);
          entityReferences = transformCsvDataToEntityReferences(csvEntries);
        }
        if (this.validTranslationHeaders.every(v => csvHeaders.includes(v))) {
          console.info(`"${file.name}" wurde als Übersetzungsdatei erkannt.`);
          translations = transformCsvDataToTranslations(csvEntries);
        }
      }

      // check if both necessary files are given
      if (entityReferences.length <= 0) {
        const errorMessage = this.$t('translation.dropErrorNoReference');
        this.uploadError = errorMessage;
        console.error(errorMessage);
        return;
      }
      if (translations.length <= 0) {

        const errorMessage = this.$t('translation.dropErrorNoTranslation');
        this.uploadError = errorMessage;
        console.error(errorMessage);
        return;
      }

      // set data
      this.entityReferences = entityReferences;
      this.translations = translations;

      // save all machines as selectable options
      this.machines = [...new Set(entityReferences.map(m => m.machine))].map((m) => {
        return { name: m, value: m };
      });

      // save all attributes as selectable options
      this.attributes = [...new Set(entityReferences.map(m => m.attribute_name))].map((m) => {
        return { name: m, value: m };
      });

      // evaluate new possible code
      const entityReferenceCodes = entityReferences.map(m => Number(m.code));
      const translationCodes = translations.map(m => Number(m.code));
      const entityReferenceCodesMax = Math.max(...entityReferenceCodes);
      const translationCodesMax = Math.max(...translationCodes);
      this.possibleNewCode = Math.max(entityReferenceCodesMax, translationCodesMax);
      this.possibleNewCode++;

      // build cache to prevent performance issues
      await this.generateCache();
    },

    handleFilesAdd(event) {
      let files;
      try {
        files = Array.from(event?.target?.files || event?.dataTransfer?.files);
        if (!Array.isArray(files)) {
          throw new Error('Wrong format!');
        }
      } catch (e) {
        console.error(e.message);
      }

      files.forEach(file => this.addFile(file));
      this.uploadError = '';
    },
    addFile(file) {
      this.files.push({ id: file.name + ':' + Date.now(), name: file.name, error: '', data: file });

      if (file.type !== 'text/csv') {
        const error = `"${file.type}" ${this.$t('translation.dropErrorFormat')}`;
        this.addFileError(file.name, error);
        console.error(error);
      }
    },
    removeFile(fileId) {
      this.uploadError = '';
      if (fileId) {
        this.files = this.files.filter(({ id }) => fileId !== id);
        return;
      }

      this.files = [];
    },
    addFileError(fileName, error) {
      const file = this.files.find(({ name }) => name === fileName);
      if (file) {
        file.error = error;
      }
    },
    handleDragEvent(evt) {
      const dragEnterClass = 'drop--dragenter';
      const hasClass = evt.target.classList.contains(dragEnterClass);

      switch (evt.type) {
        case 'dragenter':
          if (hasClass) return;
          evt.target.classList.add(dragEnterClass);
          break;
        case 'dragleave':
        case 'dragend':
          hasClass && evt.target.classList.remove(dragEnterClass);
          break;
        case 'drop':
          hasClass && evt.target.classList.remove(dragEnterClass);
          this.handleFilesAdd(evt);
      }
    },
  },
  computed: {
    readableEditorHeaders() {
      return [
        'Code',
        'Maschine',
        'Attribut',
        'de (DE)',
        'en (GB)',
        'en (US)',
        'es (ES)',
        'pt (BR)',
        'ru (RU)',
      ];
    },
    isFilesHaveNoError() {
      return this.files.every(({ error }) => !error);
    },
  },
};
</script>
