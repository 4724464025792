<template>
  <div class="accordion"
       :class="{'accordion--open': open  }">
    <p class="accordion__label"
       @click="toggleAccordion">{{ label }}</p>
    <div class="accordion__body"
         :style="{ height: `${getHeight}px` }"
         ref="accordion">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Accordion',
  props: {
    label: String,
    preOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggleAccordion() {
      this.open = !this.open;
    },
    async evaluateSelected() {
      if (this.preOpened) {
        this.open = true;
      }
    },
  },
  computed: {
    getHeight() {
      return this.open && this.$refs['accordion'] ? this.$refs['accordion'].scrollHeight : 0;
    },
  },
  watch: {
    preOpened() {
      this.evaluateSelected();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.evaluateSelected();
    });
  },
};
</script>
