export const ROLE_SALES = 'sales_app';
export const ROLE_LEAD = 'lead_app';
export const ROLE_TRANSLATION_MANAGEMENT = 'translation_manager';
export const ROLE_MY_POLY = 'my_poly_app';

export const ROLE_MODULE_LEAD_AND_SALES = 'sales_app';
export const ROLE_MODULE_MYPOLY = 'my_poly_app';
export const ROLE_MODULE_OTHERS = 'translation_manager';

export function hasRole(requiredRoles, userRoles) {
  const checker = (arr, target) => target.every(v => arr.includes(v));
  if (typeof requiredRoles === 'object') {
    return checker(userRoles, requiredRoles);
  } else if (typeof requiredRoles === 'string') {
    return userRoles.includes(requiredRoles);
  } else {
    return true;
  }
}
