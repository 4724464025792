import env from '@/helpers/env';
import {
  tessaApi,
  tessaAuthHeaders,
  tessaErrorHandler,
  transformMyPolyCategoryTree,
  transformMyPolyData, transformMyPolyDerivate,
  transformMyPolyLanguages,
  transformToSelectArray,
} from '@/helpers/api';


const deepFindById = (id, s, idx = 0) => {
  if (!s || s.length === 0) return null;
  const item = s[idx];
  if (!item) return null;
  if (Number(item.id) === id) return item;
  const nextLevel = item.children && item.children.length ? [...s, ...item.children] : s;
  return deepFindById(id, nextLevel, idx + 1);
};

const deepGetCategoriesByTitle = (title, tree) => {
  const categories = [];
  const deep = (treeItem) => {
    if (treeItem.title.includes(title)) {
      categories.push(treeItem.id);
    }

    if (treeItem.children && treeItem.children.length > 0) {
      treeItem.children.forEach(childItem => {
        deep(childItem);
      });
    }
  };
  tree.forEach(deep);
  return categories;
};

const getDateFormatted = (d) => {
  const today = d;
  const date = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, 0) + '-' + String(today.getDate()).padStart(2, 0);
  const time = String(today.getHours()).padStart(2, 0) + ':' + String(today.getMinutes()).padStart(2, 0) + ':' + String(today.getSeconds()).padStart(2, 0);
  return date + ' ' + time;
};

const addDays = (date, days) => {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() + days);
  return copy;
};

export default {
  namespaced: true,
  state: {
    derivate: [],
    categoryTreeGeneral: [],
    categoryTreeMachines: [],
    languages: [],
    files: [],
    cachedFiles: [],
    filterExtended: null,
    filterSelectedGeneral: null,
    filterSelectedMachine: null,
    filterMachine: null,
    filterMachineNr: null,
    filterText: null,
    filterLanguage: null,
  },
  mutations: {
    SET(state, { key, value }) {
      state[key] = value;
    },
  },
  actions: {
    async handleBulkDownload({ rootGetters }, versionIds) {
      const today = new Date();
      const startDate = getDateFormatted(today);
      const endDate = getDateFormatted(addDays(today, 1));

      let apiKey = rootGetters['auth/getCurrentUser'].tessa_access_token;

      const downloadResponse = await tessaApi
      .post(`publications/publication`, {
        'media_ids': versionIds.join(','),
        'type': 'download',
        'settings': 'original',
        'start_date': startDate,
        'stop_date': endDate,
      }, tessaAuthHeaders(apiKey))
      .catch(tessaErrorHandler);
    },
    async fetchData({ commit, dispatch, rootGetters }) {
      await dispatch('startLoading', 'myPolyFetchData', { root: true });

      const sharepointCategory = env('api_tessa_mypoly_category');
      const machinesCategory = env('api_tessa_mypoly_machines_category');
      const languageCategory = env('api_tessa_mypoly_languages_category');
      const derivateCategory = env('api_tessa_mypoly_cat_derivate');

      let apiKey = rootGetters['auth/getCurrentUser'].tessa_access_token;

      const categoriesResponse = await tessaApi
      .get(`categories/sortedcategory/?searchterm={"status":[{"operator":"equal","value":"1"}]}`, tessaAuthHeaders(apiKey))
      .catch(tessaErrorHandler);

      if (categoriesResponse && categoriesResponse.data) {
        // relevant for later (language of files)
        const languageFolder = categoriesResponse.data.data.find(category => category.id === languageCategory);
        commit('SET', { key: 'languages', value: transformMyPolyLanguages(languageFolder?.children) });

        // tree
        const sharepointFolder = categoriesResponse.data.data.find(category => category.id === sharepointCategory);
        const machinesFolder = categoriesResponse.data.data.find(category => category.id === machinesCategory);
        const derivateFolder = categoriesResponse.data.data.find(category => category.id === derivateCategory);

        commit('SET', {
              key: 'categoryTreeGeneral',
              value: transformMyPolyCategoryTree(sharepointFolder?.children),
            },
        );
        commit('SET', {
              key: 'categoryTreeMachines',
              value: transformMyPolyCategoryTree(machinesFolder?.children),
            },
        );
        commit('SET', {
              key: 'derivate',
              value: transformMyPolyDerivate(derivateFolder?.children),
            },
        );
      }

      const filesResponse = await tessaApi
      .get(`media/asset?categories=${env('api_tessa_mypoly_category')}&page_size=7000&attributes=version_id,id,asset_trio_sap,name,file_name,file_type,file_size,categories,download,preview&searchterm={"external": [{"operator":"equal", "value":"1"}]}`, tessaAuthHeaders(apiKey))
      .catch(tessaErrorHandler);

      if (filesResponse && filesResponse.data) {
        commit('SET', {
              key: 'files',
              value: transformMyPolyData(filesResponse.data),
            },
        );
      }

      await dispatch('createFileCache');
      await dispatch('endLoading', 'myPolyFetchData', { root: true });
    },

    async resetFilters({ commit, dispatch }) {
      commit('SET', { key: 'filterSelectedGeneral', value: null });
      commit('SET', { key: 'filterSelectedMachine', value: null });
      commit('SET', { key: 'filterMachine', value: null });
      commit('SET', { key: 'filterMachineNr', value: null });
      commit('SET', { key: 'filterLanguage', value: null });
      commit('SET', { key: 'filterText', value: null });
      commit('SET', { key: 'filterExtended', value: null });

      await dispatch('createFileCache');
    },

    async unsetFilter({ state, commit, dispatch }, filter) {
      commit('SET', { key: filter, value: null });
      await dispatch('createFileCache');
    },

    async setFilters({ state, commit, dispatch }, filters) {

      const keys = Object.keys(filters);
      if (filters.selectedGeneral) {
        commit('SET', { key: 'filterSelectedGeneral', value: filters.selectedGeneral });
      }
      if (filters.selectedMachine) {
        commit('SET', { key: 'filterSelectedMachine', value: filters.selectedMachine });
      }
      // extended
      if (filters.selectedGeneral === Number(env('api_tessa_mypoly_image_category'))) {
        commit('SET', { key: 'filterExtended', value: Number(filters.extended) });
      } else {
        commit('SET', { key: 'filterExtended', value: null });
      }

      // machine
      const isMachine = keys.includes('machine');
      if (isMachine && filters.machine) {
        commit('SET', { key: 'filterMachine', value: filters.machine });
      } else if (isMachine) {
        commit('SET', { key: 'filterMachine', value: null });
      }

      // machine nr
      const isMachineNr = keys.includes('machineNr');
      if (isMachineNr && filters.machineNr) {
        commit('SET', { key: 'filterMachineNr', value: filters.machineNr });
      } else if (isMachineNr) {
        commit('SET', { key: 'filterMachineNr', value: null });
      }

      // language
      if (typeof filters.language === 'string') {
        const language = filters.language === '' || filters.language === 'null' ? null : filters.language;
        commit('SET', { key: 'filterLanguage', value: language });
      }

      // freetext
      const isFreetext = keys.includes('freetext');
      if (isFreetext && filters.freetext) {
        commit('SET', { key: 'filterText', value: filters.freetext });
      } else if (isFreetext) {
        commit('SET', { key: 'filterText', value: null });
      }

      await dispatch('createFileCache');
    },

    async createFileCache({ commit, state }) {
      let files = state.files;
      const machineCategories = state.categoryTreeMachines;
      let machinesToFilterBy = [];
      let categoriesToFilterBy = [];

      // get all machine ids with matching name
      if (state.filterMachine) machinesToFilterBy.push(...deepGetCategoriesByTitle(state.filterMachine, machineCategories));
      // get selected general category
      if (state.filterSelectedGeneral) categoriesToFilterBy.push(state.filterSelectedGeneral);
      // get selected machine category
      if (state.filterSelectedMachine) categoriesToFilterBy.push(state.filterSelectedMachine);
      // get selected language category
      if (state.filterLanguage) categoriesToFilterBy.push(state.filterLanguage);
      // set extended filters if needed
      if (state.filterSelectedGeneral === Number(env('api_tessa_mypoly_image_category'))) {
        if (state.filterExtended) categoriesToFilterBy.push(state.filterExtended);
      }

      // check machines
      if (machinesToFilterBy.length > 0) {
        files = files.filter(file => {
          const categories = file.categories.split(',');
          return machinesToFilterBy.some(f => categories.includes(String(f)));
        });
      }

      // check machine nr
      if (state.filterMachineNr) {
        files = files.filter((file) => {
          let saps = file.sap.split(';');
          if (saps.length <= 0) {
            saps = file.sap.split('.');
          }
          return saps.some(s => s === state.filterMachineNr);
        });
      }

      if (categoriesToFilterBy.length > 0) {
        files = files.filter(file => {
          const categories = file.categories.split(',');
          return categoriesToFilterBy.every(f => categories.includes(String(f)));
        });
      }

      // check freetext search
      if (state.filterText) {
        files = files.filter(file => {
          return file.name.toLowerCase().includes(state.filterText.toLowerCase());
        });
      }

      // set cache
      commit('SET', { key: 'cachedFiles', value: files });
    },
  },
  getters: {
    getHighlightedCategories(state) {
      const filteredGeneral = state.categoryTreeGeneral.filter(item => item.highlighted);
      const filteredMachines = state.categoryTreeMachines.filter(item => item.highlighted);
      return [...filteredGeneral, ...filteredMachines].sort(function (a, b) {
        return a.sort - b.sort;
      });
    },
    getCategories(state) {
      const filteredGeneral = state.categoryTreeGeneral.filter(item => !item.highlighted);
      const filteredMachines = state.categoryTreeMachines.filter(item => !item.highlighted);
      return [...filteredGeneral, ...filteredMachines].sort(function (a, b) {
        return a.sort - b.sort;
      });
    },
    getCategoryById: state => id => {
      return [...state.categoryTreeGeneral, ...state.categoryTreeMachines].filter(item => item.id === id)[0];
    },
    getCategoryGeneral(state) {
      return state.categoryTreeGeneral;
    },
    getCategoryMachines(state) {
      return state.categoryTreeMachines;
    },
    getDeepGeneralCategoryById: state => categoryId => {
      return deepFindById(Number(categoryId), state.categoryTreeGeneral);
    },
    getDeepMachineCategoryById: state => categoryId => {
      return deepFindById(Number(categoryId), state.categoryTreeMachines);
    },
    getLanguagesForSelect(state) {
      return transformToSelectArray(state.languages);
    },
    getLanguageById: state => id => {
      return state.languages.filter(lang => lang.id === Number(id))[0];
    },
  },
};
