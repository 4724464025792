<template>
  <label class="form-label"
         :class="classes"
         :for="reference">
    <slot></slot>
  </label>
</template>
<script>
export default {
  name: 'FormLabel',
  props: {
    reference: {},
    main: Boolean,
    big: Boolean,
    dark: Boolean,
  },
  computed: {
    classes() {
      return {
        [`form-label--main`]: this.main,
        [`form-label--big`]: this.big,
        [`form-label--dark`]: this.dark,

      };
    },
  },
};
</script>
