<template>
  <div class="login">
    <div class="login__img"></div>
    <form class="login__form"
          action="#"
          @submit.prevent="handleLogin">
      <logo />
      <logo mobile />
      <div class="login__form-title">{{ $t('login.welcome') }}</div>

      <error-banner v-if="!online">
        {{ $t('errors.connection') }}
      </error-banner>
      <template v-else>
        <error-banner v-if="error"
                      class="m-bottom-medium">
          <div v-html="getTranslatedError" />
        </error-banner>

        <form-input v-model="form.name"
                    type="text"
                    :label="$t('login.user')" />
        <form-input v-model="form.password"
                    type="password"
                    :label="$t('login.password')" />
        <span class="login__info login__info--gdpr"
              v-html="gdprMessage"></span>
        <btn :disabled="isLoading('login')"
             class="login__form-button"
             type="submit"
             variant="highlighted"
             full-width>
          {{ $t('login.submit') }}
        </btn>

        <btn :disabled="isLoading('login')"
             variant="decent"
             push-right
             target="_blank"
             spaced-top
             :href="getForgotPasswordURL">
          {{ $t('login.forgotPassword') }}
        </btn>
        <div class="login__note">
          {{ $t('login.note') }} <a href="mailto:marketing@polyclip.de">marketing@polyclip.de</a>
        </div>
        <div class="login__info">
          <a href="https://www.polyclip.com/de/impressum"
             class="login__link"
             target="_blank">{{ $t('login.imprint') }}</a> &middot;
          <a :href="$t('login.data_privacy_link')"
             class="login__link"
             target="_blank">{{ $t('login.data_privacy') }}</a> &middot;
          <a :href="$t('login.terms_of_use_link')"
             class="login__link"
             target="_blank">{{ $t('login.terms_of_use') }}</a>
        </div>
        <language-switch class="language-switch__login"></language-switch>
        <version class="version--login" />
      </template>
    </form>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import Logo from '@/components/atoms/Logo';
import Btn from '@/components/molecules/Btn';
import FormInput from '@/components/molecules/FormInput';
import ErrorBanner from '@/components/molecules/ErrorBanner';
import LanguageSwitch from '@/components/molecules/LanguageSwitch';
import Version from '@/components/atoms/Version';

export default {
  name: 'LoginForm',
  components: { FormInput, Btn, Logo, ErrorBanner, LanguageSwitch, Version },
  data() {
    return {
      error: null,
      form: {
        name: '',
        password: '',
      },
    };
  },
  computed: {
    ...mapGetters(['isLoading']),
    ...mapState(['online']),
    ...mapGetters('auth', ['getPasswordChangeURL', 'getForgotPasswordURL']),

    getTranslatedError() {
      return this.$t(this.error, { passwordChangeURL: this.getPasswordChangeURL });
    },
    gdprMessage() {
      return this.$t('login.gdpr', {
        privacyPolicy: this.$t('login.data_privacy_link'),
        terms: this.$t('login.terms_of_use_link'),
      });
    },
  },
  methods: {
    ...mapActions('auth', ['loginUser']),
    async handleLogin() {
      this.error = null;
      const response = await this.loginUser(this.form);

      if (!response) {
        this.error = `errors.unexpected`;
      } else if (response?.errorCode) {
        this.error = `errors.${response?.errorCode}`;
      }

      this.form.password = '';
    },
  },
};
</script>
