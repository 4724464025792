<template>
  <div class="data-table">

    <div class="data-table__head-wrapper">
      <div class="data-table__head-filters">
        <slot name="filters"></slot>
      </div>
      <div class="data-table__head-actions">
        <btn @click.native.prevent="withPreview = false"
             :active="!withPreview"
             class="m-left-auto"
             icon="bars"
             tiny
             variant="toggle"></btn>

        <btn @click.native.prevent="withPreview = true"
             :active="!!withPreview"
             icon="th-list"
             tiny
             variant="toggle"></btn>
      </div>
      <ul class="data-table__head">
        <li class="data-table__col"
            :class="{ [`data-table__col--${head.field}`]: head.field }"
            v-for="head in headers">
          {{ $t(head.name) }}
        </li>
        <li class="data-table__col data-table__col--actions">
          <div class="data-table__desktop">
          </div>
        </li>
      </ul>
    </div>


    <ul class="data-table__content">
      <DynamicScroller
          page-mode
          :buffer="1000"
          :items="items"
          :min-item-size="39"
          class="scroller">
        <template v-slot="{ item, index, active }">
          <DynamicScrollerItem
              :item="item"
              :active="active"
              :data-index="index">
            <data-table-accordion v-if="isAccordion(item)"
                                  :headers="headers"
                                  :item="item"
                                  :open-mobile-menu="openMobileMenu"
                                  :with-preview="withPreview"
                                  :get-referenced-languages="getReferencedLanguages"
                                  :get-referenced-derivate="getReferencedDerivate">
              <slot :item="item"></slot>
            </data-table-accordion>
            <div class="data-table__item"
                 v-else>
              <div v-for="head in headers"
                   class="data-table__col"
                   :class="{ [`data-table__col--${head.field}`]: head.field }">
                <template v-if="typeof item[head.field] === 'object'">
                  {{ item[head.field].join(', ') }}
                </template>
                <template v-else>
                  <template v-if="head.field === 'name'">
                    <div v-if="withPreview"
                         class="data-table__img">
                      <img :src="item.preview"
                           :alt="item.name">
                    </div>
                    <div>
                      <div class="data-table__item-name">
                        {{ item[head.field] }}
                      </div>
                      <div class="data-table__mobile-subheading">

                        {{ item.type }} &middot;
                        {{ getReferencedLanguages(item.categories) }} &middot;
                        {{ $filters.bytes(item.size) }}
                      </div>
                    </div>
                  </template>
                  <template v-else-if="head.field === 'size'">
                    {{ $filters.bytes(item[head.field]) }}
                  </template>
                  <template v-else-if="head.field === 'languages'">
                    {{ getReferencedLanguages(item.categories) }}
                  </template>
                  <template v-else>
                    {{ item[head.field] }}
                  </template>
                </template>
              </div>
              <div class="data-table__col data-table__col--actions">
                <div class="data-table__desktop">
                  <slot :item="item"></slot>
                  <download :name="item.name"
                            :link="cdnLink(item.name)" />
                </div>
                <div class="data-table__mobile">
                  <icon name="ellipsis-h"
                        @click.native="openMobileMenu(item)" />
                </div>
              </div>
            </div>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
    </ul>

    <transition name="data-table__mobile-menu">
      <div v-if="mobileMenuOpen"
           class="data-table__mobile-menu-wrapper">
        <div class="data-table__mobile-menu-underlay"
             @click="closeMobileMenu"></div>
        <div class="data-table__mobile-menu">
          <div class="container">
            <div class="data-table__mobile-menu-header">
              <div>
                <div class="data-table__mobile-menu-heading">
                  {{ mobileMenuData.name }}
                </div>
                <div class="data-table__mobile-menu-subheading">
                  {{ mobileMenuData.type }} &middot;
                  {{ getReferencedLanguages(mobileMenuData.categories) }} &middot;
                  {{ $filters.bytes(mobileMenuData.size) }}
                </div>
              </div>
              <div>
                <Icon name="times"
                      variant="grey"
                      @click.native="closeMobileMenu" />
              </div>
            </div>
            <div class="data-table__mobile-menu-body">
              <div class="data-table__mobile-menu-option">
                <add-to-wishlist :item="mobileMenuData"
                                 has-label />
              </div>
              <a v-if="mobileMenuData.preview.length > 0"
                 :href="mobileMenuData.preview"
                 target="_blank"
                 class="data-table__mobile-menu-option">
                <icon name="eye" />
                <p>Vorschau</p>
                <br>
              </a>
              <div class="data-table__mobile-menu-option"
                   v-if="!isAccordion(mobileMenuData)">
                <Download :link="cdnLink(mobileMenuData.name)"
                          :name="mobileMenuData.name"
                          has-label />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

  </div>
</template>
<script>
import Btn from '@/components/molecules/Btn';
import Icon from '@/components/atoms/Icon';
import AddToWishlist from '@/components/atoms/AddToWishlist';
import Download from '@/components/atoms/Download';
import { mapActions, mapState } from 'vuex';
import DataTableAccordion from '@/components/molecules/DataTableAccordion';
import env from '@/helpers/env';

export default {
  name: 'DataTable',
  components: { DataTableAccordion, AddToWishlist, Icon, Btn, Download },
  data() {
    return {
      withPreview: false,
      selectedDownloads: [],
      mobileMenuOpen: false,
      mobileMenuData: {},
    };
  },
  methods: {
    ...mapActions('mypoly', [
      'handleBulkDownload',
    ]),
    isAccordion(item) {
      const dIds = this.derivate.map((d) => d.id);
      const itemCategories = item.categories.split(',').map((i) => {
        return Number(i);
      });
      return dIds.some(r => itemCategories.indexOf(r) >= 0);
    },
    getReferencedDerivate(itemCategories) {
      const categories = itemCategories.split(',');
      const filteredDerivate = this.derivate.filter(d => categories.includes(`${d.id}`));
      return filteredDerivate.map(d => {
        return d.title;
      });
    },
    openMobileMenu(menuData) {
      this.mobileMenuData = menuData;
      this.mobileMenuOpen = true;
    },
    closeMobileMenu() {
      this.mobileMenuOpen = false;
    },
    getReferencedLanguages(itemCategories) {
      const categories = itemCategories.split(',');
      const filteredLanguages = this.languages.filter(l => categories.includes(`${l.id}`));
      return filteredLanguages.map(l => l.shortcut).join(', ');
    },
    cdnLink(name) {
      return `${env('cdn_tessa')}/${name}`;
    },
  },
  props: {
    multiSelect: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
    },
    items: {
      type: Array,
    },
  },
  computed: {
    ...mapState('mypoly', [
      'languages',
      'derivate',
    ]),
  },
};
</script>
<style scoped>
.scroller {
  height: 100%;
}
</style>
