<template>
  <div class="form-select"
       :class="classes">
    <form-label :reference="`form-select${uid}`">
      {{ label }}
      <span v-if="mandatory">*</span>
    </form-label>
    <div class="form-select__select-wrapper m-top-tiny"
         :class="{'form-select__select--error': error }">
      <select @input="handle"
              :id="`form-select${uid}`"
              :disabled="disabled"
              :readonly="readOnly">
        <option :selected="!modelValue"
                value=""> {{ $t('formSelect.pleaseChoose') }}
        </option>
        <option v-for="option in options"
                :selected="option.value === modelValue"
                :value="option.value">
          <template v-if="disableTranslations">{{ option.name }}</template>
          <template v-else-if="multiLanguageFields">{{ option[`name_${$i18n.locale}`] }}</template>
          <template v-else>{{ $t(option.name) }}</template>
        </option>
      </select>
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import FormLabel from '@/components/atoms/FormLabel';

export default {
  name: 'FormSelect',
  emits: ['update:modelValue'],
  props: {
    modelValue: {},
    label: {},
    options: {},
    mandatory: Boolean,
    error: Boolean,
    isSpaceless: Boolean,
    readOnly: Boolean,
    disabled: Boolean,
    multiLanguageFields: {
      type: Boolean,
      default: false,
    },
    disableTranslations: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FormLabel,
  },
  methods: {
    handle(e) {
      this.$emit('update:modelValue', e.target.value);
    },
  },
  computed: {
    classes() {
      return {
        ['form-select--spaceless']: this.isSpaceless,
      };
    },
    uid() {
      return uuidv4();
    },
  },
};
</script>
