<template>
  <div class="form-radios">
    <form-label :main="main"
                big>
      {{ label }}
      <span v-if="mandatory">*</span>
    </form-label>
    <div v-if="resetOption"
         class="form-radios__radio-wrapper"
         :class="classes">
      <input @input="handle"
             class="form-radios__radio"
             :id="`form-radios${name}-reset`"
             type="radio"
             :checked="!value"
             value=""
             :name="name"
      >
      <form-label :dark="dark"
                  :reference="`form-radios${name}-reset`">{{ $t('productFinder.all') }}
      </form-label>
    </div>
    <div class="form-radios__radio-wrapper"
         v-for="(option, index) in options"
         :class="classes">
      <input @input="handle"
             class="form-radios__radio"
             :disabled="disabled"
             :id="`form-radios-${name}${index}`"
             type="radio"
             :checked="option.value === value"
             :value="option.value || option.id"
             :name="name">
      <form-label :dark="dark"
                  :reference="`form-radios-${name}${index}`">{{ option.name }}
      </form-label>
    </div>
  </div>
</template>
<script>
import FormLabel from '@/components/atoms/FormLabel';

export default {
  name: 'FormRadios',
  props: {
    value: {},
    name: {},
    label: {},
    options: {},
    resetOption: Boolean,
    mandatory: Boolean,
    readOnly: Boolean,
    disabled: Boolean,
    main: Boolean,
    dark: Boolean,
    centered: Boolean,
  },
  components: {
    FormLabel,
  },
  methods: {
    handle(e) {
      this.$emit('update:value', e.target.value);
    },
  },
  computed: {
    classes() {
      return {
        ['form-radios__radio-wrapper--centered']: this.centered,
      };

    },
  },
};
</script>
