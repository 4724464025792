<template>
  <div class="product-industries"
       :class="classes">
    <underline ref="underline"
               side="bottom"
               :underline-present="productFinder">
      <!-- 'All' item -->
      <div class="product-industries__item"
           :class="classesAll"
           :data-active="!modelValue"
           @click="industry = null; $refs.underline.selectTab()"
      >
        {{ $t('productFinder.all') }}
      </div>
      <div class="product-industries__item"
           v-for="item in items"
           :class="classesItems(item)"
           :data-active="modelValue && modelValue.id === item.id"
           @click="industry = item; $refs.underline.selectTab()"
      >
        {{ item.name }}
      </div>
    </underline>
  </div>
</template>
<script>
import Underline from '@/components/atoms/Underline';

export default {
  name: 'ProductIndustries',
  components: { Underline },
  props: {
    modelValue: {}, // v-model
    items: {
      type: Array,
      required: true,
    },
    productFinder: Boolean,
  },
  computed: {
    industry: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:model-value', newValue);
      },
    },
    classes() {
      return {
        ['product-industries--finder']: this.productFinder,
      };
    },
    classesAll() {
      return {
        [`product-industries--finder-item`]: this.productFinder && !this.modelValue,
        ['product-industries__item--active']: !this.modelValue,
      };
    },

  },
  methods: {
    classesItems(item) {
      return {
        [`product-industries--${item.machine_name}`]: item.machine_name,
        [`product-industries--finder-item`]: this.productFinder && this.modelValue && this.modelValue.id === item.id,
        ['product-industries__item--active']: this.modelValue && this.modelValue.id === item.id,
      };
    },
  },
};
</script>
