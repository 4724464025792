<template>
  <div v-if="form">
    <!-- Head Section -->
    <head-section img="leads">
      <div>
        <page-title centered
                    :headline="$t('leads.name')" />

        <div class="step-progress m-top-big m-bottom-very-big">
          <ul class="step-progress__items">
            <li v-for="(s, sIndex) in steps"
                @click.prevent="s.name !== 'leads.completion' ? step = sIndex : null"
                class="step-progress__item"
                :class="{
                                'step-progress__item--hoverable': s.name !== 'leads.completion',
                                'step-progress__item--wip': stepState[sIndex] === 'wip',
                                'step-progress__item--error': stepState[sIndex] === 'error' || errors.some(r => s.fields.indexOf(r) >= 0),
                                'step-progress__item--done': stepState[sIndex] === 'done',
                                'step-progress__item--submitted': s.name === 'leads.completion' && form.submitted,
                            }">
              <div class="step-progress__icon"></div>
              <div class="step-progress__name">
                {{ $t(s.name) }}
              </div>
            </li>
          </ul>
          <div class="step-progress__bar">
            <div class="step-progress__fill"
                 :style="progress"></div>
          </div>
        </div>

      </div>
    </head-section>

    <div class="container">
      <card class="lead__data"
            is-spaceless
            is-shadowed>

        <div class="form-stepper">
          <div class="form-stepper__content">
            <error-banner v-for="(detail, index) in errorDetails"
                          class="m-bottom-tiny"
                          :key="`errorbanner${index}`">
              {{ detail }}
            </error-banner>
            <error-banner v-if="errors.length > 0 || checkMandatoryFields && stepState.some(a => a === 'error')"
                          class="m-bottom-big">
              {{ $t('leads.couldntSave') }}
            </error-banner>
            <page-title :headline=" $t(steps[step].name) "
                        class="m-bottom-big"
                        variant="lead"
                        btn-right>
              <btn icon="save"
                   class="lead__data-stepper-button"
                   :disabled="differsLength <= 0"
                   @click.native.prevent="handleSave">
                {{ $t('leads.saveLead') }}
              </btn>
            </page-title>
            <!-- Step 0 -->
            <div v-if="step === 0"
                 class="detail-form row">
              <div class="grid-m-6">
                <!-- TODO: why is there a calendar picker indicator? -->
                <form-input type="text"
                            :label="$t('leads.tradeFair')"
                            list="messe"
                            :placeholder="$t('leads.whichFair')"
                            v-model="form.fair"
                            :disabled="form.submitted"
                            mandatory />
                <datalist id="messe">
                  <option value="IFFA 2022" />
                  <option value="Termin" />
                </datalist>
              </div>
              <div class="grid-m-6">
                <form-input type="text"
                            :label="$t('leads.contact_creator')"
                            v-model="form.contact_creator"
                            :disabled="form.submitted"
                            mandatory />
              </div>
              <div class="grid-m-6">
                <form-input type="text"
                            class="m-bottom-0-m-l-xl"
                            label="Lead-ID"
                            v-model="form.lead_id"
                            read-only />
              </div>
              <div class="grid-m-6">
                <form-input type="date"
                            class="m-bottom-0"
                            :label="$t('leads.visitingDay')"
                            :disabled="form.submitted"
                            v-model="form.visiting_day" />
              </div>
            </div>
            <!-- /Step 0 -->
            <!-- Step Contact -->
            <div v-if="step === 2"
                 class="row">

              <div class="grid-m-6 m-bottom-small">
                <form-select :label="$t('leads.salutation')"
                             mandatory
                             :disabled="form.submitted"
                             v-model="form.salutation"
                             :options="salutationOptions"></form-select>
              </div>

              <div class="grid-m-6 m-bottom-small">
                <form-select :label="$t('leads.title')"
                             :disabled="form.submitted"
                             v-model="form.title"
                             :options="titleOptions"></form-select>
              </div>

              <div class="grid-m-6">
                <form-input type="text"
                            :label="$t('leads.fName')"
                            :disabled="form.submitted"
                            :placeholder="$t('leads.howLName')"
                            v-model="form.first_name"
                            mandatory />
              </div>

              <div class="grid-m-6">
                <form-input type="text"
                            :label="$t('leads.lName')"
                            :disabled="form.submitted"
                            :placeholder="$t('leads.howLName')"
                            v-model="form.last_name"
                            mandatory />
              </div>

              <div class="grid-12 m-bottom-small">
                <form-select :label="$t('leads.status')"
                             mandatory
                             :disabled="form.submitted"
                             v-model="form.status"
                             :options="statusOptions"></form-select>
              </div>

              <div class="grid-12">
                <form-input type="text"
                            :label="$t('leads.email')"
                            :placeholder="$t('leads.howEmail')"
                            :disabled="form.submitted"
                            :error="errors.includes('email')"
                            v-model="form.email"
                            mandatory />
              </div>

              <div class="grid-12">
                <form-input type="text"
                            class="m-bottom-0"
                            :label="$t('leads.phone')"
                            :disabled="form.submitted"
                            :placeholder="$t('leads.howPhone')"
                            v-model="form.tel"
                            mandatory />
              </div>
              <!--              Temporary disabled due to Mautic not supporting file uploads-->
              <!--              <div class="grid-12 m-top-small m-bottom-0">-->
              <!--                <business-card-upload>-->
              <!--                  <template #business-card-back>-->
              <!--                    <attachment-upload-->
              <!--                        :disabled="form.submitted"-->
              <!--                        v-model="form.business_card_back"-->
              <!--                        preview />-->
              <!--                  </template>-->

              <!--                  <template #business-card-front>-->
              <!--                    <attachment-upload-->
              <!--                        :disabled="form.submitted"-->
              <!--                        v-model="form.business_card_front"-->
              <!--                        preview />-->
              <!--                  </template>-->
              <!--                </business-card-upload>-->
              <!--              </div>-->
            </div>
            <!-- /Step 1 -->
            <!-- Step Company -->
            <div v-if="step === 1"
                 class="row">
              <div class="grid-12">
                <form-input type="text"
                            :label="$t('leads.company')"
                            :disabled="form.submitted"
                            :placeholder="$t('leads.whichCompany')"
                            v-model="form.company"
                            mandatory />
              </div>
              <div class="grid-m-6 m-bottom-small">
                <form-select :label="$t('leads.industry')"
                             mandatory
                             :disabled="form.submitted"
                             v-model="form.sector"
                             :options="sectorOptions"></form-select>
              </div>
              <div class="grid-m-6 m-bottom-small">
                <form-select :label="$t('leads.companySize')"
                             mandatory
                             :disabled="form.submitted"
                             v-model="form.company_size"
                             :options="companySizeOptions"></form-select>
              </div>
              <div class="grid-m-6 m-bottom-small">
                <form-select :label="$t('leads.country')"
                             mandatory
                             :error="errors.includes('country')"
                             :disabled="form.submitted"
                             v-model="form.country"
                             :options="countryOptions"></form-select>
              </div>
              <div class="grid-m-6">
                <form-input type="text"
                            :label="$t('leads.postal_code')"
                            class="m-bottom-0"
                            :disabled="form.submitted"
                            :placeholder="$t('leads.which_postal_code')"
                            v-model="form.postal_code"
                />
              </div>
            </div>
            <!-- /Step 2 -->
            <!-- Step 3 -->
            <div v-if="step === 3"
                 class="row">
              <template v-for="(option, index) in getItems">
                <div class="nested-form grid-l-3"
                     v-if="option && option.id && getItemsByCategoryId(option.id).length > 0">
                  <div>
                    <input class="form-checkboxes__radio"
                           :id="`form-checkboxes_${uid}_${index}`"
                           type="checkbox"
                           :disabled="form.submitted"
                           :checked="checkCategorySelect(option.id)"
                           @input="handleCategorySelect({id: option.id, event: $event})"
                           name="categories">
                    <form-label big
                                :reference="`form-checkboxes_${uid}_${index}`">
                      {{ option.title }}
                    </form-label>
                  </div>
                  <form-checkboxes type="text"
                                   name="products"
                                   :disabled="form.submitted"
                                   v-model="form.machines"
                                   :options="getItemsByCategoryId(option.id)">
                  </form-checkboxes>
                </div>
              </template>
            </div>
            <!-- /Step 3 -->
            <!-- Step 4 -->
            <div v-if="step === 4"
                 class="row">
              <div class="grid-m-6 m-bottom-big">
                <form-radios type="text"
                             :label="$t('leads.priority')"
                             name="priority"
                             mandatory
                             :disabled="form.submitted"
                             :value="form.priority"
                             v-on:update:value="form.priority = $event"
                             :options="priorityOptions" />
              </div>
              <!--Temporary disabled due to Mautic not supporting file uploads-->
              <!--              <div class="grid-m-6 m-bottom-big">-->
              <!--                <attachment-upload v-model="form.attachment1"-->
              <!--                                   button-left-->
              <!--                                   :label="$t('leads.attachment1')"-->
              <!--                                   :big-label="$t('leads.attachments')"-->
              <!--                                   :disabled="form.submitted"-->
              <!--                                   bordered />-->
              <!--                <attachment-upload v-model="form.attachment2"-->
              <!--                                   button-left-->
              <!--                                   :label="$t('leads.attachment2')"-->
              <!--                                   :disabled="form.submitted"-->
              <!--                                   bordered />-->
              <!--              </div>-->
              <div class="grid-m-6">
                <form-checkboxes type="text"
                                 class="m-bottom-big"
                                 lead-spacing="m-left-very-small"
                                 :label="$t('leads.followUp')"
                                 name="follow_up_actions"
                                 :disabled="form.submitted"
                                 v-model="form.follow_up_actions"
                                 :options="followUpActionsOptions" />
              </div>
              <div class="grid-m-6">
                <form-checkboxes type="text"
                                 lead-spacing="m-left-very-small"
                                 :label="$t('leads.AddAdditional')"
                                 name="additional"
                                 :disabled="form.submitted"
                                 v-model="form.additional"
                                 :options="additionalOptions" />
              </div>
              <div class="grid-m-6 m-top-big m-top-0-m-l-xl">
                <form-text-area :label="$t('leads.note')"
                                maxlength="191"
                                v-model="form.notice"
                                :disabled="form.submitted"
                                :placeholder="$t('leads.note_placeholder')" />
              </div>
            </div>
            <!-- /Step 4 -->
            <!-- Step 5 -->
            <div v-if="step === 5"
                 class="completion">
              <div class="completion__title">
                {{ $t('leads.success') }}
              </div>
            </div>
            <!-- /Step 5 -->
          </div>

          <!-- Actions -->
          <div class="form-stepper__actions">
            <btn class="form-stepper__actions-prev"
                 variant="bg-highlighted"
                 push-left
                 @click.native.prevent="prev"
                 v-if="!isFirstStep && !isLastStep">
              <icon class="form-stepper__actions-prev-icon"
                    name="long-arrow-alt-left"></icon>
              {{ $t('leads.backTo') }} {{ $t(steps[step - 1].name) }}
            </btn>


            <btn class="form-stepper__actions-next"
                 variant="highlighted"
                 push-right
                 :disabled="form.submitted || loading"
                 @click.native.prevent="next"
                 v-if="!isLastStep && steps[step + 1].name === 'leads.completion'">
              <template v-if="loading">
                <icon name="spinner"></icon>
              </template>
              <template v-else>
                {{ $t('leads.finishLead') }}
                <icon class="form-stepper__actions-next-icon"
                      name="long-arrow-alt-right"></icon>
              </template>
            </btn>


            <btn class="form-stepper__actions-next"
                 variant="highlighted"
                 push-right
                 @click.native.prevent="next"
                 v-else-if="!isLastStep">
              {{ $t('leads.continue') }} {{ $t(steps[step + 1].name) }}
              <icon class="form-stepper__actions-next-icon"
                    name="long-arrow-alt-right"></icon>
            </btn>
          </div>
        </div>

      </card>

      <a href="#"
         class="leads__saved-route"
         @click.prevent="handleNew">
        <card class="leads__saved"
              isShadowed>
          <div class="leads__saved-wrapper">
            <div>
              <btn class="leads__saved-button"
                   icon="plus"
                   variant="highlighted"></btn>
              {{ $t('leads.newLead') }}
            </div>
          </div>
        </card>
      </a>

      <router-link :to="{ name: 'LocallySavedLeads' }"
                   class="leads__saved-route">
        <card class="leads__saved"
              isShadowed>
          <div class="leads__saved-wrapper">
            <div>
              <btn class="leads__saved-button"
                   icon="spreadsheet"
                   variant="highlighted"></btn>
              {{ $t('leads.savedLeads') }}
            </div>
            <div>
              <icon class="leads__saved-icon"
                    name="long-arrow-right"></icon>
            </div>
          </div>
        </card>
      </router-link>
    </div>
  </div>
</template>

<script>
import AttachmentUpload from '@/components/molecules/AttachmentUpload';
import Btn from '@/components/molecules/Btn';
import BusinessCardUpload from '@/components/molecules/BusinessCardUpload';
import Card from '@/components/atoms/Card';
import FormCheckboxes from '@/components/molecules/FormCheckboxes';
import FormInput from '@/components/molecules/FormInput';
import FormSelect from '@/components/molecules/FormSelect';
import FormLabel from '@/components/atoms/FormLabel';
import FormRadios from '@/components/molecules/FormRadios';
import FormTextArea from '@/components/molecules/FormTextArea';
import HeadSection from '@/components/molecules/HeadSection';
import Icon from '@/components/atoms/Icon';
import PageTitle from '@/components/molecules/PageTitle';
import StepProgress from '@/components/molecules/StepProgress';
import ErrorBanner from '@/components/molecules/ErrorBanner';
import {
  salutationOptions, statusOptions, sectorOptions, companySizeOptions,
  countryOptions, priorityOptions, followUpActionsOptions, additionalOptions, titleOptions,
} from '@/helpers/options';
import { mapState, mapActions, mapGetters } from 'vuex';
import { diff } from 'deep-object-diff';
import scrollToTop from '@/helpers/scrollToTop';
import { v4 as uuidv4 } from 'uuid';
import { formPreFills } from '@/store/modules/leads';

export default {
  name: 'Leads',
  components: {
    AttachmentUpload,
    Btn,
    BusinessCardUpload,
    Card,
    FormCheckboxes,
    FormInput,
    FormSelect,
    FormLabel,
    FormRadios,
    FormTextArea,
    HeadSection,
    Icon,
    PageTitle,
    StepProgress,
    ErrorBanner,
  },
  data() {
    return {
      checkMandatoryFields: false,
      step: 0,
      form: null,
      errors: [],
      errorDetails: [],
      detailedError: false,
      steps: [
        {
          name: 'leads.leadDetails',
          fields: [],
        },
        {
          name: 'leads.company',
          fields: ['country'],
        },
        {
          name: 'leads.contactPersona',
          fields: ['email'],
        },
        {
          name: 'leads.machineInterests',
          fields: [],
        },
        {
          name: 'leads.additional',
          fields: [],
        },
        {
          name: 'leads.completion',
          fields: [],
        },
      ],
    };
  },
  methods: {
    ...mapActions('leads', ['saveOrCreate', 'newLead', 'submitLeadToMautic']),
    checkCategorySelect(id) {
      const products = this.getItemsByCategoryId(id);
      const productIds = products.map((product) => product.id);
      const checker = (arr, target) => target.every(v => arr.includes(v));

      return products.length > 0 && productIds.length > 0 && checker(this.form.machines, productIds) && products.length === productIds.length;
    },
    handleCategorySelect(e) {
      const checked = e.event.target.checked;
      const products = this.getItemsByCategoryId(e.id);

      const productIds = products.map((product) => product.id);
      if (checked) {
        // add
        this.form.machines = [...this.form.machines, ...productIds];
      } else {
        // remove
        this.form.machines = this.form.machines.filter(ar => !productIds.find(rm => (rm === ar)));
      }
    },
    async prev() {
      if (this.step - 1 >= 0) {
        this.step--;
      }
      await scrollToTop();
    },
    async next() {
      await this.handleSave();
      if (this.steps[this.step + 1].name === 'leads.completion') {
        this.checkMandatoryFields = true;
        let valid = !this.stepState.some(a => a === false || a === 'wip' || a === 'error');
        if (valid) {
          const submit = await this.submitLeadToMautic(this.form);
          this.errorDetails = submit.errorDetails;
          this.errors = submit.errors;
          if (this.errors.length <= 0) {
            this.step = this.steps.length - 1;
            this.form.submitted = true;
          }
        }
      } else if (this.step + 1 < this.steps.length) {
        this.step++;
      }
      await scrollToTop();
    },
    updateFormData() {
      let data = this.current;

      if (!data?.contact_creator?.trim()) {
        data.contact_creator = `${this.getCurrentUser.first_name} ${this.getCurrentUser.last_name}`;
      }
      if (!data?.fair?.trim()) {
        data.fair = formPreFills.fair;
      }

      this.form = JSON.parse(JSON.stringify(data));
    },
    async handleSave() {
      if (this.differsLength > 0) {
        await this.saveOrCreate(this.form);
      }
    },
    async handleNew() {
      await this.handleSave();
      this.step = 0;
      await scrollToTop();
      await this.newLead();
      this.errors = [];
      this.checkMandatoryFields = false;
    },
  },
  computed: {
    ...mapGetters('products', ['getItemsByCategoryId']),
    ...mapGetters('productCategories', ['getItems']),
    ...mapGetters(['isLoading']),
    ...mapGetters('auth', ['getCurrentUser']),
    ...mapState('leads', ['current']),
    uid() {
      return uuidv4();
    },
    stepState() {
      let step1 = false,
          step2 = false,
          step3 = false,
          step4 = false,
          step5 = false;

      // step 1 wip
      step1 = (
          this.form.contact_creator !== '' ||
          this.form.fair !== '' ||
          this.form.lead_id !== '' ||
          this.form.visiting_day !== '') ? 'wip' : step1;
      // step 1 done
      step1 = (
          this.form.contact_creator !== '' &&
          this.form.fair !== '' &&
          this.form.lead_id !== '' &&
          this.form.visiting_day !== '') ? 'done' : step1;

      // step 2 wip
      step2 = (
          this.form.company !== '' ||
          this.form.sector !== '' ||
          this.form.company_size !== '' ||
          this.form.country !== ''
      ) ? 'wip' : step2;
      // step 2 done
      step2 = (
          this.form.company !== '' &&
          this.form.sector !== '' &&
          this.form.company_size !== '' &&
          this.form.country !== ''
      ) ? 'done' : step2;

      // step 3 wip
      step3 = (
          this.form.title !== '' ||
          this.form.salutation !== '' ||
          this.form.first_name !== '' ||
          this.form.last_name !== '' ||
          this.form.status !== '' ||
          this.form.tel !== '' ||
          this.form.email !== '' ||
          this.form.business_card_front !== '' ||
          this.form.business_card_back !== ''
      ) ? 'wip' : step3;
      // step 3 done
      step3 = (
          this.form.salutation !== '' &&
          this.form.first_name !== '' &&
          this.form.last_name !== '' &&
          this.form.status !== '' &&
          this.form.tel !== '' &&
          this.form.email !== ''
      ) ? 'done' : step3;

      // step 4 wip
      step4 = (
          this.form.machines.length > 0
      ) ? 'wip' : step4;
      // step 4 done
      step4 = (
          this.form.machines.length > 0
      ) ? 'done' : step4;

      // step 5 wip
      step5 = (
          this.form.priority !== '' ||
          this.form.additional.length > 0 ||
          this.form.follow_up_actions.length > 0 ||
          this.form.attachment1.length > 0 ||
          this.form.attachment2.length > 0 ||
          this.form.notice !== ''
      ) ? 'wip' : step5;
      // step 5 done
      step5 = (
          this.form.priority !== ''
      ) ? 'done' : step5;

      if (this.checkMandatoryFields) {
        step1 = step1 === 'wip' || step1 === false ? 'error' : step1;
        step2 = step2 === 'wip' || step2 === false ? 'error' : step2;
        step3 = step3 === 'wip' || step3 === false ? 'error' : step3;
        step4 = step4 === 'wip' || step4 === false ? 'error' : step4;
        step5 = step5 === 'wip' || step5 === false ? 'error' : step5;
      }

      return [step1, step2, step3, step4, step5];
    },
    loading() {
      return this.isLoading('mautic');
    },
    // diff
    differs() {
      return diff(this.current, this.form);
    },
    differsLength() {
      return this.differs ? Object.keys(this.differs).length : 0;
    },
    // step
    isFirstStep() {
      return this.step - 1 < 0;
    },
    isLastStep() {
      return this.step + 1 >= this.steps.length;
    },
    // select data
    titleOptions() {
      return titleOptions;
    },
    salutationOptions() {
      return salutationOptions;
    },
    statusOptions() {
      return statusOptions;
    },
    sectorOptions() {
      return sectorOptions;
    },
    companySizeOptions() {
      return companySizeOptions;
    },
    countryOptions() {
      return countryOptions;
    },
    priorityOptions() {
      return priorityOptions;
    },
    followUpActionsOptions() {
      return followUpActionsOptions;
    },
    additionalOptions() {
      return additionalOptions;
    },
    // progression
    progress() {
      return {
        width: `${(this.step / (this.steps.length - 1) * 100)}%`,
      };
    },
  },
  watch: {
    current() {
      this.updateFormData();
    },
    step() {
      this.handleSave();
    },
  },
  mounted() {
    this.updateFormData();
  },
};
</script>
