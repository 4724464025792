<template>
  <div class="dashboard">
    <head-section img="finder-big">
      <page-title centered
                  :subline="$t('myPolyclip.welcome')"
                  headline="My Poly-Clip"
                  class="m-bottom-very-big" />

      <div class="loading-overlay__text"
           v-if="isLoading('myPolyFetchData')">
        {{ $t('myPolyclip.loadingInfo') }}
      </div>

      <search-widget class="m-bottom-huge" />
    </head-section>

    <div class="container">
      <highlighted-categories to="MyCategory"
                              :items="getHighlightedCategories" />

      <teasers-grid :items="getCategories"
                    to="MyCategory"
                    :subtitles="false"
                    param="categoryId" />
    </div>
  </div>
</template>
<script>
import HeadSection from '@/components/molecules/HeadSection';
import PageTitle from '@/components/molecules/PageTitle';
import SearchWidget from '@/components/organisms/SearchWidget';
import HighlightedCategories from '@/components/molecules/HighlightedCategories';
import TeasersGrid from '@/components/TeasersGrid';
import { mapGetters } from 'vuex';

export default {
  name: 'MyDashboard',
  components: {
    HighlightedCategories,
    SearchWidget,
    HeadSection,
    PageTitle,
    TeasersGrid,
  },
  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters('mypoly', ['getHighlightedCategories', 'getCategories']),
  },
};
</script>
