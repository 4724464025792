<template>
  <div class="highlighted-categories">
    <div class="highlighted-categories__item"
         v-for="item in items"
         :key="item.id">
      <teaser :to="{ name: to, params: {categoryId: item.id} }"
              isHighlighted
              :title="item.title"
              :img="item.image"></teaser>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Teaser from '@/components/Teaser';

export default {
  name: 'HighlightedProductCategories',
  components: {
    Teaser,
  },
  props: {
    items: Array,
    to: {
      default: 'ProductCategory',
      type: String,
    },
  },
};
</script>
