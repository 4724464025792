<template>
  <img @click="handleClick"
       class="logo"
       :class="classes"
       src="@/assets/logo.svg"
       alt="poly-clip system">
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'Logo',
  props: {
    mobile: Boolean,
    tiny: Boolean,
  },
  methods: {
    async handleClick() {
      await this.$router.push('/');
      if (this.online) {
        window.location.reload(true);
      }
    },
  },
  computed: {
    ...mapState(['online']),
    classes() {
      return {
        ['logo--mobile']: this.mobile,
        [`logo--tiny`]: this.tiny,
      };
    },
  },
};
</script>
