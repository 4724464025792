<template>
  <div class="product-finder">
    <!--        Filter options mobile-->
    <transition name="dropdown-right">
      <modal
          v-if="addModal"
          title="Filter"
          full-screen
          mobile
          box-background-bg
          head-background-white
          head-shadow
          full-height
          v-model="addModal">
        <template #body>
          <filters />
        </template>
        <template #actions>
          <btn variant="highlighted"
               full-width
               @click.native="addModal = false"
          >
            <span>{{ getProducts.length }}</span>
            {{ getProducts.length === 1 ? $t('productFinder.product') : $t('productFinder.products') }}
          </btn>
        </template>
      </modal>
    </transition>
    <!-- Title area with industry tabs -->
    <head-section img="finder">
      <page-title badge
                  centered
                  :headline="$t('productFinder.productFinder')"
                  class="product-finder__headline" />
      <product-industries v-if="filters?.top_filters"
                          :items="filters.top_filters?.items"
                          v-model="industry"
                          v-on:update:model-value="industry = $event"
                          product-finder />
    </head-section>
    <!-- Results -->
    <div class="container">
      <div class="product-finder__results">
        <div class="product-finder__filters">
          <filters />
        </div>
        <teasers-grid :items="getProducts"
                      to="Product"
                      param="productId"
                      grid-header
                      showIcon
                      @openModal="openModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Accordion from '@/components/molecules/Accordion';
import FormRadios from '@/components/molecules/FormRadios';
import HeadSection from '@/components/molecules/HeadSection';
import PageTitle from '@/components/molecules/PageTitle';
import ProductIndustries from '@/components/molecules/ProductIndustries';
import TeasersGrid from '@/components/TeasersGrid';
import Filters from '@/components/organisms/Filters';
import Modal from '@/components/organisms/Modal';
import Btn from '@/components/molecules/Btn';

export default {
  name: 'ProductFinder',
  components: {
    Modal,
    Accordion,
    FormRadios,
    HeadSection,
    PageTitle,
    ProductIndustries,
    TeasersGrid,
    Filters,
    Btn,
  },
  data() {
    return {
      addModal: false,
    };
  },
  computed: {
    ...mapState('products', ['deFilters', 'enFilters', 'activeIndustry', 'activeSubindustry', 'activeApplication', 'activeFilters']),
    ...mapGetters('products', ['getProducts']),
    filters() {
      return this[`${this.$i18n.locale}Filters`];
    },
    locale() {
      return this.$i18n.locale;
    },
    industry: {
      get() {
        return this.activeIndustry;
      },

      set(value) {
        this.setIndustry(value);
      },

    },
    subindustry: {
      get() {
        return this.activeSubindustry;
      },

      set(itemId) {
        // Some industries have only 1 subindustry so its items are rendered
        // directly to avoid redundant wrappers (for layout reasons).
        const itemsPool = this.activeIndustry.items.length === 1
            ? this.activeIndustry.items[0].items
            : this.activeIndustry.items;
        const item = itemId ? itemsPool.find(({ id }) => id === itemId) : null;
        this.setSubindustry(item);
      },
    },
    application: {
      get() {
        return this.activeApplication;
      },

      set(itemId) {
        const item = itemId ? this.activeSubindustry.items.find(({ id }) => id === itemId) : null;
        this.setApplication(item);
      },
    },
    sideFilters: {
      get() {
        return this.activeFilters;
      },

      set(value) {
        this.setFilters(value);
      },
    },
  },
  methods: {
    ...mapActions('products', ['setIndustry', 'setSubindustry', 'setApplication', 'setFilters', 'resetAll']),
    openModal() {
      this.addModal = true;
    },
  },
};
</script>
