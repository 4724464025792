<template>
  <div class="underline"
       :class="classes">
    <div v-if="underlinePresent"
         class="underline__element"
         :class="underlineElementClasses"
         :style="moveUnderline"></div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'Underline',
  props: {
    // availableSides are 'bottom', 'top', 'right';
    side: String,
    underlinePresent: Boolean,
    column: Boolean,
    hide: Boolean,
  },
  data() {
    return {
      itemLeft: '0',
      itemRight: '0',
      itemTop: '0',
      itemBottom: '0',
      itemWidth: '0',
      itemHeight: '0',
      itemCenter: '0',
    };
  },
  mounted() {
    this.$nextTick(function () {
      this.selectTab();
    });
  },
  updated() {
    this.selectTab();
  },
  created() {
    // makes underline stay in place on screen-resize
    window.addEventListener('resize', this.selectTab);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.selectTab);
  },
  methods: {
    selectTab() {
      for (let i = 0; i < this.$slots.default.length; i++) {
        if (!this.hide && this.$slots.default[i] && this.$slots.default[i].tag && this.$slots.default[i].elm.getAttribute('data-active') === 'true') {
          this.itemWidth = this.$slots.default[i].elm.clientWidth;
          this.itemHeight = this.$slots.default[i].elm.clientHeight;
          this.itemLeft = this.$slots.default[i].elm.offsetLeft;
          this.itemRight = this.$slots.default[i].elm.offsetLeft + this.itemWidth;
          this.itemTop = this.$slots.default[i].elm.offsetTop;
          this.itemBottom = this.$slots.default[i].elm.offsetTop + this.itemHeight;
          this.itemCenter = this.itemLeft + (this.itemWidth / 2);
        }
      }
    },
  },
  computed: {
    moveUnderline() {
      if (this.side === 'bottom') {
        return {
          left: `${this.itemLeft}px`,
          bottom: `0`,
          width: `${this.itemWidth}px`,
        };
      } else if (this.side === 'top') {
        return {
          left: `${this.itemLeft}px`,
          top: `0`,
          width: `${this.itemWidth}px`,
        };
      } else if (this.side === 'right') {
        if (window.matchMedia('(max-width: 1100px)').matches) {
          return {
            left: `${this.itemCenter}px`,
            transform: 'translateX(-50%)',
            top: `0`,
            width: `48px`,
          };
        } else {
          return {
            right: `0`,
            top: `${this.itemTop}px`,
            height: `${this.itemHeight}px`,
            width: `5px`,
          };
        }
      }
    },
    classes() {
      return {
        ['underline--column']: this.column,
      };
    },
    underlineElementClasses() {
      return {
        ['underline__element--hide']: this.hide,
      };
    },
  },
};
</script>
