<template>
  <header class="app-header">
    <div class="container">
      <div class="app-header__mobile">
        <div class="app-header__mobile-top-bar">
          <logo tiny />
          <div v-if="isSalesAppModule"
               class="app-header__mobile-search">
            <form class="app-header__search-form"
                  @submit.prevent="handleSearch">
              <input class="app-header__search-form-input"
                     v-model="searchQuery"
                     type="search"
                     name="search"
                     placeholder="Geben Sie hier Ihren Begriff ein">
              <btn class="app-header__search-form-btn"
                   icon="search"
                   variant="highlighted"></btn>
            </form>
          </div>
          <div>
            <btn @click.native="toggleActive"
                 :icon="iconState"
                 variant="app-header" />
          </div>
        </div>
        <transition name="dropdown-right">
          <div v-if="isActive"
               class="app-header__mobile-user-dropdown">
            <div class="app-header__mobile-user-dropdown-container">
              <card v-click-outside="closeDropdown"
                    padding-x-small
                    is-shadowed
                    is-bordered
                    angle>
                <version class="version--userdropdown" />
                <div class="app-header__mobile-user-dropdown-content"
                     :class="classes">
                  <div class="mobile-user-dropdown__content-user-info">
                    <user-info mobile />
                  </div>
                  <div v-if="isSalesAppModule"
                       class="mobile-user-dropdown__content-sync-info">
                    <sync-tool mobile />
                  </div>
                  <div v-if="isSalesAppModule"
                       class="mobile-user-dropdown__content-module-info">
                    <module-switch />
                  </div>
                  <div v-if="isMyPolyModule || isOthersModule"
                       class="mobile-user-dropdown__content-sync-info">
                    <module-switch vertical />
                  </div>
                  <div class="mobile-user-dropdown__language-switch">
                    <language-switch class="language-switch--app-header" />
                  </div>
                </div>
              </card>
            </div>
          </div>
        </transition>
        <div v-if="isSalesAppModule"
             class="app-header__inner">
          <div class="app-header__col"></div>
          <div class="app-header__search app-header__col">
            <form class="app-header__search-form"
                  @submit.prevent="handleSearch">
              <input class="app-header__search-form-input"
                     v-model="searchQuery"
                     type="search"
                     name="search"
                     :placeholder="$t('header.expression')">
              <btn class="app-header__search-form-btn"
                   icon="search"
                   variant="highlighted"></btn>
            </form>
          </div>
          <div v-if="isSalesAppModule"
               class="app-header__sync app-header__col">
            <sync-tool />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
// components
import Btn from '@/components/molecules/Btn';
import Icon from '@/components/atoms/Icon';
import Logo from '@/components/atoms/Logo';
import SyncTool from '@/components/molecules/SyncTool';
import UserInfo from '@/components/molecules/UserInfo';
import LanguageSwitch from '@/components/molecules/LanguageSwitch';
import Card from '@/components/atoms/Card';
import { MODULE_LEAD_AND_SALES, MODULE_MY_POLY, MODULE_OTHERS } from '@/helpers/modules';
import ModuleSwitch from '@/components/molecules/ModuleSwitch';
import Version from '@/components/atoms/Version';

export default {
  name: 'AppHeader',
  components: {
    LanguageSwitch,
    ModuleSwitch,
    UserInfo,
    Btn,
    SyncTool,
    Icon,
    Logo,
    Card,
    Version,
  },
  data() {
    return {
      searchQuery: '',
      isActive: false,
      justToggled: false,
    };
  },
  computed: {
    isSalesAppModule() {
      return this.$route.meta.module === MODULE_LEAD_AND_SALES;
    },
    isMyPolyModule() {
      return this.$route.meta.module === MODULE_MY_POLY;
    },
    isOthersModule() {
      return this.$route.meta.module === MODULE_OTHERS;
    },
    iconState() {
      return this.isActive ? 'times' : 'user';
    },
    classes() {
      return {
        [`app-header__mobile-user-dropdown__content--open`]: this.isActive,
      };
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleSearch() {
      if (this.$route.name !== 'Search' || this.$route.params?.query !== this.searchQuery) {
        this.$router.push({
          name: 'Search', params: {
            query: this.searchQuery,
          },
        });
      }
    },
    toggleActive() {
      this.isActive = !this.isActive;
      // Keeping second state because dropdown gets closed by closeDropdown()
      // when it just got opened on button click
      this.justToggled = true;
    },
    closeDropdown() {
      if (!this.justToggled) {
        this.isActive = false;
      }
      this.justToggled = false;
    },
    handleScroll(event) {
      this.isActive = false;
    },
  },
};
</script>
