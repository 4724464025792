<template>
  <footer class="app-footer">
    <div class="container">
      <div class="app-footer__inner">
        <div>
          © {{ new Date().getFullYear() }} Poly-clip System GmbH & Co. KG
        </div>
        <div class="app-footer__additional-info">
          <a class="app-footer__link"
             href="https://www.polyclip.com/de/impressum"
             target="_blank">{{ $t('login.imprint') }}</a> &middot;
          <a class="app-footer__link"
             :href="$t('login.data_privacy_link')"
             target="_blank">{{ $t('login.data_privacy') }}</a> &middot;
          <a class="app-footer__link"
             :href="$t('login.terms_of_use_link')"
             target="_blank">{{ $t('login.terms_of_use') }}</a>
        </div>
        <icon
            name="to-top"
            class="app-footer__to-top"
            @click.native.prevent="toTop"
        />
      </div>
    </div>
  </footer>
</template>

<script>
import Icon from '../atoms/Icon';
import scrollToTop from '@/helpers/scrollToTop';

export default {
  name: 'AppFooter',
  components: { Icon },
  methods: {
    toTop() {
      scrollToTop();
    },
  },
};
</script>
