<template>
  <div class="form-input"
       :class="classes">
    <form-label :reference="`form-input${uid}`"
                v-if="label">
      {{ label }}
      <span v-if="mandatory">*</span>
    </form-label>
    <input class="form-input__input"
           :class="{ 'm-top-tiny': label, 'form-input__input--error': error }"
           @input="handle"
           :id="`form-input${uid}`"
           :type="type"
           :list="list"
           :value="modelValue"
           :placeholder="placeholder"
           :disabled="disabled"
           :readonly="readOnly">
    <p v-if="hint"
       class="form-input__hint"
       v-html="hint"></p>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import FormLabel from '@/components/atoms/FormLabel';

export default {
  name: 'FormInput',
  emits: ['update:modelValue'],
  props: {
    list: {},
    modelValue: {},
    type: {},
    label: {},
    placeholder: {},
    mandatory: Boolean,
    readOnly: Boolean,
    error: Boolean,
    disabled: Boolean,
    isSpaceless: Boolean,
    hint: {},
  },
  components: {
    FormLabel,
  },
  methods: {
    handle(e) {
      this.$emit('update:modelValue', e.target.value);
    },
  },
  computed: {
    uid() {
      return uuidv4();
    },
    classes() {
      return {
        ['form-input--spaceless']: this.isSpaceless,
      };
    },
  },
};
</script>
