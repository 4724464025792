<template>
  <div class="loading-overlay">
    <div class="loading-overlay__modal">
      <Logo />
      <Logo mobile />
      <div class="loading-overlay__icon"></div>
      <div class="loading-overlay__text"
           v-if="isLoading('fetchProductCategories')">
        {{ $t('loadingOverlay.product_category') }}
      </div>
      <div class="loading-overlay__text"
           v-if="isLoading('fetchProducts')">
        {{ $t('loadingOverlay.products') }}
      </div>
      <div class="loading-overlay__text"
           v-if="isLoading('fetchTessaDownloadInformation')">
        {{ $t('loadingOverlay.download_information') }}
      </div>
      <div class="loading-overlay__text"
           v-if="isLoading('fetchTessaCategoriesAndProducts')">
        {{ $t('loadingOverlay.data') }}
      </div>
    </div>
  </div>
</template>
<script>
import Logo from '@/components/atoms/Logo';
import { mapGetters } from 'vuex';

export default {
  name: 'LoadingOverlay',
  components: {
    Logo,
  },
  computed: {
    ...mapGetters(['isLoading']),
  },
};
</script>
