<template>
  <div class="tooltip"
       @mouseenter="handleEnter"
       @mouseleave="handleLeave">
    <transition name="tooltip">
      <div v-if="show"
           class="tooltip__popup"
           v-html="message">
      </div>
    </transition>
    <div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Tooltip',
  props: {
    message: String,
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    handleEnter() {
      this.show = true;
    },
    handleLeave() {
      this.show = false;
    },
  },
};

</script>
