export const preloads = [
  'clip-tip-back-daten.svg',
  'clip-tip-back-schieber.svg',
  'clip-tip-front-daten.svg',
  'clip-tip-front-schieber.svg',
  'no-img.svg',
];

// read a file from filelist as string an returns it
export async function readFileAsText(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
}

// returns an array of all the headers within a csv string
export function getCsvHeaders(csvString, delimiter = '|') {
  return csvString.slice(0, csvString.replaceAll('\r', '').indexOf('\n')).split(delimiter);
}

// returns csv text as array
export function csvToArray(csvString, delimiter = '|') {
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = getCsvHeaders(csvString, delimiter);

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = csvString.slice(csvString.replaceAll('\r', '').indexOf('\n')).split('\n');

  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  // return the array
  const data = rows.map((row) => {
    const values = row.split(delimiter);
    return headers.reduce((object, header, index) => {
      if (values[index] !== undefined) {
        object[header] = String(values[index]).replaceAll('"', '');
        return object;
      } else {
        return undefined;
      }
    }, {});
  });
  // remove undefined entries
  return data.filter(n => n);
}

// returns array as csv text
export function arrayToCsv(headers, items, delimiter = '|') {
  let csv = '';

  // Loop the array of objects
  for (let row = 0; row < items.length + 1; row++) {
    let keysAmount = headers.length;
    let keysCounter = 0;

    // If this is the first row, generate the headings
    if (row === 0) {

      // Loop each property of the object
      for (let key in headers) {
        // This is to not add a comma at the last cell
        // The '\r\n' adds a new line
        csv += headers[key] + (keysCounter + 1 < keysAmount ? delimiter : '\r\n');
        keysCounter++;

      }
    } else {
      for (let key in headers) {
        const value = items[row - 1][headers[key]];
        csv += (value ? value : '') + (keysCounter + 1 < keysAmount ? delimiter : '\r\n');
        keysCounter++;
      }
    }
    keysCounter = 0;
  }
  return csv;
}
