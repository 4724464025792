import { v4 as uuidv4 } from 'uuid';
import env from '@/helpers/env';
import { getTimestampByDate } from '@/helpers/time';
import axios from 'axios';

export const tessaApi = axios.create({
  baseURL: `${env('api_tessa')}/`,
});
export const tessaAuthHeaders = (apiKey) => {
  return {
    headers: {
      'X-Auth-Token': apiKey,
    },
  };
};
export const tessaErrorHandler = (error, cb = () => {
}) => {
  console.log(error);
  cb();
};

export function transformFormIntoMautic(form) {
  return {
    // - lead details
    lead_fair: form.fair,
    lead_visiting_day: form.visiting_day,
    lead_lead_nr: form.lead_id,
    contact_creator: form.contact_creator,
    // - contact
    title: form.title,
    salutation: form.salutation,
    firstname: form.first_name,
    lastname: form.last_name,
    position: form.status,
    phone: form.tel,
    email: form.email,
    // ignored value: business_card_front
    // ignored value: business_card_back
    // - company
    company: form.company,
    contact_companyindustry: form.sector,
    contact_company_size: form.company_size,
    contact_country: form.country,
    postal_code: form.postal_code,
    // - machine interests
    lead_machines: form.machines.join(','),
    // - additional
    lead_priority: form.priority,
    lead_follow_up_action_off: form.follow_up_actions.includes('Angebot'),
    lead_follow_up_action_vis: form.follow_up_actions.includes('Besuch'),
    lead_follow_up_action_bro: form.follow_up_actions.includes('Prospekt'),
    lead_follow_up_action_cal: form.follow_up_actions.includes('Telefonat'),
    lead_follow_up_action_tes: form.follow_up_actions.includes('Test'),
    lead_follow_up_action_vid: form.follow_up_actions.includes('Video'),
    lead_follow_up_action_dem: form.follow_up_actions.includes('Vorführung'),
    lead_follow_up_action_whe: form.follow_up_actions.includes('Wartung & Service'),
    lead_no_thank_you_email: form.additional.includes('keine Danke E-Mail'),
    lead_no_automated_lead_di: form.additional.includes('keine Leadverteilung'),
    // ignored value: attachment1
    // ignored value: attachment2
    lead_note: form.notice,
  };
}

export function transformMyPolyData(response) {
  const data = response.data;

  // fake categories
  const transformed = data.map((file) => {
    return {
      id: Number(file?.id),
      version_id: Number(file?.version_id),
      name: file?.file_name,
      size: file?.file_size,
      type: file?.file_type,
      download: file?.download,
      preview: file?.preview,
      categories: file?.categories,
      sap: file?.asset_trio_sap,
    };
  });

  return transformed;
}

export function transformTessaDownloads(response) {
  const data = response.data;

  const transformed = data.map((download) => {
    let sku = undefined;
    let type = undefined;

    const categoryLeaflets = env('tessa_category_leaflets');
    const categoryPriceLists = env('tessa_category_price_lists');
    const categoryOrderForms = env('tessa_category_order_forms');
    const categoryVideos = env('tessa_category_videos');
    const categoryTraining = env('tessa_category_training');

    if (download.categories.includes(categoryLeaflets)) {
      type = categoryLeaflets;
    } else if (download.categories.includes(categoryPriceLists)) {
      type = categoryPriceLists;
    } else if (download.categories.includes(categoryOrderForms)) {
      type = categoryOrderForms;
    } else if (download.categories.includes(categoryVideos)) {
      type = categoryVideos;
    } else if (download.categories.includes(categoryTraining)) {
      type = categoryTraining;
    } else {
      type = 'CATEGORY_OTHERS';
    }

    if (typeof download?.linked_articles === 'object') {
      sku = download.linked_articles[0].sku;
    }

    return {
      id: Number(download?.id),
      src: download?.cdn.replace('http://', 'https://'),
      type: type,
      name: download?.name,
      updated: getTimestampByDate(download.update_date),
      sku: sku,
      attachmentOf: download.categories.split(',').map(i => Number(i)),
    };
  });

  return transformed;
}

export function transformTessaCategoriesAndProducts(response) {
  const data = response.data;
  const entry = env('api_tessa_category');
  let filtered = [];

  // get all nodes by the parent_id of the entry
  data.filter(category => category.parent_id === entry)
  .forEach((filteredCategory) => {
    // get all nodes by the parent_id of the current item
    filtered = [
      ...data.filter(category => category.parent_id === filteredCategory.id),
      ...filtered,
      filteredCategory,
    ];
  });

  const transformedData = filtered.map((item) => {
    let image = undefined;
    if (item.asset_id !== '0') {
      image = `${env('cdn_tessa')}/${env('cdn_tessa_image_channel')}/${item.asset_id}`;
    }

    return {
      id: Number(item?.id),
      tessaId: Number(item?.id),
      title: item?.name?.de,
      subtitle: item?.description?.de,
      parent_id: Number(item?.parent_id),
      highlighted: Boolean(Number(item?.ist_highlight)),
      sku: item?.akeneo_sku !== '' ? item?.akeneo_sku : undefined,
      image: image ? image : undefined,
      updated: new Date(item?.update_date).getTime(),
      sort: Number(item?.sort),
    };
  });

  return {
    products: transformedData.filter(data => data.sku !== undefined),
    categories: transformedData.filter(data => data.sku === undefined),
  };
}

export function transformCsvDataToEntityReferences(csvEntries) {
  return csvEntries.map((entry) => {
    return {
      entityReferenceId: uuidv4(),
      code: entry.code,
      attribute_name: entry.attribute_name,
      machine: entry.machine,
    };
  });
}

export function transformEntityReferencesToCsvData(csvEntries) {
  return csvEntries.map((entry) => {
    return {
      code: entry.code,
      attribute_name: entry.attribute_name,
      machine: entry.machine,
    };
  });
}

export function transformCsvDataToTranslations(csvEntries) {
  return csvEntries.map((entry) => {
    return {
      translationId: uuidv4(),
      'code': entry['code'],
      'entityCode': entry['entityCode'],
      'textblock-de_DE': entry['textblock-de_DE'],
      'textblock-en_GB': entry['textblock-en_GB'],
      'textblock-en_US': entry['textblock-en_US'],
      'textblock-es_ES': entry['textblock-es_ES'],
      'textblock-pt_BR': entry['textblock-pt_BR'],
      'textblock-ru_RU': entry['textblock-ru_RU'],
    };
  });
}

export function transformTranslationsToCsvData(csvEntries) {
  return csvEntries.map((entry) => {
    return {
      'code': entry['code'],
      'entityCode': entry['entityCode'],
      'textblock-de_DE': entry['textblock-de_DE'],
      'textblock-en_GB': entry['textblock-en_GB'],
      'textblock-en_US': entry['textblock-en_US'],
      'textblock-es_ES': entry['textblock-es_ES'],
      'textblock-pt_BR': entry['textblock-pt_BR'],
      'textblock-ru_RU': entry['textblock-ru_RU'],
    };
  });
}

export function transformObjectByArray(arr, obj) {
  const transformed = {};
  arr.forEach((key) => {
    transformed[key] = (obj && obj[key]) || '';
  });
  return transformed;
}

export function transformMyPolyLanguages(languages) {
  if (!languages) {
    return [];
  }

  return languages.map((language) => {
    return {
      id: Number(language?.id),
      name: language?.name?.de,
      name_de: language?.name?.de,
      name_en: language?.name?.en,
      shortcut: language?.shortcut,
    };
  });
}

export function transformMyPolyCategoryTree(nestedTree) {
  if (!nestedTree) {
    return [];
  }

  return nestedTree.map((tree) => {
    let image = undefined;
    if (tree?.asset_id !== '0') {
      image = `${env('cdn_tessa')}/${env('cdn_tessa_image_channel')}/${tree.asset_id}`;
    }
    return {
      id: Number(tree?.id),
      title: tree?.name?.de,
      highlighted: Boolean(Number(tree?.ist_highlight)),
      image: image,
      updated: new Date(tree?.update_date).getTime(),
      sort: tree?.sort,
      children: tree?.children ? transformMyPolyCategoryTree(tree?.children) : [],
    };
  });
}

export function transformMyPolyDerivate(items) {
  if (!items) {
    return [];
  }

  return items.map((item) => {
    return {
      id: Number(item?.id),
      title: item?.name?.de,
      desc: item?.description?.de,
    };
  });
}

export function transformToSelectArray(items) {
  return items.map((item) => {
    return {
      value: Number(item?.id),
      name: item?.name,
      name_de: item?.name_de,
      name_en: item?.name_en,
    };
  });
}
