<template>
  <div class="step-progress m-top-big m-bottom-very-big">
    {{ status }}
    <ul class="step-progress__items">
      <li v-for="(item, index) in forms"
          @click="$emit('input', index)"
          :key="`progress_${index}`"
          class="step-progress__item"
          :class="{ [`step-progress__item--${item.state}`]: item.state !== 'untouched' }">
        <div class="step-progress__icon"></div>
        <div class="step-progress__name">
          {{ item.name }}
        </div>
      </li>
    </ul>
    <div class="step-progress__bar">
      <div class="step-progress__fill"
           :style="progress"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StepProgress',
  props: {
    forms: {},
    value: {},
    status: {},
  },
  computed: {
    progress() {
      return {
        width: `${(this.value / (this.forms.length - 1) * 100)}%`,
      };
    },
  },
};
</script>
