<template>
  <div>
    <div class="container">
      <page-title underneath
                  headline="Gespeicherte Leads" />

      <div v-if="getLeadsFromCurrentUser.length <= 0">
        {{ $t('leads.noLeads') }}
      </div>

      <div v-else>
        <card class="m-bottom-medium"
              is-bordered>
          <form class="freetext-search"
                @submit.prevent="handleSearch">
            <form-input v-model="search"
                        @change.native.prevent="handleSearch"
                        class="freetext-search__form-item"
                        :label="$t('leads.searchTerm')"
                        type="search"
                        name="search"
                        :placeholder="$t('leads.giveSearch')" />
            <btn icon="search"
                 variant="highlighted"></btn>
          </form>
        </card>

        <card is-bordered
              v-if="filteredSearch.length > 0">
          <list>
            <list-item v-for="(lead, key) in filteredSearch"
                       class="list-item__mobile"
                       :key="lead.lead_id"
                       :subtitle="buildSubtitle(lead)">
              <template #preline>
                <div v-if="lead.submitted"
                     class="list-item__succeeded">{{ $t('leads.completed') }}
                </div>
              </template>
              <template #title>
                {{ buildTitle(lead) }}
                <btn class="list-item__trash"
                     icon="trash-alt"
                     variant="decent"
                     @click.prevent.native="openDeleteModal(key)">
                  {{ $t('leads.delete') }}
                </btn>
              </template>
              <template #actions>
                <btn
                    spaced
                    fullWidth
                    icon="edit"
                    variant="light"
                    @click.prevent.native="handleLoadLead(lead.lead_id)">
                  {{ $t('leads.editLead') }}
                </btn>
              </template>
            </list-item>
          </list>
        </card>
        <div v-else>
          {{ $t('leads.noResult') }}
        </div>

      </div>


      <!-- Delete modal -->
      <modal v-model="deleteModal"
             :title="`${currentLead.fair} ${$t('leads.smallDelete')}`">
        <template #body>
          <page-title variant="paragraph"
                      :headline="`${$t('leads.youWant')} <span>${currentLead.fair}</span> ${$t('leads.really')}`"
                      :back-btn="false">
          </page-title>
        </template>
        <template #actions>
          <btn @click.prevent.native="deleteModal = false"
               variant="muted"
               half-width>
            {{ $t('leads.cancel') }}
          </btn>
          <btn variant="highlighted"
               @click.native="handleDelete"
               half-width>
            {{ $t('leads.delete') }}
          </btn>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Card from '@/components/atoms/Card';
import List from '@/components/molecules/List';
import ListItem from '@/components/molecules/ListItem';
import PageTitle from '@/components/molecules/PageTitle';
import Btn from '@/components/molecules/Btn';
import Tooltip from '@/components/molecules/Tooltip';
import FormInput from '@/components/molecules/FormInput';

import Modal from '@/components/organisms/Modal';

export default {
  name: 'LocallySavedLeads',
  components: {
    Tooltip,
    Btn,
    Card,
    List,
    ListItem,
    Modal,
    PageTitle,
    FormInput,
  },
  data() {
    return {
      search: '',
      filteredSearch: [],
      deleteModal: false,
      currentLead: {}, // for delete modal
      tooltipActive: false,
    };
  },
  methods: {
    ...mapActions('leads', ['loadLead', 'deleteLead']),
    handleSearch() {
      if (this.search === '') {
        this.filteredSearch = this.getLeadsFromCurrentUser;
      } else {
        this.filteredSearch = this.getLeadsFromCurrentUser.filter(l => {
          return l.fair.includes(this.search)
              || l.visiting_day.includes(this.search)
              || l.lead_id.includes(this.search)
              || l.first_name.includes(this.search)
              || l.last_name.includes(this.search)
              || l.company.includes(this.search);
        });
      }
    },
    openDeleteModal(key) {
      this.currentLead = this.getLeadsFromCurrentUser[key];
      this.deleteModal = true;
    },
    handleLoadLead(leadId) {
      this.loadLead(leadId);
      this.$router.push({ name: 'Leads' });
    },
    handleDelete() {
      this.deleteLead(this.currentLead.lead_id);
      this.deleteModal = false;
      this.currentLead = {};
      this.filteredSearch = this.getLeadsFromCurrentUser;
    },
    buildSubtitle(lead) {
      const fair = lead.fair && lead.fair !== '' ? lead.fair : 'Unbenannt';
      return this.groupNames([fair, lead.visiting_day, lead.lead_id], ' · ');
    },
    buildTitle(lead) {
      return this.groupNames([`${lead.first_name} ${lead.last_name}`, lead.company], ', ');
    },
    groupNames(fields, separator) {
      return fields.filter(e => e).join(separator);
    },
  },
  computed: {
    ...mapGetters('leads', ['getLeadsFromCurrentUser']),
    ...mapGetters(['isLoading']),
    ...mapState(['online']),
    loading() {
      return this.isLoading('mautic');
    },
    classes() {
      return {
        ['btn--disabled']: !this.online,
      };
    },
  },
  mounted() {
    this.filteredSearch = this.getLeadsFromCurrentUser;
  },
};
</script>
