export default {
  namespaced: true,
  state: {
    wishlistItems: [],
  },
  mutations: {
    ADD_TO_WISHLIST(state, fileId) {
      state.wishlistItems.push(fileId);
    },
    REMOVE_FROM_WISHLIST(state, fileId) {
      const index = state.wishlistItems.indexOf(fileId);
      state.wishlistItems.splice(index, 1);
    },
  },
  actions: {
    async toggleWishlistItem({ state, commit }, fileId) {
      if (state.wishlistItems.includes(fileId)) {
        commit('REMOVE_FROM_WISHLIST', fileId);
      } else {
        commit('ADD_TO_WISHLIST', fileId);
      }
    },
  },
  getters: {},
};
