<template>
  <router-link :to="to"
               class="dynamic-list-item">
    <div class="dynamic-list__product">
      <div class="dynamic-list-item__product-img">
        <lazy-img :src="item.image"
                  :alt="item.title" />
      </div>
      <a class="dynamic-list-item__product-title">
        {{ item.title }}
      </a>
    </div>
    <div class="dynamic-list-item__info">
      <slot name="info"></slot>
    </div>
    <slot name="icon"></slot>
  </router-link>
</template>
<script>
import LazyImg from '@/components/molecules/LazyImg';

export default {
  name: 'DynamicListItem',
  components: {
    LazyImg,
  },
  props: {
    item: Object,
    to: {},
  },
  updated() {
    let dividedSpace = 100 / document.querySelector('.dynamic-list-item__info').childElementCount;

    let nodes = document.querySelector('.dynamic-list-item__info').childNodes;
    for (let i = 0; i < nodes.length; i++) {
      if (nodes && i && nodes[i] && nodes[i].style && nodes[i].style.maxWidth) {
        nodes[i].style.maxWidth = `${dividedSpace}%`;
      }
    }
  },
};
</script>
