<template>
  <div class="clip-tip">
    <div class="clip-tip__content">
      <div class="clip-tip__img-container">
        <lazy-img v-if="frontSideActive"
                  class="clip-tip__img"
                  src="/assets/clip-tip-front-daten.svg" />
        <lazy-img v-else
                  class="clip-tip__img"
                  src="/assets/clip-tip-back-daten.svg" />
        <lazy-img v-if="frontSideActive"
                  class="clip-tip__img clip-tip__img--slider"
                  :style="`left: ${sliderPositionInPercent}`"
                  src="/assets/clip-tip-front-schieber.svg" />
        <lazy-img v-else
                  class="clip-tip__img clip-tip__img--slider"
                  :style="`left: ${sliderPositionInPercent}`"
                  src="/assets/clip-tip-back-schieber.svg" />
      </div>
      <div class="clip-tip__controls">
        <div class="clip-tip__slider">
          <input v-model="sliderValue"
                 type="range"
                 id="clip-tip__slider"
                 name="clip-tip__slider"
                 min="0"
                 max="100"
                 step="10">
          <div class="clip-tip__slider-thumb"
               :style="`left: ${sliderValue}%`">
          </div>
        </div>
        <div class="clip-tip__buttons">
          <div @click="turnToFront"
               class="clip-tip__button"
               :class="frontButtonClasses">
            Front
          </div>
          <div @click="turnToBack"
               class="clip-tip__button"
               :class="backButtonClasses">
            Back
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LazyImg from '@/components/molecules/LazyImg';

export default {
  name: 'ClipTip',
  components: { LazyImg },
  data() {
    return {
      frontSideActive: true,
      sliderValue: 0,
    };
  },
  methods: {
    turnToFront() {
      this.frontSideActive = true;
    },
    turnToBack() {
      this.frontSideActive = false;
    },
  },

  computed: {
    sliderPositionInPercent() {
      if (this.frontSideActive) {
        return `${((this.sliderValue / 10) - 3) * 8.4}%`;
      } else {
        return `${((this.sliderValue / 10) - 2) * 8.4}%`;
      }
    },
    frontButtonClasses() {
      return {
        ['clip-tip__button--active']: this.frontSideActive,
      };
    },
    backButtonClasses() {
      return {
        ['clip-tip__button--active']: !this.frontSideActive,
      };
    },
  },
};
</script>
