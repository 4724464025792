<template>
  <router-link :to="to"
               class="teaser"
               :class="classes">
    <div class="teaser__slot">
      <slot></slot>
    </div>

    <lazy-img :src="img"
              :alt="title"
              :variant="isHighlighted ? 'highlighted-teaser' : null" />

    <div class="teaser__info">
      <div v-if="subtitle"
           class="teaser__subtitle m-bottom-tiny">{{ subtitle }}
      </div>
      <div class="teaser__title">{{ title }}</div>
    </div>
  </router-link>
</template>

<script>
import Icon from '@/components/atoms/Icon';
import LazyImg from '@/components/molecules/LazyImg';

export default {
  name: 'Teaser',
  components: {
    Icon,
    LazyImg,
  },
  props: {
    title: {},
    subtitle: {},
    img: {},
    to: {},
    isHighlighted: Boolean,
    isDetailed: Boolean,
  },

  computed: {
    classes() {
      return {
        [`teaser--is-highlighted`]: this.isHighlighted,
        [`teaser--is-detailed`]: this.isDetailed,
      };
    },
  },
};
</script>
