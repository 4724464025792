<template>
  <div class="product-finder-widget">
    <h3>{{ $t('productFinder.productFinder') }}</h3>
    <!-- Industries -->
    <product-industries v-if="filters?.top_filters"
                        :items="filters.top_filters?.items"
                        v-model="industry" />
    <!-- First group of the side filters -->
    <div v-if="filters?.side_filters">
      <form-radios :label="filters.side_filters?.[0].name"
                   :name="filters.side_filters?.[0].vid"
                   :options="filters.side_filters?.[0].items"
                   reset-option
                   centered
                   :value="sideFilters[filters.side_filters?.[0].vid]"
                   v-on:update:value="sideFilters[filters.side_filters?.[0].vid] = $event" />
    </div>
    <div class="product-finder-widget__buttons">
      <btn variant="highlighted"
           :disabled="getProducts.length === 0"
           :to="{ name: 'ProductFinder' }"
      >

        <icon class="m-right-very-small"
              name="sliders-h" />
        {{ $t('productFinder.showResults') }} ({{ getProducts.length }})
      </btn>
      <btn variant="light"
           @click.native="resetAll"
           :to="{ name: 'ProductFinder' }"
      >
        {{ $t('productFinder.allFilters') }}
      </btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Btn from '@/components/molecules/Btn';
import FormRadios from '@/components/molecules/FormRadios';
import ProductIndustries from '@/components/molecules/ProductIndustries';
import Icon from '@/components/atoms/Icon';

export default {
  name: 'ProductFinderWidget',
  components: {
    Icon,
    Btn,
    FormRadios,
    ProductIndustries,
  },
  computed: {
    ...mapState('products', ['deFilters', 'enFilters', 'activeIndustry', 'activeFilters']),
    ...mapGetters('products', ['getProducts']),
    filters() {
      return this[`${this.$i18n.locale}Filters`];
    },
    industry: {
      get() {
        return this.activeIndustry;
      },

      set(value) {
        this.setIndustry(value);
      },
    },
    sideFilters: {
      get() {
        return this.activeFilters;
      },

      set(value) {
        this.setFilters(value);
      },
    },
  },
  methods: {
    ...mapActions('products', ['setIndustry', 'setFilters', 'resetAll']),
  },
};
</script>
