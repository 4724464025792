<template>
  <div class="teasers-grid">
    <div v-if="gridHeader"
         class="teasers-grid__header">
      <!-- Items count -->
      <div class="teasers-grid__count">
        <div class="teasers-grid__count__num">
          <transition :name="newItemCountIsHigher ? 'count-change-down' : 'count-change-up'"
                      mode="out-in">
            <span v-bind:key="currentItemCount">{{ currentItemCount }}</span>
          </transition>
        </div>
        <span class="teasers-grid__count__heading">{{
            items.length === 1 ? $t('productFinder.product') : $t('productFinder.products')
                                                   }}</span>
      </div>
      <!-- Grid setting buttons -->
      <div class="teasers-grid__buttons">
        <div class="teasers-grid__button"
             :class="quickViewClasses"
             @click="showDetailed = false"
             @mouseover="showQuickViewTitle = true"
             @mouseleave="showQuickViewTitle = false"
             title="Schnellansicht">
          <transition name="teasers-grid__button-dropup">
            <div v-if="showQuickViewTitle"
                 class="teasers-grid__button-title">
              {{ $t('productFinder.quickView') }}
            </div>
          </transition>
          <i class="fas fa-th"></i>
        </div>
        <div class="teasers-grid__button"
             :class="detailedClasses"
             @click="showDetailed = true"
             @mouseover="showDetailedViewTitle = true"
             @mouseleave="showDetailedViewTitle = false"
             title="Detailansicht">
          <transition name="teasers-grid__button-dropup">
            <div v-if="showDetailedViewTitle"
                 class="teasers-grid__button-title">
              {{ $t('productFinder.detailView') }}
            </div>
          </transition>
          <i class="fas fa-align-justify"></i>
        </div>
      </div>

      <!--            Filter Button for XS-M-->
      <div class="teasers-grid__filter-button">
        <btn variant="transparent"
             icon="filter-main"
             @click.native="openModal">
          {{ $t('productFinder.filter') }}
        </btn>
      </div>
    </div>
    <!-- Items -->


    <dynamic-list @toggleModal="toggleModal($event)"
                  v-if="showDetailed"
                  :items="items"
                  :list-settings="dynamicListSettingNames"
                  :show-icon="showIcon">

    </dynamic-list>


    <div class="teasers-grid__col"
         v-for="(item, index) in items"
         :key="item.id">
      <teaser :to="{ name: to, params: {[param]: item.id} }"
              :title="item.title"
              :subtitle="subtitles ? item.subtitle : null"
              :img="item.image"
              :is-detailed="showDetailed"
              v-if="!showDetailed">
        <icon v-if="showIcon"
              name="bookmark"
              @click.native.prevent="toggleModal(index)"></icon>

      </teaser>
      <modal :product="product"
             v-model="modal[index]"
             :title="`${item.title} zur Merkliste hinzufügen`">
        <template #body>
          <page-title variant="label"
                      :headline="$t('productFinder.yourWishlists')"
                      btn-right
                      :back-btn="false">
            <btn variant="underlined-inline-text"
                 :to="{ name: 'Wishlists' }">{{ $t('productFinder.manageWishlists') }}
            </btn>
          </page-title>
          <multi-select-list :items="getListsFromCurrentUser"
                             :selected="getListIdsFromCurrentUserByProductId(item.id)"
                             v-on:update:selected-list="handleSelectedWishlists" />
          <add-wishlist-form />
        </template>
        <template #actions>
          <btn variant="highlighted"
               full-width
               :disabled="isDisabled(item.id)"
               @click.native.prevent="handleUpdateWishlist(index, item.id)">
            {{ $t('productFinder.save') }}
          </btn>
        </template>
      </modal>

    </div>
  </div>
</template>
<script>
import Teaser from '@/components/Teaser';
import Btn from '@/components/molecules/Btn';
import Modal from '@/components/organisms/Modal';
import Icon from '@/components/atoms/Icon';
import PageTitle from '@/components/molecules/PageTitle';
import AddWishlistForm from '@/components/forms/wishlist/AddWishlistForm';
import MultiSelectList from '@/components/molecules/MultiSelectList';
import { mapActions, mapGetters } from 'vuex';
import List from '@/components/molecules/List';
import ListItem from '@/components/molecules/ListItem';
import DynamicList from '@/components/molecules/DynamicList';
import DynamicListItem from '@/components/molecules/DynamicListItem';

export default {
  name: 'ProductCategoriesGrid',
  props: {
    items: {},
    param: String,
    to: String,
    gridHeader: Boolean,
    showIcon: Boolean,
    subtitles: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    DynamicListItem,
    DynamicList,
    List,
    ListItem,
    MultiSelectList,
    AddWishlistForm,
    PageTitle,
    Icon,
    Modal,
    Btn,
    Teaser,
  },
  data() {
    return {
      showDetailed: false,
      showQuickViewTitle: false,
      showDetailedViewTitle: false,
      selectedWishlists: [],
      modal: [],
      checkboxValues: [],
      dynamicListSettingNames: ['productFinder.width', 'productFinder.height', 'productFinder.weight', 'productFinder.depth', 'productFinder.voltage', 'productFinder.power', 'productFinder.validation', 'productFinder.compressedAir', 'productFinder.airConsumption', 'productFinder.spread', 'productFinder.Spread to'],
      dynamicListItemInfo: [],
      currentItemCount: this.items.length,
      lastItemCount: null,
      newItemCountIsHigher: Boolean,
    };
  },
  created() {
    // makes underline stay in place on screen-resize
    window.addEventListener('resize', this.disableDetailedListOnMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.disableDetailedListOnMobile);
  },
  methods: {
    ...mapActions('wishlists', ['setItemsOnWishlist']),
    openModal() {
      this.$emit('openModal');
    },
    handleSelectedWishlists(e) {
      this.selectedWishlists = e;
    },
    handleUpdateWishlist(i, id) {
      this.setItemsOnWishlist({ wishlistIds: this.selectedWishlists, productId: id });
      this.modal[i] = false;
    },
    toggleModal(i) {
      this.modal[i] = !this.modal[i];
    },
    isDisabled(id) {
      const list1 = JSON.parse(JSON.stringify(this.selectedWishlists));
      const list2 = JSON.parse(JSON.stringify(this.getListIdsFromCurrentUserByProductId(id)));
      return JSON.stringify(list1.sort()) === JSON.stringify(list2.sort());
    },
    disableDetailedListOnMobile() {
      if (window.matchMedia('(max-width: 1100px)').matches) {
        this.showDetailed = false;
      }
    },
  },
  computed: {
    ...mapGetters('wishlists', ['getListsFromCurrentUser', 'getListIdsFromCurrentUserByProductId']),
    ...mapGetters('products', ['getProductById']),
    productId() {
      return this?.$route?.params?.productId;
    },
    product() {
      return this.getProductById(this.productId);
    },
    detailedClasses() {
      return {
        [`teasers-grid__button--deactivated`]: !this.showDetailed,
      };
    },
    quickViewClasses() {
      return {
        [`teasers-grid__button--deactivated`]: this.showDetailed,
      };
    },
  },
  watch: {
    items() {
      this.modal = this.items.map(item => false);
    },
  },
  updated() {
    this.lastItemCount = this.currentItemCount;
    this.currentItemCount = this.items.length;
    if (this.lastItemCount > this.currentItemCount) {
      this.newItemCountIsHigher = false;
    } else if (this.lastItemCount < this.currentItemCount) {
      this.newItemCountIsHigher = true;
    }
  },
};

</script>
