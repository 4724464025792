<template>
  <div class="alert"
       :class="classes">
    <div class="alert__icon"></div>
    <div class="alert__title">
      <slot></slot>
    </div>
  </div>
</template>
<script>

const availableVariants = ['error', 'info', 'success'];
const availableSizes = ['default', 'small'];

export default {
  name: 'Alert',
  props: {
    variant: {
      type: String,
      default: 'error',
    },
    size: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    classes() {
      return {
        [`alert--variant-${this.variant}`]: this.variant && availableVariants.includes(this.variant),
        [`alert--size-${this.size}`]: this.size && availableSizes.includes(this.size),
      };
    },
  },
};
</script>
