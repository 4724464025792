export default {
  namespaced: true,
  state: {
    lists: [],
  },
  mutations: {
    SET(state, { key, value }) {
      state[key] = value;
    },
    CREATE_LIST(state, { userId, wishlistId, wishlistName }) {
      const newList = {
        id: wishlistId,
        userId,
        name: wishlistName,
        items: [],
      };
      state.lists.push(newList);
    },
    ADD_TO_WISHLIST(state, { wishlistId, productId }) {
      const list = state.lists.find(({ id }) => id === wishlistId);
      if (!list.items.includes(productId)) {
        list.items.push(productId);
      }
    },
    REMOVE_FROM_WISHLIST(state, { wishlistId, productId }) {
      const list = state.lists.find(({ id }) => id === wishlistId);
      const index = list.items.indexOf(productId);
      if (index > -1) {
        list.items.splice(index, 1);
      }
    },
  },
  actions: {
    async removeItemFromWishlist({ commit }, { wishlistId, productId }) {
      commit('REMOVE_FROM_WISHLIST', { wishlistId: Number(wishlistId), productId: productId });
    },
    async setItemsOnWishlist({ state, commit, rootGetters }, { wishlistIds, productId }) {
      const currentUserId = rootGetters['auth/getCurrentUser'].id;
      const lists = state.lists.filter(item => item.userId === currentUserId);
      lists.forEach((list) => {
        let shouldBeOnWishlist = wishlistIds.includes(list.id);
        let productIsOnWishlist = list.items.includes(productId);
        if (shouldBeOnWishlist && !productIsOnWishlist) {
          commit('ADD_TO_WISHLIST', { wishlistId: list.id, productId: productId });
        } else if (!shouldBeOnWishlist && productIsOnWishlist) {
          commit('REMOVE_FROM_WISHLIST', { wishlistId: list.id, productId: productId });
        }
      });
    },
    async createWishlist({ state, commit, rootGetters }, wishlistName) {
      let id = 1;
      const listIds = state.lists.map(list => list.id);
      if (listIds.length > 0) {
        id = Math.max(...listIds) + 1;
      }
      commit('CREATE_LIST', {
        userId: rootGetters['auth/getCurrentUser'].id,
        wishlistId: id,
        wishlistName,
      });
    },
    deleteWishlist({ state, commit }, wishlistId) {
      const filteredLists = state.lists.filter(list => {
        return list.id !== wishlistId;
      });
      commit('SET', { key: 'lists', value: filteredLists });
    },
  },
  getters: {
    getListsFromCurrentUser(state, getters, rootState, rootGetters) {
      return state.lists.filter(item => item.userId === rootGetters['auth/getCurrentUser'].id);
    },
    getListIdsFromCurrentUserByProductId: (state, getters, rootState, rootGetters) => productId => {
      return state.lists.filter(item => item.userId === rootGetters['auth/getCurrentUser'].id && item.items.includes(productId)).map(item => item.id);
    },
    getListById: (state) => listId => {
      return state.lists.find(({ id }) => id === listId);
    },
  },
};
