<template>
  <div class="container">
    <div class="page-module-switch">
      <div v-for="module in modules"
           class="page-module-switch__item">
        <router-link class="page-module-switch__link"
                     :to="{ name: module.initialRoute }">
          <div class="teaser">
            <h2>{{ $t(module.name) }}</h2>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getAvailableModules } from '@/helpers/modules';
import Card from '@/components/atoms/Card';

export default {
  components: {
    Card,
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUser']),
    modules() {
      return getAvailableModules(this.getCurrentUser.roles, this.$route);
    },
  },
};
</script>
