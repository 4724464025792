<template>
  <div @click.prevent="unsetFilter(filter)"
       class="filter-chip"
       v-if="text !== '' && text !== 'null'">
    <div class="filter-chip__content"
         v-html="text"></div>
    <div class="filter-chip__remove"></div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  props: {
    filter: {
      type: String,
    },
    text: {
      default: '',
      type: String,
    },
  },
  methods: {
    ...mapActions('mypoly', ['unsetFilter']),
  },
};
</script>
