<template>
  <div class="search-widget">
    <tabs v-if="mode === 'tabs'"
          :items="tabs" />
    <file-search v-if="mode === 'single-form'" />
  </div>
</template>
<script>
import Card from '@/components/atoms/Card';
import Tabs from '@/components/molecules/Tabs';
import FreetextSearch from '@/components/forms/FreetextSearch';
import DetailSearch from '@/components/forms/DetailSearch';
import FileSearch from '@/components/forms/FileSearch';

export default {
  name: 'SearchWidget',
  props: {
    mode: {
      default: 'tabs',
      type: String,
    },
  },
  components: {
    Card,
    Tabs,
    FileSearch,
  },
  computed: {
    tabs() {
      return [
        {
          name: 'myPolyclip.freeText',
          component: FreetextSearch,
        },
        {
          name: 'myPolyclip.detail',
          component: DetailSearch,
        },
      ];
    },
  },
};
</script>
