<template>
  <div class="page-title__container">
    <back-btn v-if="backBtn"
              mobile />
    <div class="page-title"
         :class="classes">
      <div class="page-title__text">
        <p v-if="subline"
           class="page-title__subline">{{ subline }}</p>
        <p class="page-title__headline"
           v-html="headline"></p>
        <div v-if="$slots.default && !btnRight"
             class="page-title__slot">
          <slot></slot>
        </div>
      </div>
      <div v-if="img">
        <lazy-img class="page-title__img"
                  :src="img" />
      </div>
      <div v-if="$slots.default && btnRight"
           class="page-title__slot">
        <slot></slot>
      </div>
      <div v-if="badge"
           class="page-title__badge">
        <div class="page-title__badge-icon"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Btn from '@/components/molecules/Btn';
import BackBtn from '@/components/molecules/BackBtn';
import LazyImg from '@/components/molecules/LazyImg';


export default {
  name: 'PageTitle',
  components: {
    LazyImg,
    BackBtn,
    Btn,
  },
  props: {
    centered: Boolean,
    btnRight: Boolean,
    smallFont: Boolean,
    badge: Boolean,
    subline: String,
    headline: String,
    img: String,
    variant: String,
    underneath: Boolean,
    backBtn: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    classes() {
      return {
        ['page-title--underneath']: this.underneath,
        ['page-title--between']: !!this.img || !!this.btnRight,
        ['page-title--centered']: !!this.centered,
        [`page-title--variant-${this.variant}`]: this.variant,
      };
    },
  },
};
</script>
