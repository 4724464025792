<template>
  <div>
    <head-section img="finder-big">
      <page-title centered
                  :headline="$t('myPolyclip.library')"
                  class="m-bottom-very-big" />
    </head-section>

    <div class="container">
      <transition name="dropdown-right">
        <modal
            v-if="addModal"
            title="Filter"
            full-screen
            mobile
            box-background-bg
            head-background-white
            head-shadow
            full-height
            v-model="addModal">
          <template #body>

            <div class="filters">
                            <span class="filter__reset"
                                  v-if="isResetVisible"
                                  @click.prevent="unsetFilters">{{ $t('myPolyclip.reset') }}</span>
              <div class="filter__settings">
                <div class="filter__settings-title">
                  {{ $t('myPolyclip.general') }}
                </div>
                <form>
                  <accordion v-for="(category, index) in getCategoryGeneral"
                             :pre-opened="!!category.children.find(child => child.id === filterSelectedGeneral)"
                             :key="`accordion_general${index}`"
                             :label="category.title">
                    <div class="form-radios__radio-wrapper"
                         v-for="child in category.children">
                      <input name="category"
                             class="form-radios__radio"
                             type="radio"
                             v-model="filter.selectedGeneral"
                             @change.prevent="handleForm"
                             :value="child.id"
                             :id="`child${child.id}`">
                      <label :for="`child${child.id}`">{{ child.title }}</label>
                    </div>
                  </accordion>
                </form>
                <div class="filter__settings-title">
                  {{ $t('myPolyclip.machine') }}
                </div>
                <form>
                  <accordion v-for="(category, index) in getCategoryMachines"
                             :pre-opened="!!category.children.find(child => child.id === filterSelectedMachine)"
                             :key="`accordion_machine${index}`"
                             :label="category.title">
                    <div class="form-radios__radio-wrapper"
                         v-for="child in category.children">
                      <input name="category"
                             class="form-radios__radio"
                             type="radio"
                             v-model="filter.selectedMachine"
                             @change.prevent="handleForm"
                             :value="child.id"
                             :id="`child${child.id}`">
                      <label :for="`child${child.id}`">{{ child.title }}</label>
                    </div>
                  </accordion>
                </form>
              </div>
            </div>

          </template>
          <template #actions>
            <btn variant="highlighted"
                 full-width
                 @click.native="addModal = false">
              <span>{{ cachedFiles.length }}</span>
              {{ cachedFiles.length === 1 ? $t('myPolyclip.file') : $t('myPolyclip.files') }}
            </btn>
          </template>
        </modal>
      </transition>


      <div class="product-finder__results">
        <div class="product-finder__filters">
          <div class="filters">
            <div class="filter__head">
              <h4 class="filter__label">Filter</h4>
              <span class="filter__reset"
                    v-if="isResetVisible"
                    @click.prevent="unsetFilters">{{ $t('myPolyclip.reset') }}</span>
            </div>

            <div class="filters">
              <div class="filter__settings">
                <div class="filter__settings-title">
                  {{ $t('myPolyclip.general') }}
                </div>
                <form>
                  <accordion v-for="(category, index) in getCategoryGeneral"
                             :pre-opened="!!category.children.find(child => child.id === filterSelectedGeneral)"
                             :key="`accordion_general${index}`"
                             :label="category.title">
                    <div class="form-radios__radio-wrapper"
                         v-for="child in category.children">
                      <input name="category"
                             class="form-radios__radio"
                             type="radio"
                             v-model="filter.selectedGeneral"
                             @change.prevent="handleForm"
                             :value="child.id"
                             :id="`child${child.id}`">
                      <label :for="`child${child.id}`">{{ child.title }}</label>
                    </div>
                  </accordion>
                </form>
                <div class="filter__settings-title">
                  {{ $t('myPolyclip.machine') }}
                </div>
                <form>
                  <accordion v-for="(category, index) in getCategoryMachines"
                             :pre-opened="!!category.children.find(child => child.id === filterSelectedMachine)"
                             :key="`accordion_machine${index}`"
                             :label="category.title">
                    <div class="form-radios__radio-wrapper"
                         v-for="child in category.children">
                      <input name="category"
                             class="form-radios__radio"
                             type="radio"
                             v-model="filter.selectedMachine"
                             @change.prevent="handleForm"
                             :value="child.id"
                             :id="`child${child.id}`">
                      <label :for="`child${child.id}`">{{ child.title }}</label>
                    </div>
                  </accordion>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div style="width: 100%">
          <!-- Filter Button for XS-M -->
          <btn variant="transparent"
               border="border-darken"
               spaced-bottom
               tablet-only
               auto-width
               icon="filter-main"
               grey-dark
               @click.native="openModal">
            Filter
          </btn>
          <search-widget mode="single-form"
                         class="m-bottom-small" />

          <extended-filter v-if="showExtendedFilter"
                           :filters="filters"
                           @input="handleForm"
                           v-model="filter.extended"
                           class="m-bottom-small m-bottom-0-l-xl"></extended-filter>

          <data-table :headers="tableHeaders"
                      :items="cachedFiles">

            <template #filters>
              <filter-chip :text="extendedIdToName"
                           filter="filterExtended"></filter-chip>
              <filter-chip :text="selectedGeneralIdToName"
                           filter="filterSelectedGeneral"></filter-chip>
              <filter-chip :text="selectedMachineToName"
                           filter="filterSelectedMachine"></filter-chip>
              <filter-chip :text="String(filterMachine)"
                           filter="filterMachine"></filter-chip>
              <filter-chip :text="String(filterMachineNr)"
                           filter="filterMachineNr"></filter-chip>
              <filter-chip :text="String(filterText)"
                           filter="filterText"></filter-chip>
              <filter-chip :text="languageIdToName"
                           filter="filterLanguage"></filter-chip>
            </template>

            <template v-slot:default="{ item }">
              <add-to-wishlist :item="item" />
            </template>

          </data-table>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import DataTable from '@/components/molecules/DataTable';
import HeadSection from '@/components/molecules/HeadSection';
import PageTitle from '@/components/molecules/PageTitle';
import SearchWidget from '@/components/organisms/SearchWidget';
import Accordion from '@/components/molecules/Accordion';
import FormRadios from '@/components/molecules/FormRadios';
import AddToWishlist from '@/components/atoms/AddToWishlist';
import Modal from '@/components/organisms/Modal';
import Btn from '@/components/molecules/Btn';
import env from '@/helpers/env';
import ExtendedFilter from '@/components/molecules/ExtendedFilter';
import FilterChip from '@/components/atoms/FilterChip.vue';

export default {
  name: 'MyLibrary',
  components: {
    FilterChip,
    ExtendedFilter,
    AddToWishlist,
    FormRadios,
    DataTable,
    HeadSection,
    PageTitle,
    SearchWidget,
    Accordion,
    Modal,
    Btn,
  },
  data() {
    return {
      filters: [
        {
          name: 'myPolyclip.extended_filters.machines',
          id: env('API_TESSA_MYPOLY_CAT_MACHINES'),
        },
        {
          name: 'myPolyclip.extended_filters.applications',
          id: env('API_TESSA_MYPOLY_CAT_APPLICATION'),
        },
        {
          name: 'myPolyclip.extended_filters.consumables',
          id: env('API_TESSA_MYPOLY_CAT_CONSUMABLES'),
        },
        {
          name: 'myPolyclip.extended_filters.others',
          id: env('API_TESSA_MYPOLY_CAT_OTHER'),
        },
      ],
      filter: {
        extended: env('API_TESSA_MYPOLY_CAT_MACHINES'),
        selectedGeneral: null,
        selectedMachine: null,
      },

      itemsOnWishlist: {},
      addModal: false,
    };
  },
  methods: {
    ...mapActions('mypoly', ['resetFilters', 'setFilters']),
    unsetFilters() {
      this.resetFilters();
    },
    handleForm() {
      this.setFilters(this.filter);
    },
    openModal() {
      this.addModal = true;
    },
    evaluateFilters() {
      const id = Number(this.$route?.params?.categoryId);
      const general = this.getDeepGeneralCategoryById(id);
      const machine = this.getDeepMachineCategoryById(id);
      if (general) {
        this.filter.selectedGeneral = id;
      }
      if (machine) {
        this.filter.selectedMachine = id;
      }
      if (id) {
        this.setFilters(this.filter);
      }
    },
  },
  computed: {
    ...mapState('mypoly', [
      'filterSelectedGeneral',
      'filterSelectedMachine',
      'filterMachine',
      'filterMachineNr',
      'filterLanguage',
      'filterText',
      'filterExtended',
      'cachedFiles',
    ]),
    ...mapGetters('mypoly', [
      'getCategoryById',
      'getCategoryGeneral',
      'getCategoryMachines',
      'getDeepGeneralCategoryById',
      'getDeepMachineCategoryById',
      'getLanguageById',
    ]),
    ...mapGetters(['isLoading']),
    languageIdToName() {
      return this.filterLanguage && this.$i18n.locale ? this.getLanguageById(this.filterLanguage)[`name_${this.$i18n.locale}`] : '';
    },
    extendedIdToName() {
      return this.filters.filter(f => f.id === String(this.filterExtended)).length > 0 ? this.$t(this.filters.filter(f => f.id === String(this.filterExtended))[0].name) : '';
    },
    selectedGeneralIdToName() {
      return this.getDeepGeneralCategoryById(this.filterSelectedGeneral) ? this.getDeepGeneralCategoryById(this.filterSelectedGeneral).title : '';
    },
    selectedMachineToName() {
      return this.getDeepMachineCategoryById(this.filterSelectedMachine) ? this.getDeepMachineCategoryById(this.filterSelectedMachine).title : '';
    },
    showExtendedFilter() {
      return env('api_tessa_mypoly_image_category') === String(this.filterSelectedGeneral);
    },
    isLoadingA() {
      return this.isLoading('myPolyFetchData');
    },
    isResetVisible() {
      return this.filterSelectedGeneral !== null ||
          this.filterSelectedMachine !== null ||
          this.filterMachine !== null ||
          this.filterMachineNr !== null ||
          this.filterLanguage !== null ||
          this.filterExtended !== null ||
          this.filterText !== null;
    },
    tableHeaders() {
      return [
        {
          name: 'myPolyclip.name',
          field: 'name',
        },
        {
          name: 'myPolyclip.fileType',
          field: 'type',
        },
        {
          name: 'myPolyclip.language',
          field: 'languages',
        },
        {
          name: 'myPolyclip.size',
          field: 'size',
        },
      ];
    },
  },
  watch: {
    filterSelectedGeneral() {
      this.filter.selectedGeneral = this.filterSelectedGeneral;
    },
    filterSelectedMachine() {
      this.filter.selectedMachine = this.filterSelectedMachine;
    },
    isLoadingA(newValue) {
      if (newValue === false) {
        this.evaluateFilters();
      }
    },
  },
  mounted() {
    this.filter.selectedGeneral = this.filterSelectedGeneral;
    this.filter.selectedMachine = this.filterSelectedMachine;
    this.evaluateFilters();
  },
};
</script>
