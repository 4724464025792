// polyfills
import 'core-js/es/symbol';
import 'core-js/features/symbol/iterator';

// dependencies
import { createApp } from 'vue';
import VueWait from 'vue-wait';
import VueVirtualScroller from 'vue-virtual-scroller';
import { createI18n } from 'vue-i18n/index';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

// app
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import { messages } from '@/helpers/locales';

// styling
import '@/scss/app.scss';

// global filters
import date from '@/filters/date';
import bytes from '@/filters/bytes';

// directives
import clickOutside from '@/directives/click-outside';

const app = createApp(App);

// register directives
app.directive('click-outside', clickOutside);

// user plugins
// Vue.use(VueI18n);
//
// // filters
// Vue.filter('date', date);
// Vue.filter('bytes', bytes);
//

app.config.globalProperties.$filters = {
  date,
  bytes,
};

const i18n = new createI18n({
  locale: 'de',
  messages,
});

app.use(store)
.use(router)
.use(i18n)
.use(VueVirtualScroller)
.mount('#app');
