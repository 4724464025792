<template>
  <div class="language-switch">
    <div class="language-switch__title">{{ $t('login.languageSwitch') }}</div>
    <div class="language-switch__items">
      <div v-for="lang in availableLanguages"
           :key="lang"
           class="language-switch__item"
           :class="{'language-switch__item--active': lang === $i18n.locale}"
           @click.prevent="selectLanguage(lang)">{{ lang }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'LanguageSwitch',
  data() {
    return {
      availableLanguages: ['de', 'en'],
    };
  },
  methods: {
    ...mapActions(['setLocale']),
    selectLanguage(lang) {
      this.setLocale(lang);
      this.$i18n.locale = lang;
    },
  },
};
</script>
