<template>
  <div class="lazy-img"
       :class="classes">
    <transition name="lazy-image-fade">
      <img v-if="path"
           class="lazy-img__img"
           :src="path"
           :alt="alt">
      <img v-else
           class="lazy-img__img lazy-img__img--no-img"
           src="@/assets/no-img.svg"
           alt="Kein Bild verfügbar.">
    </transition>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import DB from '@/helpers/indexedDb';
import Icon from '@/components/atoms/Icon';

export default {
  name: 'lazy-img',
  components: {
    Icon,
  },
  props: {
    src: String,
    alt: String,
    variant: String,
  },
  data() {
    return {
      loading: true,
      path: null,
      queueWatch: null,
    };
  },
  methods: {
    async loadImage() {
      if (!this.src) {
        this.loading = false;
        return false;
      }
      const item = await this.localDatabase?.getEntry(this.src);

      if (!item) {
        this.loading = false;
        return false;
      }
      this.path = URL.createObjectURL(item.blob);
      this.loading = false;
    },
  },
  computed: {
    ...mapState('offline', ['queue']),
    classes() {
      return {
        [`lazy-img--variant-${this.variant}`]: this.variant,
      };
    },
  },
  watch: {
    queue: {
      handler(newValue, oldValue) {
        this.loadImage();
      },
      deep: true,
    },
  },
  async mounted() {
    this.localDatabase = await DB('sales-app');
    this.$nextTick(async () => {
      await this.loadImage();
    });
  },
};
</script>
