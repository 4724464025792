<template>
  <div id="app">
    <loading-overlay v-if="isLoading('bootstrap')" />
    <template v-else>
      <template v-if="!authenticated">
        <login-form />
      </template>
      <template v-else>
        <modal :closeable="false"
               v-model="showUpdateModal"
               :title="$t('sales.update_title')">
          <template #body>
            {{ $t('sales.update_description') }}
          </template>
          <template #actions>
            <btn half-width
                 variant="highlighted"
                 @click.native.prevent="doUpdate">
              {{ $t('sales.update_do') }}
            </btn>
            <btn half-width
                 @click.native.prevent="skipUpdate">
              {{ $t('sales.update_skip') }}
            </btn>
          </template>
        </modal>
        <sidebar />
        <back-btn />
        <main :class="classes">
          <app-header v-if="!appHeaderIsVisible" />

          <router-view v-slot="{ Component }"
                       class="router-view">
            <transition name="page-transition"
                        mode="out-in">
              <component :is="Component"
                         v-if="hasPermissions" />
              <div v-else
                   class="router-view">
                <page-title class="m-top-large"
                            centered
                            subline="403"
                            :headline="$t('myPolyclip.missingPermission')" />
              </div>
            </transition>
          </router-view>
          <app-footer v-if="!footerIsVisible" />
        </main>
      </template>
    </template>


    <modal
        v-if="myPolyPrivacyModal"
        :title="$t('myPolyclip.welcomeTo')"
        :closeable="false"
        v-model="myPolyPrivacyModal">
      <template #body>
        <p class="p1 m-bottom-small">
          {{ $t('myPolyclip.photo') }}
          <a :href="$t('login.terms_of_use_link')"
             target="_blank">{{ $t('myPolyclip.terms') }}</a>
          {{ $t('myPolyclip.copyright') }}</p>
        <p class="p1 m-bottom-small">
          {{ $t('myPolyclip.publication') }}
        </p>
        <p class="p1">
          {{ $t('myPolyclip.download') }}
          <a :href="$t('login.terms_of_use_link')"
             target="_blank">{{ $t('myPolyclip.terms') }}</a> {{ $t('myPolyclip.reading') }}
        </p>
      </template>
      <template #actions>
        <btn full-width
             variant="highlighted"
             @click.native.prevent="myPolyModalOk">
          Ok
        </btn>
      </template>
    </modal>

  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import AppHeader from '@/components/organisms/AppHeader';
import AppFooter from '@/components/organisms/AppFooter';
import Sidebar from '@/components/organisms/Sidebar';
import LoginForm from '@/components/forms/LoginForm';
import Btn from '@/components/molecules/Btn';
import LoadingOverlay from '@/components/LoadingOverlay';
import PageTitle from '@/components/molecules/PageTitle';
import BackBtn from '@/components/molecules/BackBtn';
import Modal from '@/components/organisms/Modal';

export default {
  name: 'App',
  components: {
    Btn,
    AppHeader,
    AppFooter,
    LoadingOverlay,
    LoginForm,
    Sidebar,
    PageTitle,
    BackBtn,
    Modal,
  },
  data() {
    return {
      myPolyPrivacyModal: false,
    };
  },
  methods: {
    ...mapActions('offline', ['startSync', 'doUpdate', 'skipUpdate']),
    ...mapActions(['bootstrap', 'setOnline', 'setLocale']),
    ...mapActions('auth', ['refreshAuth', 'refreshProfile', 'setAuth', 'isStillAuthenticated', 'logoutUser', 'setMyPolyModalShown']),
    onOnline() {
      this.setOnline(true);
    },
    onOffline() {
      this.setOnline(false);
    },
    async myPolyModal() {
      if (!this.myPolyModalShown && this.$route && this.$route.fullPath && this.$route.fullPath.includes('mypoly')) {
        this.myPolyPrivacyModal = true;
      }
    },
    async myPolyModalOk() {
      await this.setMyPolyModalShown(true);
      this.myPolyPrivacyModal = false;
    },
  },
  computed: {
    ...mapState(['online', 'locale']),
    ...mapState('offline', ['showUpdateModal']),
    ...mapGetters('auth', ['getCurrentUser']),
    ...mapState('auth', ['authenticated', 'myPolyModalShown']),
    ...mapGetters(['isLoading']),
    appHeaderIsVisible() {
      return this.$route?.meta?.hideAppHeader === true;
    },
    footerIsVisible() {
      return this.$route?.meta?.hideFooter === true;
    },
    hasPermissions() {
      const checker = (arr, target) => target.every(v => arr.includes(v));
      const requiredRoles = this.$route?.meta?.requiresRole;
      const userRoles = this.getCurrentUser.roles;

      if (typeof requiredRoles === 'object') {
        return checker(userRoles, requiredRoles);
      } else if (typeof requiredRoles === 'string') {
        return userRoles.includes(requiredRoles);
      } else {
        return true;
      }
    },
    classes() {
      return {
        [`main--sidebar-narrow`]: this.$route.meta.narrowSidebar,
      };
    },
  },
  async mounted() {
    window.addEventListener('online', this.onOnline);
    window.addEventListener('offline', this.onOffline);

    if (navigator.onLine) {
      this.setOnline(true);
    } else {
      this.setOnline(false);
    }

    if (this.online && navigator.onLine) {
      const authenticated = await this.isStillAuthenticated();
      if (authenticated) {
        if (this.locale === -1) {
          this.setLocale('de');
        }
        this.$i18n.locale = this.locale;
        await this.refreshAuth();
        await this.refreshProfile();
        await this.myPolyModal();
        await this.bootstrap();
        await this.startSync();
      } else {
        await this.logoutUser();
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('online', this.onOnline);
    window.removeEventListener('offline', this.onOffline);
  },
  watch: {
    $route() {
      this.myPolyModal();
    },
    authenticated() {
      this.myPolyModal();
    },
  },
};
</script>
