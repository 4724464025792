<template>
  <div class="container">
    <div v-if="getDownloadsBySearch(query).length <= 0">
      {{ $t('header.search') }}
    </div>
    <card v-else
          class="m-bottom-large ">
      <download v-for="(download, downloadIndex) in getDownloadsBySearch(query)"
                :key="`download${downloadIndex}`"
                :download="download"></download>
    </card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DownloadState from '@/components/atoms/DownloadState';
import Btn from '@/components/molecules/Btn';
import Download from '@/components/molecules/Download';
import Card from '@/components/atoms/Card';

export default {
  name: 'Search',
  components: {
    Card,
    Btn,
    DownloadState,
    Download,
  },
  props: {
    query: String,
  },
  methods: {
    getDownloadFileExtension(downloadName) {
      const sections = downloadName.split('.');
      return sections[sections.length - 1];
    },
  },
  computed: {
    ...mapGetters('offline', ['getDownloadsBySearch']),
  },
};
</script>
