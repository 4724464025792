<template>
  <div class="error-banner">
    <div class="error-banner__icon"></div>
    <div class="error-banner__title">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ErrorBanner',
};
</script>
