<template>
  <div class="form-checkboxes">
    <form-label big>
      {{ label }}
      <span v-if="mandatory">*</span>
    </form-label>
    <div class="form-checkboxes__radio-wrapper"
         v-for="(option, index) in options">
      <input @input="handle"
             :disabled="disabled"
             class="form-checkboxes__radio"
             :id="`form-checkboxes_${uid}_${index}`"
             type="checkbox"
             :checked="(modelValue && typeof modelValue === 'object') ? modelValue.includes(option.value) || modelValue.includes(option.id) : false"
             :value="option.value ? option.value : option.id"
             :name="name">
      <form-label :class="leadSpacing"
                  v-if="option.name"
                  :reference="`form-checkboxes_${uid}_${index}`">{{ $t(option.name) }}
      </form-label>
      <form-label :class="leadSpacing"
                  v-if="option.title"
                  :reference="`form-checkboxes_${uid}_${index}`">{{ option.title }}
      </form-label>
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import FormLabel from '@/components/atoms/FormLabel';

export default {
  name: 'FormCheckboxes',
  emits: ['update:modelValue'],
  props: {
    modelValue: {},
    name: {},
    label: {},
    options: {},
    mandatory: Boolean,
    readOnly: Boolean,
    disabled: Boolean,
    leadSpacing: String,
  },
  components: {
    FormLabel,
  },
  methods: {
    handle(e) {
      const isChecked = e.target.checked;
      const modelValue = e.target.value;
      const newValue = this.modelValue && typeof this.modelValue === 'object' ? [...this.modelValue] : [];

      if (isChecked) {
        newValue.push(modelValue);
      } else {
        newValue.splice(newValue.indexOf(modelValue), 1);
      }

      this.$emit('update:modelValue', newValue);
    },
  },
  computed: {
    uid() {
      return uuidv4();
    },
  },
};
</script>
