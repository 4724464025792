<template>
  <div class="back-btn"
       :class="classes">
    <transition name="appear">
      <btn v-if="isVisible"
           variant="muted"
           icon="long-arrow-alt-left"
           @click.native.prevent="back" />
    </transition>
  </div>
</template>
<script>
import Icon from '@/components/atoms/Icon';
import Btn from '@/components/molecules/Btn';

export default {
  name: 'BackBtn',
  components: {
    Icon,
    Btn,
  },
  props: {
    mobile: Boolean,
  },

  computed: {
    isVisible() {
      return this.$route?.meta?.backButton === true;
    },
    classes() {
      return {
        ['back-btn--mobile']: !!this.mobile,
      };
    },
  },
  methods: {
    back() {
      if (window.vueRouterFrom.name === null) {
        this.$router.push({ name: 'Dashboard' });
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
