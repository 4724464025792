<template>
  <div class="user-info__meta"
       :class="classes">
    <span class="user-info__heading">{{ $t('sidebar.loggedIn') }}</span>
    <div class="user-info__user">
      {{ first_name }} {{ last_name }}
    </div>
    <router-link class="user-info__link"
                 :to="{ name: 'Settings' }"><span class="user-info__text">{{ $t('sidebar.settings') }}</span>
    </router-link>
    <a class="user-info__link user-info__link--logout"
       href="#"
       @click.prevent="logout"><span class="user-info__text">{{ $t('sidebar.logout') }}</span></a>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'UserInfo',
  props: {
    mobile: Boolean,
  },
  methods: {
    ...mapActions('auth', ['logoutUser']),
    ...mapActions('offline', ['startPause']),
    logout() {
      this.startPause();
      this.logoutUser();
    },
  },
  computed: {
    ...mapState('auth', ['authenticated', 'roles', 'first_name', 'last_name']),
    classes() {
      return {
        [`user-info__meta--mobile`]: this.mobile,
      };
    },
  },
};


</script>
