<template>
  <div class="sidebar"
       :class="classes">
    <version class="version--sidebar" />
    <div class="sidebar__header">
      <logo :tiny="isNarrow" />
    </div>
    <div class="sidebar__navigations">
      <navigation-menu :title="$t('sidebar.switchTo')"
                       :items="defaultNavigation"
                       :narrow="isNarrow" />
      <navigation-menu title="Sales App"
                       :items="salesAppNavigation"
                       :narrow="isNarrow" />
      <navigation-menu title="My Poly-clip"
                       :items="myPolyclipNavigation"
                       :narrow="isNarrow" />
      <navigation-menu :title="$t('sidebar.other')"
                       :items="othersNavigation"
                       :narrow="isNarrow" />
    </div>


    <div class="sidebar__footer">
      <div v-if="showModuleSwitch"
           @click="toggleAppSwitcher"
           class="sidebar__app-switcher">
        <div class="sidebar__app-switcher-icon icon--shuffle icon--strong">
        </div>
        <div class="sidebar__app-switcher-title">
          {{ $t('sidebar.switchTo') }}
        </div>
        <div v-if="appSwitcherOpen"
             class="sidebar__app-switcher-menu">
          <card v-click-outside="closeAppSwitcher"
                is-spaceless
                is-bordered
                is-shadowed
                angle
                angle-left>
            <module-switch vertical
                           :variant="isNarrow ? 'in-sidebar-narrow' : 'in-sidebar'" />
          </card>
        </div>
      </div>

      <div class="sidebar__user-meta">
        <user-info />
      </div>
    </div>
  </div>
</template>
<script>
import { getNavigation } from '@/router';

import Logo from '@/components/atoms/Logo';
import NavigationMenu from '@/components/molecules/NavigationMenu';
import Btn from '@/components/molecules/Btn';
import UserInfo from '@/components/molecules/UserInfo';
import { mapGetters, mapState } from 'vuex';
import {
  MODULE_NONE,
  MODULE_LEAD_AND_SALES,
  MODULE_MY_POLY,
  MODULE_OTHERS,
  getAvailableModules,
} from '@/helpers/modules';
import ModuleSwitch from '@/components/molecules/ModuleSwitch';
import Card from '@/components/atoms/Card';
import Version from '@/components/atoms/Version';

export default {
  name: 'Sidebar',
  components: {
    Card,
    ModuleSwitch,
    UserInfo,
    Logo,
    NavigationMenu,
    Btn,
    Version,
  },
  data() {
    return {
      appSwitcherOpen: false,
      justToggled: false,
    };
  },
  methods: {
    toggleAppSwitcher() {
      this.appSwitcherOpen = !this.appSwitcherOpen;
      this.justToggled = true;

    },
    closeAppSwitcher() {
      if (!this.justToggled) {
        this.appSwitcherOpen = false;
      }
      this.justToggled = false;
    },
  },
  computed: {
    ...mapState('auth', ['authenticated', 'roles', 'first_name', 'last_name']),
    ...mapGetters('auth', ['getCurrentUser']),
    defaultNavigation() {
      const nav = getNavigation(MODULE_NONE, this.roles, this.authenticated);

      // Since modules are permission-aware, make sure to show only links to allowed modules.
      return nav.filter(item => this.modules.some(module => module.name === item.meta.name));
    },
    salesAppNavigation() {
      return getNavigation(MODULE_LEAD_AND_SALES, this.roles, this.authenticated);
    },
    myPolyclipNavigation() {
      return getNavigation(MODULE_MY_POLY, this.roles, this.authenticated);
    },
    othersNavigation() {
      return getNavigation(MODULE_OTHERS, this.roles, this.authenticated);
    },
    isNarrow() {
      return this.$route.meta.narrowSidebar === true;
    },
    modules() {
      return getAvailableModules(this.getCurrentUser.roles, this.$route);
    },
    showModuleSwitch() {
      return MODULE_NONE !== this.$route.meta.module && this.modules.length > 0;
    },
    classes() {
      return {
        [`sidebar--narrow`]: this.isNarrow,
      };
    },
  },
};
</script>
