<template>
  <form @submit.prevent="validate"
        action="#"
        class="add-wishlist-form">
    <Icon name="bookmark"
          class="add-wishlist-form__icon"></Icon>
    <input type="text"
           :placeholder="$t(placeholder)"
           v-model="input"
           class="add-wishlist-form__input"
           :class="classes"
           v-on:input="removeErrorMessage"
    />
    <btn v-if="!hideSubmit"
         class="add-wishlist-form__btn"
         icon="plus"
         variant="decent"
         small-font>
      {{ $t('wishlists.add') }}
    </btn>
  </form>
</template>
<script>
import { mapActions } from 'vuex';

import Btn from '@/components/molecules/Btn';
import Icon from '@/components/atoms/Icon';

export default {
  name: 'AddWishlistForm',
  components: {
    Btn,
    Icon,
  },
  props: {
    cb: Function,
    hideSubmit: Boolean,
  },
  data() {
    return {
      input: '',
      placeholder: 'wishlists.newWishlist',
      inputError: false,
    };
  },
  methods: {
    ...mapActions('wishlists', ['createWishlist']),
    async validate() {
      if (this.input) {
        await this.handleCreateWishlist();
        if (this.cb) {
          await this.cb();
        }
        this.placeholder = 'wishlists.newWishlist';
      } else {
        this.inputError = true;
        this.placeholder = 'wishlists.enterName';
      }
    },
    async handleCreateWishlist() {
      await this.createWishlist(this.input);
      this.input = '';
    },
    removeErrorMessage() {
      this.inputError = false;
      this.placeholder = 'wishlists.newWishlist';
    },
  },
  computed: {
    classes() {
      return {
        ['add-wishlist-form__input--error']: this.inputError,
      };
    },
  },
};
</script>
