<template>
  <div class="download-state"
       :class="classes"></div>
</template>
<script>
import { mapState } from 'vuex';
import DB from '@/helpers/indexedDb';

export default {
  name: 'DownloadState',
  props: {
    value: Boolean,
    path: String,
  },
  data() {
    return {
      available: false,
    };
  },
  computed: {
    ...mapState('offline', ['queue']),
    classes() {
      return {
        ['download-state--current']: this.queue.length > 0 && this.queue[0] === this.path,
        ['download-state--done']: this.available && this.queue[0] !== this.path,
      };
    },
  },
  methods: {
    async checkAvailability() {
      const item = await this.localDatabase?.getEntry(this.path);
      this.available = item ? item : false;
      this.$emit('input', !(this.available && this.queue[0] !== this.path));
    },
  },
  watch: {
    queue: {
      handler() {
        this.checkAvailability();
      },
      deep: true,
    },
  },
  async mounted() {
    this.localDatabase = await DB('sales-app');
    this.$nextTick(async () => {
      await this.checkAvailability();
    });
  },
};
</script>
