<template>
  <span class="version">v{{ version }}</span>
</template>
<script>
import env from '@/helpers/env';

export default {
  name: 'Version',
  computed: {
    version() {
      return env('version');
    },
  },
};
</script>
