<template>
  <div class="dynamic-list">
    <div class="dynamic-list__head">
      <div class="dynamic-list__head-title">
        {{ $t('leads.title') }}
      </div>

      <div v-for="(selectedSetting, i) in itemsInPreview"
           :key="`selectedSetting${i}`"
           class="dynamic-list__head-settings">
        <input v-model="selectedSettings"
               :value="selectedSetting"
               type="checkbox"
               class="dynamic-list__popup-checkbox">
        <p class="dynamic-list__head-settings-label">{{ $t(selectedSetting) }}</p>
      </div>
      <icon @click.native="toggleActive"
            name="cog"
            class="dynamic-list__head-icon" />
      <div v-click-outside="closePopup"
           v-if="isActive"
           class="dynamic-list__popup">
        <div v-for="(setting, i) in listSettings"
             :key="`setting${i}`"
             class="dynamic-list__popup-setting">
          <input @change="settingChecked"
                 v-model="selectedSettings"
                 :value="setting"
                 type="checkbox"
                 class="dynamic-list__popup-checkbox"
                 :id="`setting${i}`">
          <label :for="`setting${i}`">
            {{ $t(setting) }}
          </label>
        </div>
      </div>
    </div>
    <dynamic-list-item v-for="(item, index) in items"
                       :key="`item${index}`"
                       :item="item"
                       :to="{ name: 'Product', params: {'productId': item.id} }">
      <template #info>
        <div v-for="(data, i) in displayedData[index]"
             :key="`data${i}`"
             class="dynamic-list-item__info-display">
          <!--                    Data in here has to be adapted for each use case-->
          <span v-if="data[0] && data[0].min">{{ data[0].min }}</span>
          <span v-if="data[0] && data[0].max && data[0].max !== data[0].min"> - {{ data[0].max }} </span>
          <span v-if="data[0] && data[0].default !== data[0].min">{{ data[0].default }} </span>
          <span v-if="data[0] && data[0].unit"> {{ data[0].unit }}</span>

        </div>
      </template>
      <template #icon>
        <icon v-if="showIcon"
              name="bookmark"
              @click.prevent.native="toggleModal(index)"></icon>
      </template>
    </dynamic-list-item>
  </div>
</template>
<script>
import Icon from '@/components/atoms/Icon';
import FormCheckboxes from '@/components/molecules/FormCheckboxes';
import DynamicListItem from '@/components/molecules/DynamicListItem';

export default {
  name: 'DynamicList',
  components: {
    FormCheckboxes,
    Icon,
    DynamicListItem,
  },
  props: {
    items: {
      type: Array,
    },
    listSettings: {
      type: Array,
    },
    showIcon: {
      type: Boolean,
    },
  },
  data() {
    return {
      // Checkboxes checked from beginning
      selectedSettings: ['productFinder.width', 'productFinder.height', 'productFinder.weight', 'productFinder.depth', 'productFinder.voltage'],
      itemsInPreview: this.selectedSettings,
      displayedData: [],
      isActive: false,
      justToggled: false,
    };
  },
  methods: {
    toggleActive() {
      this.isActive = !this.isActive;
      // Keeping second state because popup gets closed by closePopup()
      // when it just got opened on button click
      this.justToggled = true;
    },
    closePopup() {
      if (!this.justToggled) {
        this.isActive = false;
      }
      this.justToggled = false;
    },
    toggleModal(i) {
      this.$emit('toggleModal', i);
    },
    settingChecked() {
      this.displayedData = [];
      for (let i = 0; i < this.items.length; i++) {
        this.displayedData.push([]);

        for (let n = 0; n < this.selectedSettings.length; n++) {
          // Pushing data into displayedData-Array when its name equals whats being set by checkboxes
          // Needs to be adapted for used array structure
          this.displayedData[i].push(this.items[i].tech_data.filter(item => item.name === this.$i18n.t(this.selectedSettings.at(n))));
        }
      }
    },
  },
  computed: {},
  updated() {
    this.settingChecked();
    this.itemsInPreview = this.selectedSettings;
  },
  mounted() {
    this.settingChecked();
    this.itemsInPreview = this.selectedSettings;
  },
};
</script>
