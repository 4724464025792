import { createRouter, createWebHashHistory } from 'vue-router';
import Dashboard from '@/views/LeadAndSales/Dashboard.vue';
import Search from '@/views/LeadAndSales/Search';
import scrollToTop from '@/helpers/scrollToTop';

import ProductFinder from '@/views/LeadAndSales/ProductFinder';
import ProductCategory from '@/views/LeadAndSales/ProductCategory';
import Product from '@/views/LeadAndSales/Product';
import Leads from '@/views/LeadAndSales/Leads/Leads';
import LocallySavedLeads from '@/views/LeadAndSales/Leads/LocallySavedLeads';
import Wishlists from '@/views/LeadAndSales/Wishlists';
import Wishlist from '@/views/LeadAndSales/Wishlist';
import ClipTip from '@/views/LeadAndSales/ClipTip';
import { ROLE_LEAD, ROLE_MY_POLY, ROLE_SALES, ROLE_TRANSLATION_MANAGEMENT } from '@/helpers/roles';
import TranslationManager from '@/views/LeadAndSales/TranslationManager';
import MyDashboard from '@/views/MyPoly/Dashboard';
import MyLibrary from '@/views/MyPoly/Library';
import MyWishlist from '@/views/MyPoly/Wishlist';
import MyCategory from '@/views/MyPoly/Category';
import { MODULE_LEAD_AND_SALES, MODULE_MY_POLY, MODULE_NONE, MODULE_OTHERS } from '@/helpers/modules';
import Home from '@/views/Home';
import Settings from '@/views/Settings';
import auth from '@/store/modules/auth';

const defaultTitle = 'Poly-clip Sales App';
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      module: MODULE_NONE,
      name: 'sidebar.home',
      menu: false,
      requiresAuth: true,
      hideFooter: true,
      title: 'Poly-clip Sales App',
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      name: 'sidebar.settings',
      module: MODULE_NONE,
      menu: false,
      requiresAuth: true,
      hideFooter: true,
    },
  },
  {
    path: '/salesapp',
    name: 'SalesApp',
    redirect: { name: 'Dashboard' },
    meta: {
      icon: 'grip-horizontal',
      name: 'sidebar.sales',
      menu: true,
      module: MODULE_NONE,
      requiresAuth: true,
      requiresRole: ROLE_SALES,
      title: 'Sales App',
    },
  },
  {
    path: '/mypoly',
    name: 'MyPoly',
    redirect: { name: 'MyDashboard' },
    meta: {
      icon: 'grip-horizontal',
      name: 'sidebar.my',
      menu: true,
      module: MODULE_NONE,
      requiresAuth: true,
      requiresRole: ROLE_MY_POLY,
      title: 'Sales App',
    },
  },
  {
    path: '/others',
    name: 'others',
    redirect: { name: 'TranslationManager' },
    meta: {
      icon: 'grip-horizontal',
      name: 'sidebar.other',
      menu: true,
      module: MODULE_NONE,
      requiresAuth: true,
      requiresRole: ROLE_MY_POLY,
      title: 'Sales App',
    },
  },
  {
    path: '/salesapp/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      icon: 'grip-horizontal',
      name: 'sidebar.dashboard',
      menu: true,
      module: MODULE_LEAD_AND_SALES,
      requiresAuth: true,
      requiresRole: ROLE_SALES,
      title: 'Sales App',
    },
  },
  {
    path: '/salesapp/search/:query',
    name: 'Search',
    component: Search,
    props: true,
    meta: {
      name: 'sidebar.search',
      menu: false,
      module: MODULE_LEAD_AND_SALES,
      requiresAuth: true,
      requiresRole: ROLE_SALES,
      title: 'Sales App',
    },
  },
  {
    path: '/salesapp/product-finder',
    name: 'ProductFinder',
    component: ProductFinder,
    meta: {
      icon: 'sliders-h',
      name: 'sidebar.productFinder',
      menu: true,
      module: MODULE_LEAD_AND_SALES,
      requiresAuth: true,
      requiresRole: ROLE_SALES,
      title: 'Sales App',
    },
  },
  {
    path: '/salesapp/product-category/:categoryId',
    name: 'ProductCategory',
    component: ProductCategory,
    meta: {
      module: MODULE_LEAD_AND_SALES,
      requiresAuth: true,
      requiresRole: ROLE_SALES,
      backButton: true,
      title: 'Sales App',
    },
  },
  {
    path: '/salesapp/product/:productId',
    name: 'Product',
    component: Product,
    meta: {
      module: MODULE_LEAD_AND_SALES,
      requiresAuth: true,
      requiresRole: ROLE_SALES,
      backButton: true,
      title: 'Sales App',
    },
  },
  {
    path: '/salesapp/wishlists',
    name: 'Wishlists',
    component: Wishlists,
    meta: {
      icon: 'bookmark',
      name: 'sidebar.wishlists',
      menu: true,
      module: MODULE_LEAD_AND_SALES,
      requiresAuth: true,
      requiresRole: ROLE_SALES,
      title: 'Sales App',
    },
  },
  {
    path: '/salesapp/wishlists/:wishlistId',
    name: 'Wishlist',
    component: Wishlist,
    meta: {
      module: MODULE_LEAD_AND_SALES,
      requiresAuth: true,
      requiresRole: ROLE_SALES,
      backButton: true,
      title: 'Sales App',
    },
  },
  {
    path: '/salesapp/leads',
    name: 'Leads',
    component: Leads,
    meta: {
      icon: 'file-spreadsheet',
      name: 'sidebar.leads',
      menu: true,
      module: MODULE_LEAD_AND_SALES,
      requiresAuth: true,
      requiresRole: [ROLE_SALES, ROLE_LEAD],
      title: 'Sales App',
    },
  },
  {
    path: '/salesapp/leads/locally-saved',
    name: 'LocallySavedLeads',
    component: LocallySavedLeads,
    meta: {
      name: 'sidebar.savedLeads',
      module: MODULE_LEAD_AND_SALES,
      requiresAuth: true,
      requiresRole: [ROLE_SALES, ROLE_LEAD],
      backButton: true,
      title: 'Sales App',
    },
  },
  {
    path: '/salesapp/clip-tip',
    name: 'ClipTip',
    component: ClipTip,
    meta: {
      icon: 'clip-tip',
      name: 'sidebar.clip_tip',
      menu: true,
      module: MODULE_LEAD_AND_SALES,
      requiresAuth: true,
      requiresRole: ROLE_SALES,
      narrowSidebar: true,
      hideFooter: true,
      hideAppHeader: true,
      title: 'Sales App',
    },
  },
  // My Poly-clip
  {
    path: '/mypoly/dashboard',
    name: 'MyDashboard',
    component: MyDashboard,
    meta: {
      icon: 'grid',
      name: 'sidebar.dashboard',
      menu: true,
      module: MODULE_MY_POLY,
      requiresAuth: true,
      requiresRole: ROLE_MY_POLY,
      title: 'My Poly-clip',
    },
  },
  {
    path: '/mypoly/category/:categoryId',
    name: 'MyCategory',
    component: MyCategory,
    meta: {
      module: MODULE_MY_POLY,
      requiresAuth: true,
      requiresRole: ROLE_MY_POLY,
      backButton: true,
      title: 'My Poly-clip',
    },
  },
  {
    path: '/mypoly/library/:categoryId?',
    name: 'MyLibrary',
    component: MyLibrary,
    meta: {
      icon: 'books',
      name: 'sidebar.library',
      menu: true,
      module: MODULE_MY_POLY,
      requiresAuth: true,
      requiresRole: ROLE_MY_POLY,
      narrowSidebar: true,
      title: 'My Poly-clip',
    },
  },
  {
    path: '/mypoly/wishlist',
    name: 'MyWishlist',
    component: MyWishlist,
    meta: {
      icon: 'star',
      name: 'sidebar.wishlist',
      menu: true,
      module: MODULE_MY_POLY,
      requiresAuth: true,
      requiresRole: ROLE_MY_POLY,
      title: 'My Poly-clip',
    },
  },
  // Others
  {
    path: '/others/translation-manager',
    name: 'TranslationManager',
    component: TranslationManager,
    meta: {
      icon: 'language',
      name: 'sidebar.translation',
      menu: true,
      module: MODULE_OTHERS,
      requiresAuth: true,
      requiresRole: ROLE_TRANSLATION_MANAGEMENT,
      title: 'Translation Manager',
      hideFooter: true,
    },
  },
];

// maps "vue routes" to main navigation
export const getNavigation = (module, roles, authenticated) => {
  const checker = (arr, target) => target.every(v => arr.includes(v));
  const transform = (nav) => {
    const isMenu = nav?.meta?.menu;
    const requiresModule = nav?.meta?.module;
    const requiresRole = nav?.meta?.requiresRole;
    const requiresAuth = nav?.meta?.requiresAuth;

    // check for non-menu items
    if (!isMenu) {
      return;
    }

    // check for auth required routes
    if (requiresAuth && !authenticated) {
      return;
    }

    // check for roles
    if (typeof requiresRole === 'object' && !checker(roles, requiresRole)) {
      return;
    } else if (typeof requiresRole === 'string' && !roles.includes(requiresRole)) {
      return;
    }

    // check for module
    if (module && requiresModule && (module !== requiresModule)) {
      return;
    }

    return {
      name: nav.name,
      meta: nav.meta,
      path: nav.path,
    };
  };

  return routes.map(transform).filter(n => n);
};

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    window.vueRouterFrom = from;
    scrollToTop();
  },
});


router.afterEach((to) => {
  window.document.title = to.meta?.title || defaultTitle;
});

export default router;
