<template>
  <div class="list-item"
       :is="to ? 'router-link' : 'div'"
       :to="to">
    <div v-if="hasPrefixSlot"
         slot="prefix"
         class="list-item__prefix">
      <slot name="prefix"></slot>
    </div>
    <div v-if="hasSubactionSlot"
         class="list-item__subaction">
      <slot name="subaction"></slot>
    </div>
    <div class="list-item__data">
      <slot name="preline"></slot>
      <div class="subtitle"
           v-if="subtitle">{{ subtitle }}
      </div>
      <slot name="title"></slot>
    </div>
    <div v-if="$slots && $slots.actions"
         class="list-item__action">
      <slot name="actions"></slot>
    </div>
  </div>
</template>
<script>
import Icon from '@/components/atoms/Icon';
import Btn from '@/components/molecules/Btn';

export default {
  name: 'ListItem',
  props: {
    subtitle: String,
    to: {},
  },
  components: { Btn, Icon },
  computed: {
    hasPrefixSlot() {
      return !!this.$slots.prefix;
    },
    hasSubactionSlot() {
      return !!this.$slots.subaction;
    },
  },
};
</script>

