<template>
  <div class="navigation-menu"
       :class="classes">
    <div class="navigation-menu__heading">
      {{ title }}
    </div>
    <ul class="navigation-menu__menu"
        v-if="items.length > 0">
      <li v-for="item in items"
          :key="item.name"
          class="navigation-menu__item"
          :class="getActiveRoute(item)">
        <router-link class="navigation-menu__link"
                     :to="{name: item.name}">
          <icon :name="item.meta.icon"
                class="navigation-menu__icon" />
          <div class="navigation-menu__text">
            {{ $t(item.meta.name) }}
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Icon from '@/components/atoms/Icon';
import Underline from '@/components/atoms/Underline';

export default {
  name: 'NavigationMenu',
  components: {
    Icon,
    Underline,
  },
  props: {
    title: {},
    items: {},
    narrow: Boolean,
  },
  methods: {
    getActiveRoute(item) {
      if (this.$route.name && this.$route.name.toUpperCase() === item.name.toUpperCase()) {
        return ['navigation-menu__item--active'];
      }
    },
  },
  computed: {
    ...mapState('auth', ['authenticated', 'roles', 'first_name', 'last_name']),
    classes() {
      const module = [...new Set(this.items.map(item => item.meta.module))];
      return {
        ['navigation-menu--in-scope']: module[0] === this.$route.meta.module,
        ['navigation-menu--narrow']: this.narrow,
      };
    },
  },
};
</script>
