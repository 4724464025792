// dependencies
import { createStore } from 'vuex';
import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';

// modules
import auth from '@/store/modules/auth';
import leads from '@/store/modules/leads';
import offline from '@/store/modules/offline';
import products from '@/store/modules/products';
import productCategories from '@/store/modules/productCategories';
import wishlists from '@/store/modules/wishlists';
import mypoly from '@/store/modules/mypoly';
import mypolyWishlist from '@/store/modules/mypolyWishlist';
import { preloads } from '@/helpers/file';

// helpers
import env from '@/helpers/env';
import {
  transformTessaCategoriesAndProducts,
  transformTessaDownloads,
} from '@/helpers/api';
import { ROLE_LEAD, ROLE_MY_POLY, ROLE_SALES } from '@/helpers/roles';

export default createStore({
  namespaced: true,
  modules: {
    auth,
    leads,
    offline,
    products,
    productCategories,
    wishlists,
    mypoly,
    mypolyWishlist,
  },
  state: {
    online: true,
    locale: -1,
    loaders: [],
  },
  mutations: {
    SET_ONLINE(state, online) {
      state.online = online;
    },
    SET_LOCALE(state, locale) {
      state.locale = locale;
    },
    async SET_STATE(state, { key, value }) {
      state[key] = value;
    },
  },
  actions: {
    async startLoading({ state, commit }, loaderKey) {
      await commit('SET_STATE', { key: 'loaders', value: [...state.loaders, loaderKey] });
    },
    async endLoading({ state, commit }, loaderKey) {
      const loaders = state.loaders;
      const index = loaders.findIndex((item) => item === loaderKey);
      if (index >= 0) {
        loaders.splice(index, 1);
      }
      await commit('SET_STATE', { key: 'loaders', value: loaders });
    },
    async fetchTessaDownloadInformation({ dispatch, rootGetters }) {
      await dispatch('startLoading', 'fetchTessaDownloadInformation', { root: true });

      const apiEndpoint = `${env('api_tessa')}/media/asset?categories=${env('api_tessa_category')}&page_size=5000&searchterm={"external": [{"operator":"equal", "value":"1"}]}`;
      const apiKey = rootGetters['auth/getCurrentUser'].tessa_access_token;

      if (env('disable_requests') === 'false') {
        const response = await axios.get(apiEndpoint, {
          headers: {
            'X-Auth-Token': apiKey,
          },
        }).catch((e) => console.log(e));

        const transformedData = await transformTessaDownloads(response.data);

        await dispatch('offline/addDownloads', transformedData, { root: true });
      }

      await dispatch('endLoading', 'fetchTessaDownloadInformation', { root: true });
    },
    async fetchTessaCategoriesAndProducts({ dispatch, commit, rootGetters }) {
      await dispatch('startLoading', 'fetchTessaCategoriesAndProducts', { root: true });

      const apiEndpoint = `${env('api_tessa')}/categories/category?page_size=5000&searchterm={"status":[{"operator":"equal","value":"1"}]}`;
      const apiKey = rootGetters['auth/getCurrentUser'].tessa_access_token;

      if (env('disable_requests') === 'false') {
        const response = await axios.get(apiEndpoint, {
          headers: {
            'X-Auth-Token': apiKey,
          },
        }).catch((e) => console.log(e));

        const transformedData = transformTessaCategoriesAndProducts(response.data);

        await commit('productCategories/SET', {
          key: 'items',
          value: transformedData.categories,
        }, { root: true });

        await dispatch('products/addProductInformation', transformedData.products, { root: true });
        await dispatch('products/cleanupItems', transformedData.products, { root: true });
      }
      await dispatch('endLoading', 'fetchTessaCategoriesAndProducts', { root: true });
    },
    async bootstrap({ state, commit, dispatch, rootGetters }, background) {
      const currentUser = rootGetters['auth/getCurrentUser'];

      if (!state.online) {
        return false;
      }

      if (!background) await dispatch('startLoading', 'bootstrap', { root: true });

      // sales
      if (currentUser.roles.includes(ROLE_SALES)) {
        await dispatch('fetchTessaDownloadInformation', null, { root: true });
        await dispatch('fetchTessaCategoriesAndProducts', null, { root: true });
      }

      // sales & leads app
      if (currentUser.roles.includes(ROLE_SALES) || currentUser.roles.includes(ROLE_LEAD)) {
        await dispatch('products/fetchDrupalProducts', null, { root: true });
      }

      await dispatch('offline/cleanUpOfflineUploads', null, { root: true });

      if (!background) await dispatch('endLoading', 'bootstrap', { root: true });

      // mypoly
      if (currentUser.roles.includes(ROLE_MY_POLY)) {
        await dispatch('mypoly/fetchData', null, { root: true });
      }
    },
    setOnline({ commit }, online) {
      commit('SET_ONLINE', online);
    },
    setLocale({ commit }, locale) {
      commit('SET_LOCALE', locale);
    },
  },
  getters: {
    isLoading(state) {
      return (loaderKeys) => {
        if (typeof loaderKeys === 'string') {
          loaderKeys = [loaderKeys];
        }
        return state.loaders.some((key) => loaderKeys.includes(key));
      };
    },
  },
  plugins: [createPersistedState({
    paths: [
      'locale',
      'online',
      'auth',
      'leads',
      'offline',
      'products',
      'productCategories',
      'wishlists',
      'mypolyWishlist',
    ],
  })],
});
