export default {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    SET(state, { key, value }) {
      state[key] = value;
    },
    UPDATE_ITEM(state, category) {
      const objIndex = state.items.findIndex((item => item.id === category.id));
      state.items[objIndex] = category;
    },
  },
  getters: {
    getAllItems(state) {
      const items = state.items;
      return items.sort(function (a, b) {
        return a.sort - b.sort;
      });
    },
    getHighlightedItems(state) {
      const filtered = state.items.filter(item => item.highlighted);
      return filtered.sort(function (a, b) {
        return a.sort - b.sort;
      });
    },
    getItems(state) {
      const filtered = state.items.filter(item => !item.highlighted);
      return filtered.sort(function (a, b) {
        return a.sort - b.sort;
      });
    },
    getItemById: state => id => {
      return state.items.filter(item => item.id === id)[0];
    },
    getCategoriesByParentId: (state) => (id) => {
      const filtered = state.items.filter(item => item.parent_id === id && !item.highlighted);
      return filtered.sort(function (a, b) {
        return a.sort - b.sort;
      });
    },
  },
};
