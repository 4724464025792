<template>
  <div class="icon"
       :class="classes"></div>
</template>
<script>
const availableVariants = ['main', 'grey'];

export default {
  name: 'Icon',
  props: {
    name: String,
    strong: Boolean,
    variant: String,
  },
  computed: {
    classes() {
      return {
        [`icon--${this.name}`]: !!this.name,
        [`icon--${this.variant}`]: !!this.variant && availableVariants.includes(this.variant),
        [`icon--strong`]: this.strong,
      };
    },
  },
};
</script>
