<template>
  <div v-if="category">
    <div class="container">
      <page-title :headline="category.title" />

      <teasers-grid v-if="category.children"
                    :items="category.children"
                    to="MyLibrary"
                    :subtitles="false"
                    param="categoryId"
                    class="m-top-large" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TeasersGrid from '@/components/TeasersGrid';
import PageTitle from '@/components/molecules/PageTitle';
import ProductTabs from '@/components/molecules/ProductTabs';

export default {
  name: 'ProductCategory',
  components: {
    TeasersGrid,
    PageTitle,
    ProductTabs,
  },
  computed: {
    ...mapGetters('mypoly', ['getCategoryById']),
    categoryId() {
      return Number(this?.$route?.params?.categoryId);
    },
    category() {
      return this.getCategoryById(this.categoryId);
    },
  },
};
</script>
