<template>
  <div class="settings-option">
    <h3 class="settings-option__title"
        v-if="title"
        v-html="title"></h3>
    <p class="settings-option__description"
       v-if="description"
       v-html="description"></p>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
  },
};
</script>
