<template>
  <div class="add-to-wishlist"
       @click.prevent="toggleWishlistItem(item.id)">
    <icon name="star"
          :variant="variant"
          :strong="isOnWishlist"></icon>
    <p v-if="hasLabel">
      {{ $t('productFinder.addWishlist') }}
    </p>
  </div>
</template>
<script>
import Icon from '@/components/atoms/Icon';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AddToWishlist',
  props: {
    item: Object,
    hasLabel: Boolean,
  },
  components: {
    Icon,
  },
  methods: {
    ...mapActions('mypolyWishlist', [
      'toggleWishlistItem',
    ]),
  },
  computed: {
    ...mapState('mypolyWishlist', ['wishlistItems']),
    variant() {
      if (!!this.isOnWishlist) {
        return 'main';
      } else {
        return '';
      }
    },
    isOnWishlist() {
      return !!this.wishlistItems.find(id => id === this.item.id);
    },
  },
};
</script>
