<template>
  <form class="file-search"
        @submit.prevent="formHandler">
    <div class="file-search__row">
      <form-input v-model="form.machine"
                  class="detail-search__form-item"
                  type="text"
                  :label="$t('myPolyclip.machine')" />

      <form-input v-model="form.machineNr"
                  class="detail-search__form-item"
                  type="text"
                  :label="$t('myPolyclip.machineNumber')" />

      <form-select v-model="form.language"
                   multi-language-fields
                   :options="getLanguagesForSelect"
                   class="detail-search__form-item"
                   :label="$t('myPolyclip.language')"></form-select>
    </div>

    <div class="file-search__row">
      <form-input v-model="form.freetext"
                  class="freetext-search__form-item"
                  :label="$t('myPolyclip.searchTerm')"
                  type="search"
                  name="search"
                  :placeholder="$t('myPolyclip.giveSearchTerm')" />
    </div>

    <div class="file-search__row">
      <btn icon="search"
           variant="highlighted"
           class="detail-search__form-button">
        {{ $t('myPolyclip.search') }}
      </btn>
    </div>
  </form>
</template>
<script>
import FormInput from '@/components/molecules/FormInput';
import FormSelect from '@/components/molecules/FormSelect';
import Btn from '@/components/molecules/Btn';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'FileSearch',
  components: { Btn, FormSelect, FormInput },
  data() {
    return {
      form: {
        machine: '',
        machineNr: '',
        language: '',
        freetext: '',
      },
    };
  },
  computed: {
    ...mapGetters('mypoly', [
      'getLanguagesForSelect',
    ]),
    ...mapState('mypoly', [
      'filterMachine',
      'filterMachineNr',
      'filterLanguage',
      'filterText',
    ]),
  },
  methods: {
    ...mapActions('mypoly', ['setFilters']),
    formHandler() {
      this.setFilters(this.form);
    },
    updateForm() {
      this.form.machine = this.filterMachine;
      this.form.machineNr = this.filterMachineNr;
      this.form.language = Number(this.filterLanguage);
      this.form.freetext = this.filterText;
    },
  },
  watch: {
    filterMachine() {
      this.form.machine = this.filterMachine;
    },
    filterMachineNr() {
      this.form.machineNr = this.filterMachineNr;
    },
    filterLanguage() {
      this.form.language = this.filterLanguage;
    },
    filterText() {
      this.form.freetext = this.filterText;
    },
  },
  mounted() {
    this.updateForm();
  },
};
</script>
