<template>
  <div class="form-text-area">
    <form-label big
                :reference="`form-text-area${uid}`">
      {{ label }}
      <span v-if="mandatory">*</span>
    </form-label>
    <textarea @input="handle"
              :maxlength="maxlength"
              :placeholder="placeholder"
              :disabled="disabled"
              :id="`form-text-area${uid}`"
              :readonly="readOnly">{{modelValue}}</textarea>
  </div>
</template>
<script>
import FormLabel from '@/components/atoms/FormLabel';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'FormTextArea',
  emits: ['update:modelValue'],
  props: {
    modelValue: {},
    label: {},
    placeholder: {},
    maxlength: {},
    mandatory: Boolean,
    readOnly: Boolean,
    disabled: Boolean,
  },
  components: {
    FormLabel,
  },
  methods: {
    handle(e) {
      this.$emit('update:modelValue', e.target.value);
    },
  },
  computed: {
    uid() {
      return uuidv4();
    },
  },
};
</script>
