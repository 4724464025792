import { createStore } from 'vuex';
import env from '@/helpers/env';
import axios from 'axios';

import qs from 'qs';
import { ROLE_LEAD, ROLE_SALES } from '@/helpers/roles';

export default {
  namespaced: true,
  state: {
    authenticated: false,
    id: 0,
    email: '',
    first_name: '',
    last_name: '',
    login_date: 0,
    expires_in: 0,
    keycloak_access_token: '',
    tessa_access_token: '',
    refresh_token: '',
    roles: [],
    myPolyModalShown: false,
  },
  mutations: {
    SET(state, { key, value }) {
      state[key] = value;
    },
    ADD_ROLE(state, role) {
      state.roles.push(role);
    },
  },
  actions: {
    setAuth({ commit }, auth) {
      auth = !!auth;
      commit('SET', { key: 'authenticated', value: auth });
    },
    async isStillAuthenticated({ state }) {
      let authenticated = true;

      // is not logged in
      if (state.authenticated === false) {
        authenticated = false;
      }

      // check expires
      if (+new Date() > state.login_date + (state.expires_in * 60000)) {
        authenticated = false;
      }

      return authenticated;
    },
    async setMyPolyModalShown({ commit }, isShown) {
      commit('SET', { key: 'myPolyModalShown', value: isShown });
    },
    async logoutUser({ commit }) {
      await commit('SET', { key: 'authenticated', value: false });
    },
    async refreshProfile({ state, dispatch, commit }) {
      if (env('disable_login') === 'true') {
        commit('SET', { key: 'id', value: 0 });
        commit('SET', { key: 'first_name', value: 'Max' });
        commit('SET', { key: 'last_name', value: 'Mustermann' });
        commit('SET', { key: 'email', value: 'max@mustermann.de' });
        commit('SET', { key: 'authenticated', value: true });
        return true;
      }
      // prepare request
      const host = env('keycloak_host');
      const realm = env('keycloak_realm');
      const infoEndpoint = `${host}/realms/${realm}/protocol/openid-connect/userinfo`;

      // fetch users data
      const info = {
        method: 'get',
        url: infoEndpoint,
        headers: {
          'Authorization': `bearer ${state.keycloak_access_token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };

      const infoResponse = await axios(info).catch((e) => console.log(e));

      if (!infoResponse) {
        return false;
      }

      const userId = infoResponse?.data?.sub;
      const firstName = infoResponse?.data?.given_name;
      const lastName = infoResponse?.data?.family_name;
      const email = infoResponse?.data?.email;

      const user = {
        id: userId,
        email: email,
        first_name: firstName,
        last_name: lastName,
      };

      if (state.id !== userId) {
        await dispatch('offline/flush', null, { root: true });
      }

      commit('SET', { key: 'id', value: user.id });
      commit('SET', { key: 'first_name', value: user.first_name });
      commit('SET', { key: 'last_name', value: user.last_name });
      commit('SET', { key: 'email', value: user.email });

      return true;

    },
    async refreshAuth({ state, commit, dispatch }) {
      if (env('disable_login') === 'false') {
        commit('SET', { key: 'authenticated', value: true });
      }

      dispatch('startLoading', 'refresh-auth', { root: true });

      // prepare request
      const host = env('keycloak_host');
      const realm = env('keycloak_realm');
      const clientId = env('keycloak_client_id');
      const authEndpoint = `${host}/realms/${realm}/protocol/openid-connect/token`;

      // auth request
      const auth = {
        method: 'post',
        url: authEndpoint,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: qs.stringify({
          'client_id': clientId,
          'grant_type': 'refresh_token',
          'refresh_token': state.refresh_token,
        }),
      };

      const authResponse = await axios(auth).catch((e) => console.log(e));
      if (authResponse === undefined) {
        dispatch('endLoading', 'refresh-auth', { root: true });
        dispatch('logoutUser');
      }

      // get token and expires_in value
      const accessToken = authResponse?.data?.access_token;
      const refreshToken = authResponse?.data?.refresh_token;
      const expiresIn = authResponse?.data?.expires_in;

      const user = {
        keycloak_access_token: accessToken,
        refresh_token: refreshToken,
        expires_in: expiresIn,
        login_date: +new Date(),
      };

      commit('SET', { key: 'keycloak_access_token', value: user.keycloak_access_token });
      commit('SET', { key: 'refresh_token', value: user.refresh_token });
      commit('SET', { key: 'expires_in', value: user.expires_in });
      commit('SET', { key: 'login_date', value: user.login_date });
      commit('SET', { key: 'authenticated', value: true });

      dispatch('endLoading', 'refresh-auth', { root: true });
    },
    async loginUser({ commit, dispatch, rootGetters }, form) {
      if (env('disable_login') === 'true') {
        commit('SET', { key: 'authenticated', value: true });
        commit('ADD_ROLE', ROLE_SALES);
        commit('ADD_ROLE', ROLE_LEAD);
        await dispatch('auth/refreshProfile', null, { root: true });
        await dispatch('bootstrap', null, { root: true });
        await dispatch('offline/prepareQueue', null, { root: true });
        await dispatch('offline/endPause', null, { root: true });
        await dispatch('offline/startSync', null, { root: true });
      }

      dispatch('startLoading', 'login', { root: true });

      // prepare requests
      const host = env('keycloak_host');
      const realm = env('keycloak_realm');
      const clientId = env('keycloak_client_id');
      const authEndpoint = `${host}/realms/${realm}/protocol/openid-connect/token`;

      // auth request
      const auth = {
        method: 'post',
        url: authEndpoint,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: qs.stringify({
          'client_id': clientId,
          'username': form.name,
          'password': form.password,
          'grant_type': 'password',
        }),
      };

      const authResponse = await axios(auth).catch((e) => {
        return { status: e?.response?.status ?? 401, data: e?.response?.data };
      });

      // check for errors
      if (authResponse?.status === 400 || authResponse?.status === 401) {
        // map keycloak error description to translation key
        const loginErrorTranslations = {
          'Account is not fully set up': 'password_expired',
          'Account disabled': 'account_disabled',
          'Invalid user credentials': 'invalid_credentials',
        };

        if (authResponse.data) {
          authResponse.data.errorCode = loginErrorTranslations[authResponse?.data?.error_description] ?? 'unexpected';
        }
        dispatch('endLoading', 'login', { root: true });
        return authResponse?.data;
      }

      // get token and expires_in value
      const accessToken = authResponse?.data?.access_token;
      const refreshToken = authResponse?.data?.refresh_token;
      const expiresIn = authResponse?.data?.expires_in;

      const user = {
        keycloak_access_token: accessToken,
        refresh_token: refreshToken,
        expires_in: expiresIn,
        login_date: +new Date(),
      };

      // retrieve and set roles
      const info = accessToken.split('.')[1];
      const convertedInfo = info.replace('-', '+');
      const convertedInfo2 = convertedInfo.replace('_', '/');
      const infoB64Decoded = Buffer.from(convertedInfo2, 'base64');
      const parsedInfo = JSON.parse(infoB64Decoded);
      const roles = parsedInfo.resource_access?.polyclip_sales_app?.roles || [];

      commit('SET', { key: 'roles', value: [] });
      roles.forEach((role) => {
        commit('ADD_ROLE', role);
      });

      const apiEndpoint = `${env('api_tessa')}/users/user/login`;
      const apiKey = env('api_tessa_key');

      const response = await axios.post(apiEndpoint, { id: form.name, password: form.password }, {
        headers: {
          'X-Auth-Token': apiKey,
        },
      }).catch((e) => {
        return { status: e?.response?.status ?? 401, data: e?.response?.data };
      });

      // handle DAM login error
      if (response.status === 401) {
        commit('SET', { key: 'roles', value: [] });
        dispatch('endLoading', 'login', { root: true });

        response.data.errorCode = 'tessa_login_failed';

        return response?.data;
      }

      const token = response?.data?.data?.key;

      commit('SET', { key: 'keycloak_access_token', value: user.keycloak_access_token });
      commit('SET', { key: 'tessa_access_token', value: token });
      commit('SET', { key: 'refresh_token', value: user.refresh_token });
      commit('SET', { key: 'expires_in', value: user.expires_in });
      commit('SET', { key: 'login_date', value: user.login_date });
      commit('SET', { key: 'authenticated', value: true });
      commit('SET', { key: 'myPolyModalShown', value: false });

      await dispatch('offline/endPause', null, { root: true });
      await dispatch('endLoading', 'login', { root: true });

      await dispatch('auth/refreshProfile', null, { root: true });
      await dispatch('bootstrap', null, { root: true });
      await dispatch('offline/prepareQueue', null, { root: true });
      await dispatch('offline/startSync', null, { root: true });

      return true;
    },
  },
  getters: {
    getCurrentUser(state) {
      return {
        id: state.id,
        first_name: state.first_name,
        last_name: state.last_name,
        email: state.email,
        authenticated: state.authenticated,
        roles: state.roles,
        tessa_access_token: state.tessa_access_token,
      };
    },
    getPasswordChangeURL() {
      const host = env('keycloak_host');
      const realm = env('keycloak_realm');
      return `${host}/realms/${realm}/account/?/security/signingin#/security/signingin`;
    },
    getForgotPasswordURL(state, getters, rootState) {
      const locale = rootState.locale === -1 ? 'de' : rootState.locale;
      const host = env('keycloak_host');
      const realm = env('keycloak_realm');
      return `${host}/realms/${realm}/login-actions/reset-credentials?client_id=account?client_id=account&kc_locale=${locale}&ui_locales=${locale}`;
    },
  },
};
