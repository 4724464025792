<template>
  <div class="tabs">
    <div class="tab__navigation-items">
      <template v-for="(tab, index) in items"
                :key="`link_${$t(tab.name)}`">
        <div class="tab__navigation-item"
             :class="navigationItemClasses(index)"
             @click.prevent="active = index">
          {{ $t(tab.name) }}
        </div>
      </template>
    </div>
    <template v-for="(tab, index) in items"
              :key="`component_${$t(tab.name)}`">
      <div class="tab__item"
           :class="itemClasses(index)"
           v-if="index === active">
        <component :is="tab.component" />
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'Tabs',

  data() {
    return {
      active: 0,
    };
  },

  props: {
    items: {
      type: Array,
      default: [],
    },
  },

  methods: {
    navigationItemClasses(index) {
      return {
        ['tab__navigation-item--active']: this.active === index,
      };
    },
    itemClasses(index) {
      return {
        ['tab__item--active']: this.active === index,
      };
    },
  },


};
</script>
