<template>
  <li class="tab-card__list-item">
    <div class="tab-card__list-item-name">
      <span>{{ getDownloadFileExtension(download.name) }}</span>
      <h4>{{ download.name }}</h4>
    </div>
    <download-state :path="download.src"
                    v-model="disabled" />
    <btn v-if="!download.name.includes('.mp4')"
         variant="light"
         :href="downloadSrc"
         :disabled="disabled"
         target="_blank"
         :download="download.name">
      {{ $t('productFinder.show') }}
    </btn>
    <btn v-else
         @click.native.prevent="modal = true"
         variant="light"
         :disabled="disabled">
      {{ $t('productFinder.show') }}
    </btn>
    <modal v-model="modal"
           :title="download.name">
      <template #body>
        <video v-if="downloadSrc"
               class="download__video"
               controls>
          <source :src="downloadSrc"
                  type="video/mp4">
          Your browser does not support HTML video.
        </video>
      </template>
    </modal>
  </li>
</template>
<script>
import DownloadState from '@/components/atoms/DownloadState';
import Btn from '@/components/molecules/Btn';
import DB from '@/helpers/indexedDb';
import Modal from '@/components/organisms/Modal';

export default {
  name: 'Download',
  components: {
    DownloadState,
    Btn,
    Modal,
  },
  props: {
    download: Object,
  },
  data() {
    return {
      downloadSrc: '',
      disabled: true,
      modal: false,
    };
  },
  methods: {
    getDownloadFileExtension(downloadName) {
      const sections = downloadName.split('.');
      return sections[sections.length - 1];
    },
    async loadDownload() {
      if (!this.download.src) {
        return false;
      }
      const item = await this.localDatabase?.getEntry(this.download.src);
      if (!item) {
        return false;
      }
      this.disabled = false;
      this.downloadSrc = URL.createObjectURL(item.blob);
    },
  },
  async mounted() {
    this.$nextTick(async () => {
      this.localDatabase = await DB('sales-app');
      await this.loadDownload();
    });
  },
  watch: {
    async download() {
      await this.loadDownload();
    },
  },
};
</script>
