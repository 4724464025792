<template>
  <div>
    <div v-if="wishlist"
         class="container">
      <page-title :headline="wishlist.name"
                  btnRight
                  underneath
                  variant="wishlist">
        <btn v-if="currentUser.roles.includes(ROLE_LEAD)"
             @click.native="copyToLead"
             variant="highlighted"
             icon="file-spreadsheet"
             full-width
             :disabled="!selectedProducts.length"
        >
          {{ $t('wishlists.leadTransfer') }}
        </btn>
      </page-title>
      <div v-if="currentUser.roles.includes(ROLE_LEAD) && products && products.length"
           class="bulk-select m-bottom-small">
        <input type="checkbox"
               id="selectAll"
               class="m-right-very-small"
               :checked="selectedProducts.length === wishlist.items.length"
               @click="selectedProducts = selectedProducts.length === wishlist.items.length ? [] : wishlist.items">
        <form-label reference="selectAll"> {{ $t('wishlists.transferAll') }}</form-label>
      </div>

      <div v-if="products.length <= 0">
        <p class="m-bottom-small"> {{ $t('wishlists.noProducts') }}</p>
        <btn variant="highlighted"
             :to="{ name: 'ProductFinder' }"> {{ $t('wishlists.toFinder') }}
        </btn>
      </div>

      <card is-bordered
            v-else>
        <list>
          <list-item v-for="(product, key) in products"
                     :key="product.id">
            <template #prefix>
              <input v-if="currentUser.roles.includes(ROLE_LEAD)"
                     :value="product.id"
                     type="checkbox"
                     v-model="selectedProducts">
            </template>
            <template #subaction>
              <router-link :to="{ name: 'Product', params: {productId: product.id} }">
                <lazy-img variant="wishlist"
                          :src="product.image"
                          :alt="product.title"></lazy-img>
              </router-link>
            </template>
            <template #title>
              {{ product.title }}
              <btn class="list-item__trash"
                   icon="trash-alt"
                   variant="decent"
                   @click.native="openDeleteModal(key)"
              >
                {{ $t('wishlists.delete') }}
              </btn>
            </template>
            <template #actions>
              <btn :to="{ name: 'Product', params: {productId: product.id} }"
                   variant="light"
              >
                {{ $t('wishlists.toDetail') }}
              </btn>
            </template>
          </list-item>
        </list>
      </card>
      <!-- Delete from wishlist modal -->
      <modal v-model="modal"
             :title="`${currentProduct.title} ${$t('wishlists.from')} ${wishlist.name} ${$t('wishlists.deleteSmall')}`">
        <template #body>
          <page-title variant="paragraph"
                      :headline="`${$t('wishlists.deleteProduct')} <span>${currentProduct.title}</span> ${$t('wishlists.reallyFrom')} <span>${wishlist.name}</span> ${$t('wishlists.deletedelete')}`"
                      btn-right
                      :back-btn="false">
            <lazy-img class="m-top-big"
                      variant="modal"
                      :src="currentProduct.image"
                      :alt="currentProduct.title" />
          </page-title>
        </template>
        <template #actions>
          <btn @click.native="modal = false"
               variant="muted"
               half-width
          >
            {{ $t('wishlists.cancel') }}
          </btn>
          <btn variant="highlighted"
               @click.native="handleDelete"
               half-width
          >
            {{ $t('wishlists.delete') }}
          </btn>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Btn from '@/components/molecules/Btn';
import Card from '@/components/atoms/Card';
import FormLabel from '@/components/atoms/FormLabel';
import LazyImg from '@/components/molecules/LazyImg';
import List from '@/components/molecules/List';
import ListItem from '@/components/molecules/ListItem';
import Modal from '@/components/organisms/Modal';
import PageTitle from '@/components/molecules/PageTitle';
import { ROLE_LEAD } from '@/helpers/roles';

export default {
  name: 'Wishlist',
  components: {
    Btn,
    Card,
    FormLabel,
    LazyImg,
    List,
    ListItem,
    Modal,
    PageTitle,
  },
  data() {
    return {
      currentProduct: {}, // for delete modal
      selectedProducts: [], // for leads generation
      modal: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUser']),
    ...mapGetters('wishlists', ['getListById']),
    ...mapGetters('products', ['getProductById']),
    wishlistId() {
      return this?.$route?.params?.wishlistId;
    },
    wishlist() {
      return this.wishlistId ? this.getListById(Number(this.wishlistId)) : null;
    },
    products() {
      return this.wishlist ? this.wishlist.items.map(item => this.getProductById(item)) : [];
    },
    currentUser() {
      return this.getCurrentUser;
    },
    ROLE_LEAD() {
      return ROLE_LEAD;
    },
  },
  methods: {
    ...mapActions('wishlists', ['removeItemFromWishlist']),
    ...mapActions('leads', ['prefillLead']),
    openDeleteModal(key) {
      this.currentProduct = this.products[key];
      this.modal = true;
    },
    handleDelete() {
      if (this.currentProduct.id) {
        this.removeItemFromWishlist({ wishlistId: this.wishlistId, productId: this.currentProduct.id });
        this.modal = false;
        this.currentProduct = {};
      }
    },
    // Redirect to '/leads' and prefill machines key with wishlist items.
    copyToLead() {
      this.prefillLead({ machines: this.selectedProducts });
      this.$router.push({ name: 'Leads' });
    },
  },
};
</script>
