<template>
  <div class="card"
       :class="classes">
    <div class="card__content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Card',
  props: {
    isBordered: Boolean,
    isShadowed: Boolean,
    angle: Boolean,
    angleLeft: Boolean,
    isSpaceless: Boolean,
    paddingXSmall: Boolean,
    hScroll: Boolean,
    bottomSpacer: Boolean,
  },
  computed: {
    classes() {
      return {
        [`card--is-bordered`]: this.isBordered,
        [`card--is-shadowed`]: this.isShadowed,
        ['card--h-scroll']: this.hScroll,
        ['card--angle']: this.angle,
        ['card--angle-left']: this.angleLeft,
        [`card--is-spaceless`]: !!this.isSpaceless,
        [`card--padding-x-small`]: this.paddingXSmall,
        [`card--bottom-spacer`]: this.bottomSpacer,
      };
    },
  },
};
</script>

