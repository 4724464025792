<template>
  <div class="head-section">
    <div class="head-section__background js-parallax"
         data-mode="background"
         data-opacity="0.25, 0.25"
         data-auto="ttb"
         data-y="0, 100"
         :class="classes"></div>
    <div class="container">
      <slot>

      </slot>
    </div>
  </div>
</template>
<script>
import TrioAssets from 'trio-assets-js';

export default {
  name: 'HeadSection',
  props: {
    img: String,
  },
  mounted() {
    TrioAssets.parallax();
  },
  beforeDestroy() {
    TrioAssets.destroyParallax();
  },
  computed: {
    classes() {
      return {
        [`head-section__background--${this.img}`]: !!this.img,
      };
    },
  },
};
</script>
