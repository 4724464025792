<template>
  <div class="filters">
    <div class="filter__head">
      <h4 class="filter__label">Filter</h4>
      <!-- Show reset only if there's any active industries or side filters -->
      <span class="filter__reset"
            v-if="industry || Object.values(sideFilters).filter(Boolean).length"
            @click="resetAll()">
                               {{ $t('myPolyclip.reset') }}
                        </span>
    </div>
    <div class="filter__settings">
      <accordion v-if="activeIndustry"
                 :label="`${$t('productFinder.optionsFor')} ${activeIndustry.name}`">
        <!-- Subindustries (if there's only 1 group show it directly) -->
        <form-radios v-if="activeIndustry.items"
                     :name="activeIndustry.machine_name"
                     :options="activeIndustry.items.length === 1
                                         ? activeIndustry.items[0].items
                                         : activeIndustry.items"
                     reset-option
                     :value="subindustry"
                     v-on:update:value="subindustry = $event"
                     dark />
      </accordion>
      <!-- Applications -->
      <accordion v-if="activeSubindustry && activeSubindustry.items"
                 :label="`${$t('productFinder.optionsFor')} ${activeSubindustry.name}`">
        <form-radios v-if="activeSubindustry.items"
                     :name="activeSubindustry.machine_name"
                     :options="activeSubindustry.items"
                     reset-option
                     :value="application"
                     v-on:update:value="application = $event"
                     dark />
      </accordion>
      <!-- Constant side filters -->
      <accordion v-for="filter in filters?.side_filters"
                 :key="filter.vid"
                 :label="filter.name">
        <form-radios
            :name="filter.vid"
            :options="filter.items"
            reset-option
            :value="sideFilters[filter.vid]"
            v-on:update:value="sideFilters[filter.vid] = $event"
            dark />
      </accordion>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Accordion from '@/components/molecules/Accordion';
import FormRadios from '@/components/molecules/FormRadios';


export default {
  name: 'Filters',
  components: {
    Accordion,
    FormRadios,
  },
  computed: {
    ...mapState('products', ['deFilters', 'enFilters', 'activeIndustry', 'activeSubindustry', 'activeApplication', 'activeFilters']),
    ...mapGetters('products', ['getProducts']),
    filters() {
      return this[`${this.$i18n.locale}Filters`];
    },
    industry: {
      get() {
        return this.activeIndustry;
      },

      set(value) {
        this.setIndustry(value);
      },
    },
    subindustry: {
      get() {
        return this.activeSubindustry;
      },

      set(itemId) {
        // Some industries have only 1 subindustry so its items are rendered
        // directly to avoid redundant wrappers (for layout reasons).
        const itemsPool = this.activeIndustry.items.length === 1
            ? this.activeIndustry.items[0].items
            : this.activeIndustry.items;
        const item = itemId ? itemsPool.find(({ id }) => id === itemId) : null;
        this.setSubindustry(item);
      },
    },
    application: {
      get() {
        return this.activeApplication;
      },

      set(itemId) {
        const item = itemId ? this.activeSubindustry.items.find(({ id }) => id === itemId) : null;
        this.setApplication(item);
      },
    },
    sideFilters: {
      get() {
        return this.activeFilters;
      },

      set(value) {
        this.setFilters(value);
      },
    },
  },
  methods: {
    ...mapActions('products', ['setIndustry', 'setSubindustry', 'setApplication', 'setFilters', 'resetAll']),
  },
};

</script>
