<template>
  <div class="data-table__accordion">
    <div class="data-table__item">
      <div v-for="head in headers"
           class="data-table__col"
           @click.prevent="isOpen = !isOpen"
           :class="{ [`data-table__col--${head.field}`]: head.field }">
        <template v-if="typeof item[head.field] === 'object'">
          {{ item[head.field].join(', ') }}
        </template>
        <template v-else>
          <template v-if="head.field === 'name'">
            <div v-if="withPreview"
                 class="data-table__img">
              <img :src="item.preview"
                   :alt="item.name">
            </div>
            <div>
              <div class="data-table__item-name">
                <div class="data-table__badge"
                     :class="{ 'data-table__badge--filled': getReferencedDerivate(item.categories).length > 0 }">
                  {{ getReferencedDerivate(item.categories).length }}
                </div>
                {{ item[head.field] }}
              </div>
            </div>
          </template>
        </template>
      </div>
      <div class="data-table__col data-table__col--actions">
        <div class="data-table__desktop">
          <slot :item="item"></slot>

          <div @click.prevent="isOpen = !isOpen"
               class="data-table__accordion-action"
               :class="{ 'data-table__accordion-action--open': isOpen }"></div>
        </div>
        <div class="data-table__mobile">
          <icon name="ellipsis-h"
                @click.native="openMobileMenu(item)" />
          <div @click.prevent="isOpen = !isOpen"
               class="data-table__accordion-action"
               :class="{ 'data-table__accordion-action--open': isOpen }"></div>
        </div>
      </div>
    </div>
    <div class="data-table__item data-table__item--inner"
         :class="{ 'data-table__item--inner-with-preview': withPreview }"
         v-show="isOpen"
         v-for="d in getReferencedDerivate(item.categories)">
      <div class="data-table__col data-table__col--no-img">
        {{ d }}
      </div>
      <div class="data-table__col data-table__col--actions">
        <download :name="cdnName(d)"
                  :link="cdnDerivatLink(d)" />
      </div>
    </div>
  </div>
</template>
<script>
import Download from '@/components/atoms/Download';
import Icon from '@/components/atoms/Icon';
import env from '@/helpers/env';

export default {
  name: 'DataTableAccordion',
  components: { Icon, Download },
  props: {
    headers: {},
    item: {},
    getReferencedDerivate: {},
    getReferencedLanguages: {},
    openMobileMenu: {},
    withPreview: {},
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    cdnDerivatLink(derivat) {
      return `${env('cdn_tessa')}/${derivat}/${this.item.name}`;
    },
    cdnName(derivat) {
      const name = this.item.name.split('_');
      return name.join('_').replace(name[name.length - 2], derivat);
    },
  },
};
</script>
