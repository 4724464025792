<template>
  <div>
    <div class="container">
      <page-title :headline="$t('myPolyclip.wishlist')"
                  underneath
                  variant="wishlist">
      </page-title>

      <card is-bordered
            class="m-bottom-large m-bottom-0-l-xl">
        <form class="freetext-search"
              @submit.prevent="formHandler">
          <form-input v-model="form.freetext"
                      class="freetext-search__form-item"
                      type="search"
                      name="search"
                      :placeholder="$t('myPolyclip.searchName')" />
          <btn icon="search"
               variant="highlighted"></btn>
        </form>
      </card>

      <data-table :headers="headers"
                  :items="items">

        <template v-slot:default="{ item }">
          <add-to-wishlist :item="item" />
        </template>

      </data-table>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/molecules/PageTitle';
import Btn from '@/components/molecules/Btn';
import DataTable from '@/components/molecules/DataTable';
import AddToWishlist from '@/components/atoms/AddToWishlist';
import Card from '@/components/atoms/Card';
import FormInput from '@/components/molecules/FormInput';
import { mapState } from 'vuex';

export default {
  name: 'MyWishlist',
  components: {
    DataTable,
    PageTitle,
    Btn,
    AddToWishlist,
    Card,
    FormInput,
  },
  data() {
    return {
      headers: [
        {
          name: 'myPolyclip.name',
          field: 'name',
        },
        {
          name: 'myPolyclip.fileType',
          field: 'type',
        },
        {
          name: 'myPolyclip.language',
          field: 'languages',
        },
        {
          name: 'myPolyclip.size',
          field: 'size',
        },
      ],
      form: {
        freetext: '',
      },
    };
  },
  methods: {
    formHandler() {
    },
  },
  computed: {
    ...mapState('mypolyWishlist', ['wishlistItems']),
    ...mapState('mypoly', ['files']),
    items() {
      const wishlistItems = this.files.filter(item => this.wishlistItems.includes(item.id));
      return wishlistItems.filter(item => item.name.includes(this.form.freetext));
    },
  },
};
</script>
