<template>
  <div>
    <div class="container">
      <page-title :headline="productCategory.title" />

      <teasers-grid v-if="getItemsByCategoryId(productCategoryId).length > 0"
                    :items="getItemsByCategoryId(productCategoryId)"
                    to="Product"
                    showIcon
                    param="productId"
                    class="m-top-large" />

      <page-title v-if="getCategoriesByParentId(productCategoryId).length > 0"
                  variant="sub"
                  :headline="`Verzeichnisse in '${productCategory.title}'`"
                  :back-btn="false"
                  class="m-top-large" />

      <teasers-grid v-if="getCategoriesByParentId(productCategoryId).length > 0"
                    :items="getCategoriesByParentId(productCategoryId)"
                    to="ProductCategory"
                    :subtitles="false"
                    param="categoryId"
                    class="m-top-large" />

      <page-title v-if="downloads.length > 0"
                  variant="sub"
                  :headline="`Downloads in '${productCategory.title}'`"
                  :back-btn="false"
                  class="m-top-large" />

      <ProductTabs v-if="downloads.length > 0"
                   :downloads="downloads"
                   class="m-top-large" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TeasersGrid from '@/components/TeasersGrid';
import PageTitle from '@/components/molecules/PageTitle';
import ProductTabs from '@/components/molecules/ProductTabs';

export default {
  name: 'ProductCategory',
  data() {
    return {
      routerFrom: null,
    };
  },
  components: {
    TeasersGrid,
    PageTitle,
    ProductTabs,
  },
  computed: {
    ...mapGetters('productCategories', ['getItemById', 'getCategoriesByParentId']),
    ...mapGetters('products', ['getItemsByCategoryId']),
    ...mapGetters('offline', ['getDownloads']),
    productCategoryId() {
      return Number(this?.$route?.params?.categoryId);
    },
    productCategory() {
      return this.getItemById(this.productCategoryId);
    },
    downloads() {
      return this.getDownloads(this.productCategory.id);
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.routerFrom = from;
    });
  },
  mounted() {
    this.$nextTick(() => {
      const products = this.getItemsByCategoryId(this.productCategoryId);
      const items = [
        ...this.getCategoriesByParentId(this.productCategoryId),
        ...this.downloads,
      ];
      if (products.length === 1 && items.length === 0 && this.routerFrom.name !== 'Product') {
        this.$router.push({ name: 'Product', params: { productId: products[0].id } });
      }
    });
  },
};
</script>
