<template>
  <div class="container">
    <page-title :headline="$t('sidebar.settings')"
                underneath
                :back-btn="false"
                variant="wishlist">
    </page-title>

    <card bottom-spacer>
      <page-title :headline="$t('settings.global')"
                  :back-btn="false"
                  variant="settings">
      </page-title>

      <settings-option :title="$t('settings.language')"
                       :description="$t('settings.language_desc')">
        <language-switch class="language-switch--settings" />
      </settings-option>

      <settings-option :title="$t('settings.password')"
                       :description="$t('settings.password_desc')">
        <btn variant="highlighted"
             :href="getPasswordChangeURL"
             target="_blank">
          {{ $t('settings.password_button') }}
        </btn>
      </settings-option>
    </card>

    <template v-if="hasRole(ROLE_SALES, roles)">
      <card bottom-spacer>
        <page-title :headline="$t('settings.salesapp')"
                    :back-btn="false"
                    variant="settings">
        </page-title>

        <sync-tool read-only />

        <settings-option :title="$t('settings.resync')"
                         :description="$t('settings.resync_desc')">
          <btn variant="highlighted"
               :disabled="isLoading('sync')"
               @click.native.prevent="handleReSync">
            {{ $t('settings.resync_button') }}
          </btn>
        </settings-option>
      </card>
    </template>
  </div>
</template>
<script>
import PageTitle from '@/components/molecules/PageTitle';
import LanguageSwitch from '@/components/molecules/LanguageSwitch';
import { hasRole, ROLE_SALES } from '@/helpers/roles';
import { mapActions, mapGetters, mapState } from 'vuex';
import Btn from '@/components/molecules/Btn';
import SettingsOption from '@/components/molecules/SettingsOption';
import Card from '@/components/atoms/Card';
import SyncTool from '@/components/molecules/SyncTool.vue';

export default {
  methods: {
    ...mapActions('offline', ['flush', 'startSync']),
    hasRole,
    async handleReSync() {
      await this.flush();
      await this.startSync(true);
    },
  },
  computed: {
    ...mapGetters('auth', ['getPasswordChangeURL']),
    ...mapGetters(['isLoading']),
    ...mapState('auth', ['roles']),
    ROLE_SALES() {
      return ROLE_SALES;
    },
  },
  components: {
    SyncTool,
    Card,
    SettingsOption,
    Btn,
    PageTitle,
    LanguageSwitch,
  },
};
</script>
