<template>
  <component class="btn"
             :is="evaluateElement"
             :href="href"
             :to="to"
             :class="classes">
    <div v-if="icon"
         class="btn__icon">
      <icon v-bind:name="icon" />
    </div>
    <div class="btn__content">
      <slot></slot>
    </div>
  </component>
</template>
<script>
import Icon from '@/components/atoms/Icon';

const availableVariants = ['highlighted', 'bg-highlighted', 'decent', 'underlined-inline-text', 'muted', 'transparent', 'app-header', 'light', 'toggle'];
const availableBorders = ['border-darken'];

export default {
  name: 'Btn',
  components: {
    Icon,
  },
  props: {
    smallFont: Boolean,
    variant: String,
    border: String,
    icon: String,
    fullWidth: Boolean,
    halfWidth: Boolean,
    mobileOnly: Boolean,
    tabletOnly: Boolean,
    desktopOnly: Boolean,
    tiny: Boolean,
    pushRight: Boolean,
    pushLeft: Boolean,
    spaced: Boolean,
    spacedBottom: Boolean,
    autoWidth: Boolean,
    to: {
      default: null,
    },
    href: {},
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    evaluateElement() {
      if (this.to) {
        return 'router-link';
      }

      if (this.href) {
        return 'a';
      }

      return 'button';
    },
    classes() {
      return {
        ['btn--icon-only']: !this.$slots.default,
        [`btn--${this.variant}`]: this.variant && availableVariants.includes(this.variant),
        [`btn--${this.border}`]: this.border && availableBorders.includes(this.border),
        ['btn--full-width']: !!this.fullWidth,
        ['btn--half-width']: !!this.halfWidth,
        ['btn--auto-width']: !!this.autoWidth,
        ['btn--mobile-only']: !!this.mobileOnly,
        ['btn--tablet-only']: !!this.tabletOnly,
        ['btn--desktop-only']: !!this.desktopOnly,
        ['btn--spaced']: !!this.spaced,
        ['btn--small-font']: !!this.smallFont,
        ['btn--tiny']: !!this.tiny,
        ['btn--push-right']: !!this.pushRight,
        ['btn--push-left']: !!this.pushLeft,
        ['btn--active']: this.active,
        ['btn--spaced-bottom']: !!this.spacedBottom,
      };
    },
  },
};
</script>
