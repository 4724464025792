<template>
  <div>
    <div class="container">
      <page-title :headline="$t('wishlists.wishlists')"
                  btnRight
                  underneath>
        <div class="button-wrapper">
          <btn @click.native.prevent="addModal = true"
               variant="highlighted"
               icon="plus"
               fullWidth
               class="m-top-0"
          >
            {{ $t('wishlists.addWishlist') }}
          </btn>
        </div>
      </page-title>

      <div v-if="getListsFromCurrentUser.length <= 0">
        {{ $t('wishlists.noWishlist') }}
      </div>

      <card is-bordered
            v-else>
        <list>
          <list-item v-for="(list, key) in getListsFromCurrentUser"
                     :key="list.id"
                     :to="{ name: 'Wishlist', params: {wishlistId: list.id} }"
          >
            <template #subaction>
              <icon class="icon--bookmark--filed"
                    name="bookmark"></icon>
            </template>
            <template #title>
              <span>{{ list.name }}</span>
              <div>
                <btn class="list-item__trash"
                     icon="trash-alt"
                     variant="decent"
                     @click.prevent.native="openDeleteModal(key)"
                >
                  {{ $t('wishlists.delete') }}
                </btn>
              </div>
            </template>
          </list-item>

        </list>
      </card>
    </div>
    <!-- Add modal -->
    <modal v-model="addModal"
           :title="$t('wishlists.addWishlist')">
      <template #body>
        <add-wishlist-form :cb="afterSubmitHook"
                           ref="form"
                           hide-submit />
      </template>
      <template #actions>
        <btn variant="highlighted"
             full-width
             @click.native="submitChildForm">
          {{ $t('wishlists.save') }}
        </btn>
      </template>
    </modal>
    <!-- Delete modal -->
    <modal v-model="deleteModal"
           :title="`${currentList.name} ${$t('wishlists.deleteSmall')}`">
      <template #body>
        <page-title variant="paragraph"
                    :headline="`${$t('wishlists.youWant')} <span>${currentList.name}</span> ${$t('wishlists.forReal')}`"
                    :back-btn="false">
        </page-title>
      </template>
      <template #actions>
        <btn @click.native="deleteModal = false"
             variant="muted"
             half-width
        >
          {{ $t('wishlists.cancel') }}
        </btn>
        <btn variant="highlighted"
             @click.native="handleDelete"
             half-width
        >
          {{ $t('wishlists.delete') }}
        </btn>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddWishlistForm from '@/components/forms/wishlist/AddWishlistForm';
import Btn from '@/components/molecules/Btn';
import Card from '@/components/atoms/Card';
import List from '@/components/molecules/List';
import ListItem from '@/components/molecules/ListItem';
import Modal from '@/components/organisms/Modal';
import PageTitle from '@/components/molecules/PageTitle';
import Icon from '../../components/atoms/Icon';

export default {
  name: 'Wishlists',
  components: {
    Icon,
    AddWishlistForm,
    Btn,
    Card,
    List,
    ListItem,
    Modal,
    PageTitle,
  },
  data() {
    return {
      addModal: false,
      deleteModal: false,
      currentList: {}, // for delete modal
    };
  },
  computed: {
    ...mapGetters('wishlists', ['getListsFromCurrentUser']),
  },
  methods: {
    ...mapActions('wishlists', ['deleteWishlist']),
    afterSubmitHook() {
      this.addModal = false;
    },
    submitChildForm() {
      this.$refs.form.validate();
      this.addModal = false;
    },
    openDeleteModal(key) {
      this.currentList = this.getListsFromCurrentUser[key];
      this.deleteModal = true;
    },
    handleDelete() {
      this.deleteWishlist(this.currentList.id);
      this.deleteModal = false;
      this.currentList = {};
    },
  },
};
</script>
